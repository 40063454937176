import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RFormGroup as FormGroup, RichSelect as Select, RSubmitButton as SubmitButton, Input } from 'components/react-hook-form';
import DatePicker from 'react-datepicker';
import { useForm, FormProvider } from 'react-hook-form';
import { Row, Col, Label, Alert, Button } from 'reactstrap';
import { useQuery } from 'react-query';
import { NotificationManager } from 'react-notifications';
import { toBase64 } from 'components/editors/utils';
import * as Yup from 'yup';
import { basename, dirname } from 'path';
import { useUserContext, useClient } from 'hooks';
import { FetchingSpinner } from 'components/common';
import { Page } from 'components/common';
import { PhotographerUploadModal } from './PhotographerUploadModal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown, } from '@fortawesome/free-solid-svg-icons';
import { format } from "date-fns";
import { useParams } from 'react-router-dom';

// const TYPES = new Set(['image/jpg', 'image/jpeg', 'image/tiff']);
const folderField = (name, field) => `folders.${name}.${field}`;

const FieldCol = ({ children, ...props }) => (
	<Col className="d-flex justify-content-center align-items-center" {...props}>
	  {children}
	</Col>
  );

export const GenProofCards = () => {
	const navigate = useNavigate();
	const client = useClient();
	const { user } = useUserContext();

	const methods = useForm({
		defaultValues: {
			shared: false,
			job: null,
		},
		mode: 'all',
	});

	const { watch, setValue, reset, formState, handleSubmit, register, getValues } = methods;

	const [error, setError] = useState(null);
	const [previewModal, setPreviewModal] = useState(false);
	const togglePreviewModal = () => setPreviewModal(!previewModal);

	const [folderNamesList, setFolderNamesList] = useState([]);
	const [submitting, setSubmitting] = useState(false);
	const [outputFolderName, setOutputFolderName] = useState(null);
	const [filesToSave, setFilesToSave] = useState(null);
	const [deadlineDate, setDeadlineDate] = useState(null);
	const isShared = watch('shared');
	// const [onlyLocal, setOnlyLocal] = useState(true);
	const [gettingJobFolderData, setGettingJobFolderData] = useState(false);

	// const [selectJobOption, setSelectJobOption] = useState();

	const [jobOptions, setJobOptions] = useState([]);

	const jobWatcher = watch('job');
	// const useLocalFilesWatcher = watch('use_local_files');

	// Show Modal State
	const [showUploadConfirm, setShowUploadConfirm] = useState(false);

	// To get the parameter.
	var {job_id} = useParams();

	// When a new job is selected, grab its data and set the navigation.

	useEffect(() => {
		setError(null);

		if (jobWatcher) {
			navigate(`/generateproofcards/${jobWatcher.id}`);

			getJobFolderData(jobWatcher.id);

			if (jobWatcher.proofs_deadline_date) {
				setDeadlineDate(new Date(jobWatcher.proofs_deadline_date));
			} else {
				setDeadlineDate(null);
			}
		} else {
			// navigate(`/generateproofcards`);
		}
	}, [jobWatcher]);


	// for when the user unselects use local files re-request job data
	// useEffect(() => {
	// 	setFolderNamesList([]);
	// 	setValue('folders', {});
	// 	if (useLocalFilesWatcher != undefined && !useLocalFilesWatcher) {
	// 		getJobFolderData(jobWatcher.id);
	// 	}
	// }, [useLocalFilesWatcher]);

	// const {
	// 	data: job,
	// 	error: jobError,
	// 	refetch: getJobs,
	// } = useQuery('fet_job_list', async () => client.get(`lab_job/min_list`).get('data'), {
	// 	refetchOnWindowFocus: false,
	// });

	// Load the job selector with jobs that are ready to be processed.

	const { refetch: getJobs1 } = useQuery(
		'fetch_job_list',
		async () => {
		  client.get(`lab_job/min_list`).then((resp) => {
			setJobOptions(resp.data);
		  });
		//   return null;
		},
		{
		  refetchOnWindowFocus: false,
		}
	);
	
	// If a job_id is passed in as a parameter, then pre-select it
	// when the job options are available.

	useEffect(() => {
		if (job_id && jobOptions) {
			const selectedJob = jobOptions.find(el => el.id == job_id);

			if (selectedJob) {
				setValue('job', selectedJob);
				getJobFolderData(selectedJob.id);

				if (selectedJob.proofs_deadline_date) {
					setDeadlineDate(new Date(selectedJob.proofs_deadline_date));
				} else {
					setDeadlineDate(null);
				}	
			} else {
				job_id = undefined;
			}
		}
	}, [job_id, jobOptions]);

	const {
		data: proofPack,
		error: proofCardsError,
		refetch: getProofCards,
	} = useQuery('get_proof_packs', async () => client.get(`proof_card_designs`).get('data'), {
		refetchOnWindowFocus: false,
	});

	const getJobFolderData = async (job_id) => {
		console.log('getJobFolderData');
		setFolderNamesList([]);
		setGettingJobFolderData(true);
		client
			.get(`generate_proof_card/${job_id}/get_folder_info?folder_type=output`, {})
			.then((resp) => {
				// console.log(resp?.data);
				const folders = resp?.data?.folderNames;

				if (folders?.length > 0) {
					// NotificationManager.success(folders.length);
					// console.log('Number of folders: ' + folders.length);
					// setOnlyLocal(false);
					// setValue('use_local_files', false);
					setValue('folders', folders);
					setFolderNamesList(folders);
				} else {
					// NotificationManager.error(':(');
					// setValue('use_local_files', true);
					// setOnlyLocal(true);
				}

				// Here check if the job has a proof deadline date and show an error if not.
				// Only do this if there are any folders to show.

				if (folders?.length === 0) {
					setError('Job has no photos uploaded from photographer. Please upload the files here instead.');
				} else {
					const proofsDeadlineDate = jobOptions.find((element) => element.id === job_id)?.proofs_deadline_date;

					if (proofsDeadlineDate === null) {
						setError('This job has no proof card deadline date. Please set a date before generating, if you want the deadline to appear on the proof cards.');
					}
				}

				setGettingJobFolderData(false);
			})
			.catch((e) => {
				// setOnlyLocal(true);
				// setValue('use_local_files', true);
				console.error('ERROR: ', e?.response?.data?.error);
				NotificationManager.error(e?.response?.data?.error);

				// setValue('use_local_files', true);
				// setValue('folders', {});
				setGettingJobFolderData(false);

				return null;
			});
	};

	// console.log('proofCards: ', proofPack);

	const onSavePressed = async (all, e) => {
		const card = { ...all };
		const folders = card.folders;

		if (
			!folders ||
			!Object.values(folders)
				.map((e) => e.selected)
				.some((e) => e)
		) {
			setError('You need to select at least one folder');
			return;
		} else {
			setError(null);
		}

		// const newFiles = [];
		// console.log(card.folders);
		// if (filesToSave) {
		// 	filesToSave.forEach((file, index) => {
		// 		// console.log('file: ', file.path)
		// 		const path = file.path;
		// 		const pathFolders = path.split('/');
		// 		const folderName = pathFolders[pathFolders.length - 2]; // get folder name as parent of file
		// 		// console.log('folder: ', folderName)
		// 		const folder = watch(`folders.${folderName}`);

		// 		// console.log(folder)

		// 		const isActive = folder?.selected;
		// 		console.log(folderName, isActive);

		// 		if (isActive) {
		// 			newFiles.push(file);
		// 		}
		// 	});
		// }

		// setFilesToSave(filesToSave);

		// card['files_to_save'] = newFiles;

		if (!card.shared) {
			// Each proof card set has its own proof pack selected.

			card['Folders'] = [];

			for (const [key, folder] of Object.entries(card['folders'])) {
				// console.log('folder: ', folder);
				// folder.name = key;

				if (!folder.selected) {
					folder.active = false;
				} else {
					folder.active = folder.selected;
				}
				delete folder.selected;

				if (!folder.design) {
					folder.design = folder?.selected_pack?.id;
				}

				// folder['path'] = folder.folder_path
				const folderCopy = {...folder}
				delete folderCopy['files']
				card['Folders'].push(folderCopy);
			}

			delete card['folders'];
		} else {
			// All selected proof card sets use the same proof pack.

			const folders = [];
			for (const [key, folder] of Object.entries(card['folders'])) {
				console.log('key: ', key);
				// console.log('folder Name folder: ', folderNamesList[key])
				console.log('folder: ', folder);
				if (folder.selected) {
					folders.push(folder.folder_path ?? folders.name ?? key);
				}
			}
			card['folders'] = folders;
		}

		delete card['just_for_form'];
		// card.files_to_save = filesToSave;

		// console.log('CARD: ', card);
		// return

		setSubmitting(true);

		card['job']['proofs_deadline_date'] = !!deadlineDate ? format(deadlineDate, 'yyyy-MM-dd') : null
		
		const payload = {
			job_id: card.job.id,
			job_type: card.shared ? 'PROOF_SHARED' : 'PROOF_CARD',
			// proofs_deadline_date: !!deadlineDate ? format(deadlineDate, 'yyyy-MM-dd') : null,
			job_data: card,
		};

		// console.log('payload', payload)

		client
			.post('/generate_proof_card', payload)
			.then((data) => {
				NotificationManager.success('Proof card job has been submitted successfully');
				// reset()
				setSubmitting(false);
				navigate(`/viewworkflow/${data.data.id}`);
				return null;
			})
			.catch((e) => {
				console.error('ERROR: ', e.response.data);
				NotificationManager.error('There was an issue with this request');
				setSubmitting(false);
				return null;
			});
	};


	function getFolderFiles(folderObject) {
		const path = folderObject.folder_path
		client
			.post(`generate_generic_job/folder_files_preview`, { job_id: jobWatcher.id, path:path, folder_type: 'output' })
			.then((resp) => {
		  		const previewImages = resp.data
		  		setValue(`folders.${folderObject.key}.files`, previewImages)
		  		return null;
			})
	}


	const createFolderList = async (files) => {
		const folderNames = [];
		const uniqueFolderNames = [];
		const filesToSave = [];

		var baseFolder = 'output';

		for (const [key, value] of Object.entries(files)) {
			// console.log(key, value);
			const filePath = value.webkitRelativePath;

			// Get Each subfolder recursively from input folder
			const sections = filePath.split('/');
			baseFolder = sections[0];
			// console.log(filePath)
			if (sections.length < 3) {
				continue;
			} else {
				for (let i = 0; i < sections.length; i++) {
					if (i == 0 || i == sections.length - 1) {
						continue;
					}

					const section = sections[i];

					if (!uniqueFolderNames.includes(section)) {
						const sectionName = section.replace('/', '');
						//if (sectionName.startsWith('F')) {
						folderNames.push({
							name: sectionName,
							path: filePath,
						});
						uniqueFolderNames.push(sectionName);
					}
				}
			}

			// Remove original folder
			sections.shift();
			const imageRelativePath = sections.join('/');

			const fileType = filePath.split('.')[1];

			const imageObject = {
				file: await toBase64(value),
				path: value.webkitRelativePath,
			};
			filesToSave.push(imageObject);
		}

		const directories = Array.from(new Set(Object.values(files).map((e) => dirname(e.webkitRelativePath)))).map((e) => ({
			name: basename(e),
			path: e,
		}));

		setOutputFolderName(baseFolder);
		setFolderNamesList(directories);
		directories.forEach((e) => {
			setValue(folderField(e.name, 'path'), e.path);
			setValue(folderField(e.name, 'selected'), false);
			setValue(folderField(e.name, 'selected_pack'), null);
		});
		const _files = await Promise.all(
			Object.values(files).map(async (e) => ({
				file: await toBase64(e),
				path: e.webkitRelativePath,
			}))
		);
		setFilesToSave(_files);
	};

	// The rendered result.

	// console.log('jobOptions', jobOptions);

	return (
		<div className='main-content-container d-flex flex-row'>
			<Page>
				<div className='font-bold text-2xl tracking-wide mb-3'>Generate Proof Card</div>
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(onSavePressed)}>
						<FormGroup name='job' label='Job'>
							<Select
								name='job'
								options={jobOptions}
								labelKey='school_name_with_date'
								valueKey='id'
								rules={{ required: true }}
								isLoading={jobOptions.length == 0}
							/>
						</FormGroup>

						{error && (
							<Row>
								<Alert className='w-100' color='danger'>
									{error}
								</Alert>
							</Row>
						)}

						{(!!watch('job') || job_id) && (
							<>
								{/* <FormGroup name='use_local_files'>
									<Label check>
										<Input checked={watch('use_local_files')} disabled={onlyLocal} name='use_local_files' type='checkbox' /> Use Local Files
									</Label>
								</FormGroup> */}
								<FormGroup>
									<Button
										color='primary'
										onClick={()=>{setShowUploadConfirm(true)}}
									>
										Upload Files
									</Button>
								</FormGroup>
								{/* {watch('use_local_files') && (
									<FormGroup name='files' label='Input Folder'>
										<input
											webkitdirectory='true'
											directory='true'
											multiple={true}
											type='file'
											className='form-control'
											id='files'
											{...register('files', {
												required: false,
												onChange: async (e) => {
													setError(null);
													const files = [...e.target.files].filter((v) => TYPES.has(v.type));
													createFolderList(files);
												},
											})}
										/>
									</FormGroup>
								)} */}
								<FormGroup name='shared'>
									<Label check>
										<Input defaultChecked={false} name='shared' type='checkbox' /> Shared proof
									</Label>
								</FormGroup>
								{gettingJobFolderData ? (
									<>
										<div className='font-bold text-2xl tracking-wide mb-3'>
											Getting Job Folder Data <FetchingSpinner className='mx-2' isFetching={true} />{' '}
										</div>
									</>
								) : (
									<>
										<Row className='border-b pb-1 pt-1'>
											<FormGroup label="Proof Card Deadline Date">
												<DatePicker
													name="deadlineDate"
													className="form-control"
													selected={deadlineDate}
													onChange={(newValue) => setDeadlineDate(newValue)}
													dateFormat='dd/MM/yyyy'
												/>
											</FormGroup>
										</Row>

										<Row className='border-b pb-1 pt-1'>
											<Col className=''>
												<div className='text-lg font-bold'>Folder Name</div>
											</Col>
											{!!isShared ? (
												<></>
											) : (
												<>
													<Col className='d-flex justify-content-center align-items-center'>
														<div className='text-lg font-bold'>Proof pack</div>
													</Col>
												</>
											)}
											<Col className='d-flex justify-content-center align-items-center'>
												<div className='text-lg font-bold'>Select</div>
											</Col>
										</Row>
									</>
								)}
								{folderNamesList.map((e, idx) => {
									const folder = e.key
									const formFolders = watch('folders')
									const files = formFolders?.[folder]?.files ?? [];

                      				{/* const useBase64 = formFolders?.[folder]?.base64; */}
									const pack = watch(folderField(folder, 'selected_pack'));
									const isPreview = watch('folders.' + folder + '.preview')

									return (
										<>
											<Row key={idx} className='pb-1 pt-1'>
												<FieldCol role="button" onClick={() => {
													const value = getValues('folders.' + folder + '.preview')
													setValue('folders.' + folder + '.preview', !value);
														// if (!value && files?.length == 0) {
															// console.log("E: ", e)
															getFolderFiles(e)
														// }
													}}
												>
													{/* <div>{e.name}</div> */}
													<div className="w-100 d-flex">
														<FontAwesomeIcon className="" icon={isPreview ? faAngleUp : faAngleDown} size="lg" />
														<h6 className="ml-2">{e.name} </h6>
													</div>
												</FieldCol>


												{!!isShared ? (
													<></>
												) : (
													<>
														<Col className='d-flex justify-content-center align-items-center'>
															<div className='w-100' name={folderField(e.key, 'selected_pack')}>
																<Select
																	name={`folders.${e.key}.selected_pack`}
																	options={proofPack}
																	labelKey='name'
																	valueKey='id'
																	rules={{
																		required: false,
																		validate: (v) => {
																			const isSelected = getValues(`folders.${e.key}.selected`);
																			if (isSelected) return !!v;
																			else return true;
																		},
																	}}
																	defaultValue={''}
																	isLoading={proofPack.length == 0}
																/>
															</div>
														</Col>
													</>
												)}
												<Col className='d-flex justify-content-center align-items-center'>
													<div name={folderField(e.key, 'selected')} className='d-flex justify-content-center align-items-center'>
														<Input
															type='checkbox'
															name={`folders.${e.key}.selected`}
															rules={{ required: false }}
															onChange={(v) => {
																setValue(`folders.${e.key}.selected`, v.target.checked);
																if (v.target.checked) setError(null);
															}}
															className='form-check-input m-0'
														/>
													</div>
												</Col>
											</Row>
											{proofPack.find((x) => x.id === pack?.id)?.has_working_template === false && (
												<Row key={idx * 2} className='justify-center'>
													<Alert className='small' color='darker'>
														This proof pack does not have a template image
													</Alert>
												</Row>
											)}
											{(files?.length > 0 && !!isPreview) ? (
												<>
												<p>Folder Path: {e.folder_path}</p>
												<div className='inline-flex overflow-x-auto w-100'>
													{files.map((file, idx) => {
														return <img key={idx} style={{ height: '250px' }} className='img-thumbnail m-2' src={`data:image/jpeg;base64, ${file}`} />
													})}
												</div>
												</>)
												: (!!isPreview) ?
												<div className='font-bold text-m tracking-wide mb-3'>
													Getting Image Previews <FetchingSpinner className='mx-1' isFetching={true} />{' '}
												</div>
											: null}
										</>
									);
								})}
								{isShared && (
									<>
										<FormGroup name='code' className='mt-3' label='Code'>
											<Input
												type='text'
												className='form-control'
												name='code'
												rules={{
													required: true,
												}}
											/>
										</FormGroup>
										<FormGroup name='title' label='Title'>
											<Input
												type='text'
												className='form-control'
												name='title'
												rules={{
													required: true,
												}}
											/>
										</FormGroup>
										<FormGroup name='pack' label='Print packs'>
											<Select name='pack' labelKey='name' valueKey='id' options={proofPack} rules={{ required: true }} />
										</FormGroup>
									</>
								)}
							</>
						)}
						{/*error && (
							<Row>
								<Alert className='w-100' color='danger'>
									{error}
								</Alert>
							</Row>
						)*/}
						<Row className='justify-content-end mt-4'>
							<Col md={2}>
								<FormGroup>
									<SubmitButton pending={submitting} className='w-100' color='primary'>
										{!submitting ? 'Submit' : 'Submitting'}
									</SubmitButton>
								</FormGroup>
							</Col>
						</Row>
					</form>
				</FormProvider>

				<PhotographerUploadModal
					jobId={jobWatcher?.id}
					afterUploadSuccess={()=>{getJobFolderData(jobWatcher?.id)}}
					showUploadConfirm={showUploadConfirm} 
					setShowUploadConfirm={setShowUploadConfirm}
				/>
			</Page>
		</div>
	);
};

export default GenProofCards;
