import React from "react";
import Nav from "./Nav";

export const AuthenticatedBase = ({ children }) => {
  return (
		<>
			<Nav children={children} />
		</>
  );
};

export default AuthenticatedBase;
