import React from 'react';
import { ModalHeader as BaseModalHeader } from 'reactstrap';
import classNames from 'classnames';

export const ModalHeader = ({ className, children, ...props }) => {
	return (
		<BaseModalHeader
			cssModule={{
				'modal-title': 'modal-title w-full',
			}}
			tag='div'
			className={classNames('text-left text-2xl font-semibold text-hc-primary', className)}
			{...props}
		>
			{children}
		</BaseModalHeader>
	);
};
