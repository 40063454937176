import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RFormGroup as FormGroup, Select, RSubmitButton as SubmitButton, Input } from 'components/react-hook-form';
import { useForm, FormProvider, useFormContext, useFormState } from 'react-hook-form';
import { Row, Col, Button, Modal, ModalBody, ModalFooter, Label, ListGroup, ListGroupItem } from 'reactstrap';
import { ModalHeader } from 'components/ModalHeader';
import { FetchingSpinner } from 'components/common';
import download from 'downloadjs';
import { useQuery } from 'react-query';

import { basename, dirname, sep } from 'path';
import ResizePanel from 'react-resize-panel';

import { toBase64 } from 'components/editors/utils';
import ColorPicker from 'components/editors/ColourPicker.jsx';

import { CompositeArea, DraggableGraphic, GraphicContextMenu, CompositeContextMenu, GraphicTypes, OrderedSectionList } from 'components/editors';
import { useUserContext, useClient } from 'hooks';
import { NotificationManager } from 'react-notifications';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import {faTrash} from '@fortawesome/free-solid-svg-icons';
import EditorArea from 'components/editors/GenComposites/EditorArea';

const break_types = [
	{id: "blank_space", Name:"Blank Space"},
    {id: "blank_space_with_border", Name:"Bordered Space"},
    {id: "split_vertical", Name:"Split Vertical"},
    {id: "split_horizontal", Name:"Army"},
    {id: "graphic", Name:"Graphic"},
    {id: "no_split", Name:"Full composite"},
    {id: "no_split_by_class", Name: "Full composite sorted by section"},
    {id: "separate_composites", Name: "Separate Composites"}]

export const GenerateComposites = () => {
	const navigate = useNavigate();
	const client = useClient();
	const { user } = useUserContext();

	const createDefaultBoxes = () => {
		const result = {}

		for (let i = 0; i < 12; i++) {
			const name = "graphic_" + i
			const newGraphic = {
				visible: false,
				type: 'Shape',
				Shape: 'rectangle',
				centre: {
					x: 0.5,
					y: 0.5,
				},
				width: 0.2,
				height: 0.1,
				outline_stroke: 2,
				colour: '#FFFFFF',
				color_outline: '#000000',
				font: 'Cantarell Italic Light 15',
			}
			result[name] = newGraphic

		}

		return result
	}

	const methods = useForm({
		defaultValues: {
			design:{
					boxes:{
						"composite_area_1": {
							"visible":true,
							"width": 0.5,
							"centre": {
								"x": 0.5,
								"y": 0.5
							},
							"height": 0.5,
							"text_format": 'none',
							"padding_x":1,
							"padding_y":1,
							"max_rows":0,
							"max_cols":0,
							"border_width":0,

						},
						...createDefaultBoxes()
					}
			},
			"output_size": [
				2500,
				1750
			],
			padding_y: 2,
			padding_x: 2,
			section_size: 24,
			section_break_type: "no_split",
			include_subfolders: true,
		},
		mode: 'all',
	});
	const {
		data: presets,
		error: proofCardsError,
		refetch: getPresets,
	} = useQuery('get_composite_preset', async () => client.get(`composite_preset`).get('data'), {
		// enabled:false,
		refetchOnWindowFocus: false,
	});

	const {
		data: fontOptions,
		error: fontOptionsError,
		refetch: getFontOptions,
	} = useQuery('get_font_options', async () => client.get(`font_options/get_list`).get('data'), {
		// enabled:false,
		refetchOnWindowFocus: false,
	});



	const { watch, getValues, setValue, formState, reset, handleSubmit, unregister, register } = methods;

	const [selectedItem, setSelectedItem] = useState({ id: -1, type: 'None', element: null });

	const [presetListModal, setPresetListModal] = useState(false);
	const togglePresetListModal = () => setPresetListModal(!presetListModal);

	const [previewModal, setPreviewListModal] = useState(false);
	const togglePreviewModal = () => setPreviewListModal(!previewModal);

	const [pageBackground, setPageBackground] = useState('');
	const [pageBackgroundColor, setPageBackgroundColor] = useState('rgb(255,255,255)');

	const [previewImages, setPreviewImages] = useState([]);
	//const [downloadedFonts, setDownloadedFonts] = useState({})
	const downloadedFonts = new Set();

	const watchBoxes = watch('design.boxes');
	const watchBackground = watch('background.colour');
	const breakTypeWatcher = watch('just_for_form.section_break_type')

	const [folderNames, setFolderNames] = useState([]);



	// loading indicators
	const [loadingPreview, setLoadingPreview] = useState(false);
	const [loadingFullComposite, setLoadingFullComposite] = useState(false);


	useEffect(
		(e) => {
			const arr = getValues('background.colour');
			if (arr) {
				// console.log('background', arr);
				setPageBackgroundColor(`rgb(${arr[0]},${arr[1]},${arr[2]})`);
			} else {
				console.log(e);
			}
		},
		[watchBackground]
		);


	async function getIndividualFont(fontSet, fontName){
		if (!fontSet.has(fontName)) {
			const fontUrl = `url(${window.location.origin}/api/font_options/get_font/${fontName})`;
			const fontFace = new FontFace(fontName, fontUrl);
			document.fonts.add(fontFace)
			try {
				await fontFace.load();
				downloadedFonts.add(fontName);
			}
			catch (e) {
				console.error(e);
			}
		}
		return null;
	}

	const finishedResizingHandler = (e) => {
		var graphicId = selectedItem.type + '_' + selectedItem.id;
		const element = e.target;
		if (selectedItem.type == 'draggable_graphic') {
			graphicId = 'design.boxes.graphic_' + selectedItem.id;
		} else {
			graphicId = "design.boxes.composite_area_1"
		}
			const heightResult = element.clientHeight / parseInt(watch('full_height'));
			const widthResult = element.clientWidth / parseInt(watch('full_width'));
			setValue(graphicId + '.height', heightResult);
			setValue(graphicId + '.width', widthResult);
			finishedDraggingHandler(e, element);
		selectedItem.element = element;
		setSelectedItem({ ...selectedItem });
	};

	const finishedDraggingHandler = (e, element = null, graphicId = null) => {
		// console.log('Caught Drag Event!!');
		if (!element) {
			element = e.target;
		}
			if (!graphicId) {
				if (selectedItem.type == 'draggable_graphic') {
					graphicId = 'design.boxes.graphic_' + selectedItem.id;
				} else {
					graphicId = "design.boxes.composite_area_1"
				}
			}
			const rect = element.getBoundingClientRect();
			const xResult = element.getAttribute('data-x') / parseFloat(watch('full_width'));
			const yResult = element.getAttribute('data-y') / parseFloat(watch('full_height'));
			const heightResult = element.clientHeight / parseInt(watch('full_height')) / 2;
			const widthResult = element.clientWidth / parseInt(watch('full_width')) / 2;
			setValue(graphicId + '.centre.x', (xResult + widthResult));
			setValue(graphicId + '.centre.y', (yResult + heightResult));
	};

	const ordering_by_options = [
		{ id: 'firstname', name: 'Alphabetical by firstname' },
		{ id: 'surname', name: 'Alphabetical by surname' },
	];

	const getContextMenuItems = () => {
		const element = selectedItem.element;
		if (!element.classList.contains('finishedResizingHandler')) {
			element.classList.add('finishedResizingHandler');
			element.addEventListener('finishedResizing', finishedResizingHandler, false);
		}

		if (!element.classList.contains('finishedDraggingHandler')) {
			element.classList.add('finishedDraggingHandler');
			element.addEventListener('finishedDragging', finishedDraggingHandler, false);
		}

		if (selectedItem.type == 'draggable_graphic') {
			return watchBoxes && <GraphicContextMenu key={selectedItem.id} selectedItem={selectedItem} methods={methods} fontOptions={fontOptions?.file_names} downloadedFonts={downloadedFonts} getIndividualFont={getIndividualFont} />;
		} else if (selectedItem.type == 'composite_area') {
			return <CompositeContextMenu key={selectedItem.id} fontOptions={fontOptions?.file_names} selectedItem={selectedItem} methods={methods} ordering_by_options={ordering_by_options} />;
		} else {
			return <> Hello </>;
		}
	};

	const onSavePressed = async (all, e) => {
		const toSave = {...all}
		toSave.output_size = [parseInt(toSave.full_width), parseInt(toSave.full_height)];

		// force the composite to be shown as sometimes this breaks
		toSave.design.boxes.composite_area_1['visible'] = true;

		// Removed unneeded values
		delete toSave.full_width;
		delete toSave.full_height;


		// console.log('All Values: ', toSave);
		client
			.post('/composite_preset', { preset_name: toSave.name, preset_data: toSave })
			.then((data) => {
				NotificationManager.success('Preset saved successfully');
				// setSelectedForm(null);
				getPresets();
				return null;
			})
			.catch((e) => {
				console.error('ERROR: ', e.response.data);
				NotificationManager.error('There was an issue with this request');
				return null;
			});
	};

	async function parseJsonFile(file) {
		return new Promise((resolve, reject) => {
		  const fileReader = new FileReader()
		  fileReader.onload = event => resolve(JSON.parse(event.target.result))
		  fileReader.onerror = error => reject(error)
		  fileReader.readAsText(file)
		})
	  }

	// This function will not be used often so don't worry too much about the post requests in a for loop - Matthew
	function saveLocalPresets(){
		const files = Array.from(getValues('local_presets'))
		console.log('Files: ', files);
		files.forEach(async file => {
			const object = await parseJsonFile(file)

			delete object['data_file']

			client
			.post('/composite_preset', { preset_name: file.name.replace('.json',''), preset_data: object })
			.then((data) => {
				NotificationManager.success(file.name.replace('.json','') + ' preset saved successfully');
				// setSelectedForm(null);
				getPresets();
				return null;
			})
			.catch((e) => {
				console.error('ERROR: ', e.response.data);
				NotificationManager.error('There was an issue with this request');
				return null;
			});
		})
	}


	const processFile = async (file) => {
		const encoded = await toBase64(file)
		var path = file.webkitRelativePath;
		// remove the folder that was uploaded, we only want its contents
		const pathArr = path.split('/');
		pathArr.shift();
		path = pathArr.join('/');
		// const sizeInMB = getObjectSize(encoded)

		return {
			data: encoded,
			path: path,
			name: file.name,
		};
	  };


	const onGeneratePressed = async (e, preview = false) => {
		// console.log('E: ', e);
		const toSend = {...getValues()};

		if(preview) {
			setLoadingPreview(true)
			delete toSend['images']
			delete toSend['data_file']
		} else {
			setLoadingFullComposite(true)
			// some things aren't used in the preview so no point processing them here
			console.log('IMAGES: ', {...toSend['images']})

			const encodedFiles = await Promise.all(Object.values(toSend['images']).map(processFile));
			toSend['images'] = encodedFiles
			if (toSend['data_file']?.length > 0) {
				const csv_file = await Promise.all([processFile(toSend['data_file'][0])])
				toSend['data_file'] = csv_file[0];
			}

			toSend['section_order'] = folderNames.map(a => a.name)

			console.log('IMAGES: ', {...toSend['images']}.length)


		}


		console.log('template: ', toSend['template'])
		if (toSend['template']?.length > 0) {
			const template_file = await Promise.all([processFile(toSend['template'][0])])
			toSend['template'] = template_file[0];
		}

		toSend['design']['boxes']['composite_area_1']['visible'] = true;

		console.log(toSend);

		toSend.output_size = [parseInt(toSend.full_width), parseInt(toSend.full_height)];

		// Removed unneeded values
		delete toSend.full_width;
		delete toSend.full_height;



		client
			.post('/generate_composite', { preset_data: toSend, preview: preview })
			.then((resp) => {
				togglePreviewModal();
				// console.log(resp.data)

				setPreviewImages(resp.data.files ?? [resp.data.file]);

				if(preview) {
					setLoadingPreview(false)
				} else {
					setLoadingFullComposite(false)
				}
				return null;
			})
			.catch((e) => {
				console.error('ERROR: ', e.response.data);
				NotificationManager.error('There was an issue with this request');
				if(preview) {
					setLoadingPreview(false)
				} else {
					setLoadingFullComposite(false)
				}
				return null;
			});
	};

	const getGraphicsList = () => {

		const toReturn = [];

		const boxes = watch('design.boxes')

		for (const [key, box] of Object.entries(boxes)) {
			// console.log(`${key}:`, box);
			if (key.includes('composite')) {
				continue
			}

			const visible = box.visible
			const listItem = <>
				<Row className={`border-b-2 pb-1 pt-1 d-flex align-items-center justify-content-between mt-2 ml-0 mr-0`}>
					<h5>Graphic {parseInt(key.replace("graphic_", ""))}</h5>
					<Button
						color=""
						onClick={() => {
							setValue("design.boxes."+key+".visible", !visible )
						}}
					>
						<FontAwesomeIcon className="mr-2" icon={visible ? faEye : faEyeSlash} />
					</Button>
				</Row>
			</>
			toReturn.push(listItem)
		}
		return toReturn
	}

	const addGraphics = () => {
		const toAdd = [];
		for (const [key_val, value] of Object.entries(getValues('design.boxes') ?? {})) {
			if (key_val != "composite_area_1") {
				console.log(`${getValues('name')}-${key_val}`)
				toAdd.push(<DraggableGraphic
					methods={methods}
					setSelectedItem={setSelectedItem}
					id={key_val}
					values={watch('design.boxes.' + key_val)}
					type={GraphicTypes.COMPOSITE_GRAPHIC}
					fonts={downloadedFonts}

				/>);
			}
		}
		return toAdd;
	};

	const createPresetList = () => {
		const toAdd = [];
		for (const [key, value] of Object.entries(presets)) {
			// console.log(key, value)

			const component = (
				<Row className='d-flex mb-2 justify-content-center' key={key}>
					<Col md={6}>
						<Button
						color='primary'
							className='w-100'
							onClick={() => {
								// * Load the preset
								reset(value.preset_data)
								const width = value.preset_data.output_size[0];
								const height = value.preset_data.output_size[1];


								const messages = ["Warning there are some issues with this preset"]

								if (value.preset_data['data_file']) {
									messages.push("\n\n - There is a data file path in this preset, you will have to manually reupload this file")
								}


								if (value.preset_data['template']) {
									messages.push("\n\n - There is a template file path in this preset, you will have to manually reupload this file")
								}

								if (value.preset_data['photo_folder']) {
									messages.push("\n\n - There is a photo folder path in this preset, you will have to manually reupload these files")
								}

								for (const [key, box] of Object.entries(value.preset_data.design.boxes)) {
									console.log(`${key}: ${box}`);
									if (box['Image']) {
										messages.push(`\n\n - There is a image file path in this preset for ${key}, you will have to manually reupload this file`)
									}
								}

								if (messages.length > 1) {
									alert(messages)
								}



								// console.log(value.preset_data);
								// Load each item into the form
								// reset();
								// for (const [key, preset_value] of Object.entries(value.preset_data)) {
								// 	setValue(key, preset_value);
								// }
								// // set name as preset name
								// setValue('name', value.preset_name);

								// // Load width and height into correct form items

								setValue('full_width', width);
								setValue('full_height', height);

								// setValue('graphics_num', Object.keys(value.preset_data.design.boxes).length



								document.getElementById('page').style.width = width + 'px';
								document.getElementById('page').style.height = height + 'px';

								// positionLoadedGraphics(value.preset_data.design.boxes ?? {})

								togglePresetListModal();
							}}
						>
							<h3>{value.preset_name}</h3>
						</Button>
					</Col>
				</Row>
			);

			toAdd.push(component);
		}
		return toAdd;
	};

	return (
		<div className='main-content-container overflow-hidden ' style={{ height: 'calc(100vh - (57px))' }}>
			{/* Main Menu  */}
			<Row style={{ margin: '0px', height: '100%' }}>
				<ResizePanel style={{ width: '400px', maxWidth: "500px"}} direction='e' className=' h-100'>
					<div className='options-list ' style={{ overflowY: 'scroll', overflowX: 'clip', height: 'calc(100vh - (57px))' }}>
						<FormProvider {...methods}>
							<form onSubmit={handleSubmit(onSavePressed)} style={{overflowX: 'hidden'}}>
								<FormGroup label='Name'>
									<Input
										type='text'
										className='form-control'
										name={'name'}
										placeholder='Enter name'
										rules={{
											required: false,
										}}
									/>
								</FormGroup>

								<FormGroup label='Background'>
									<Row>
										<Col md={pageBackground ? 8 : 10} className='pr-2'>
											<input
												type='file'
												className='form-control'
												id='template'
												{...register('template', {
													required: false,
													onChange: async (e) => {
														const file = e.target.files[0];
														console.log(file);
														await toBase64(file).then((string) => {
															// console.log('String: ', string);
															setPageBackground(string);
															// refreshPanel();
															// setImagePreview(string);
														});
													},
												})}
											/>
										</Col>
										{pageBackground && (
											<Col className='pl-0 pr-2'>
												<Button className='w-100'
													color={'danger'}
													onClick={() => {
														setPageBackground(null);
														setValue('template', null)
													}}
												>
													<FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
												</Button>
											</Col>
										)}
										<Col className='pl-0'>
											<ColorPicker className='w-100 h-100' setValue={setValue} hookFormTitle={'background.colour'} />
										</Col>
									</Row>
								</FormGroup>

								<Row className='m-0'>
									<Col className='pl-0'>
										<FormGroup label='Width'>
											<input
												type='number'
												className='form-control'
												placeholder='0'
												{...register('full_width', {
													required: false,
													value: 2500,
													valueAsNumber: true,
												})}
												min={0}
												max={10000}
												step={10}
												onChange={(e) => {
													const value = e.target.value;
													setValue('full_width', value);
													// selectedItem.element.style.width = value + 'px';
													document.getElementById('page').style.width = value + 'px';
												}}
											/>
										</FormGroup>
									</Col>
									<Col className='pr-0'>
										<FormGroup label='Height'>
											<input
												type='number'
												className='form-control'
												placeholder='0'
												{...register('full_height', {
													required: false,
													value: 1750,
													valueAsNumber: true,
												})}
												min={0}
												max={10000}
												step={10}
												onChange={(e) => {
													const value = e.target.value;
													// selectedItem.element.style.width = value + 'px';
													setValue('full_height', value);
													document.getElementById('page').style.height = value + 'px';
												}}
											/>
										</FormGroup>
									</Col>
								</Row>

								<FormGroup name={'data_file'} label='Data File'>
								<Row>
									<Col>
										<input
											type='file'
											className='form-control'
											id='data_file'
											{...register('data_file', {
												required: false,
											})}
										/>
									</Col>
									<Col md={6} className='d-flex align-items-center'>
										<input
											id='use_folders_as_data_checkbox'
											type="checkbox"
											checked={watch('use_folders_as_data')}
											name={'use_folders_as_data'}
											onChange={(e) => {
												const checked = e.target.checked;
												// console.log('e', newValue);
												setValue('use_folders_as_data', checked)
												if(checked) setValue('include_subfolders', true)

											}}
										/>
										<label className='m-0 ml-2' htmlFor="use_folders_as_data_checkbox"><span class="fa fa-check"/><p className="small">Use folder structure as data</p></label>
									</Col>
								</Row>
								</FormGroup>

								<FormGroup label='Photo Folder'>
								<Row>
									<Col>
										<input
											webkitdirectory='true'
											directory='true'
											multiple={true}
											type='file'
											className='form-control'
											id='images'
											{...register('images', {
												required: false,
												onChange: async (e) => {
													const files = Array.from(e.target.files);
													console.log('files: ', files);

													const folderNamesList = []
													files.forEach(file => {
														const file_path = file.webkitRelativePath
														// const folder = dirname(path)

														const folder = basename(dirname(file_path))
														if (['jpeg', 'jpg'].includes(file.name.split('.').pop()) && !folderNamesList.includes(folder)) {
															console.log(file_path, "------------" ,folder)
															folderNamesList.push(folder)
														}
													})
													const tempList = []
                                                    console.log("AutoLog - onChange: - tempList", tempList)

													folderNamesList.forEach((folder, index) => {
														tempList.push({ id: index + 1, name: folder})
													})

													setFolderNames(tempList)
													// setFolderNames(folderNamesList)
												},
											})}
										/>
									</Col>
									<Col md={6} className='d-flex align-items-center'>
										<input
											id='include_subfolders_checkbox'
											type="checkbox"
											checked={watch('include_subfolders')}
											name={'use_folders_as_data'}
											onChange={(e) => {
												const checked = e.target.checked;
												// console.log('e', newValue);
												setValue('include_subfolders', checked)

												if(!checked) setValue('use_folders_as_data', false)


											}}
										/>
										<label className='m-0 ml-2' htmlFor="include_subfolders_checkbox"><span class="fa fa-check"/><p className="small">Include Subfolders</p></label>
									</Col>
								</Row>
								</FormGroup>
								<hr />
								<h4>Section Control</h4>
								<FormGroup label='Break Type'>
									<Select
										getOptionLabel={(data) => {
											return data['Name'];
										}}
										getOptionValue={(data) => {
											return data['id'];
										}}
										options={break_types}
										{...register(`section_break_type`, {
											rules: {
												required: false,
											},
										})}
										onChange={(newValue) => {
											console.log('e', newValue);
											console.log(break_types[5])
											console.log(break_types[5] == newValue)

											setValue(`just_for_form.section_break_type`, newValue);
											setValue(`section_break_type`, newValue.id);
										}}
										shouldUnregister={true}
										value={breakTypeWatcher ?? break_types[5]}
									/>
								</FormGroup>

								{["Split Vertical", "Army"].includes(breakTypeWatcher?.Name) && (

									<FormGroup label='Section Name Place'>
										<Select
											getOptionLabel={(data) => {
												return data['name'];
											}}
											getOptionValue={(data) => {
												return data['id'];
											}}
											options={ordering_by_options}
											{...register(`ordering`, {
												rules: {
													required: false,
												},
											})}
											onChange={(newValue) => {
												console.log('e', newValue);
												setValue(`photo_label_font`, newValue);
											}}
											shouldUnregister={true}
											// defaultValue={panel ?? ''}
										/>
									</FormGroup>
								)}
								{["Split Vertical", "Army", "Separate Composites"].includes(breakTypeWatcher?.Name) && (
									<FormGroup label='Section Label Size'>
										<Input
											type='number'
											className='form-control'
											name={'section_size'}
											placeholder='0'
											rules={{
												required: false,
											}}
											// defaultValue={selectedItem.element.clientWidth}
											onChange={(e) => {
												// const value = e.target.value;
												// selectedItem.element.style.width = value + 'px';
											}}
										/>
									</FormGroup>
								)}
								{["Army"].includes(breakTypeWatcher?.Name) && (

									<FormGroup label='Horizontal Padding'>
										<Input
											type='number'
											className='form-control'
											name={'padding_x'}
											placeholder='0'
											rules={{
												required: false,
											}}
											// defaultValue={selectedItem.element.clientWidth}
											onChange={(e) => {
												// const value = e.target.value;
												// selectedItem.element.style.width = value + 'px';
											}}
										/>
									</FormGroup>
								)}

								{["Split Vertical"].includes(breakTypeWatcher?.Name) && (
									<FormGroup label='Vertical Padding'>
										<Input
											type='number'
											className='form-control'
											name={'padding_y'}
											placeholder='0'
											rules={{
												required: false,
											}}
											// defaultValue={selectedItem.element.clientWidth}
											onChange={(e) => {
												// const value = e.target.value;
												// selectedItem.element.style.width = value + 'px';
											}}
										/>
									</FormGroup>
								)}
								{["Split Vertical"].includes(breakTypeWatcher?.Name) && (
									<FormGroup label='Vertical Padding'>
										<Input
											type='number'
											className='form-control'
											name={'padding_y'}
											placeholder='0'
											rules={{
												required: false,
											}}
											// defaultValue={selectedItem.element.clientWidth}
											onChange={(e) => {
												// const value = e.target.value;
												// selectedItem.element.style.width = value + 'px';
											}}
										/>
									</FormGroup>
								)}
								{watch('use_folders_as_data') && watch('include_subfolders') && folderNames?.length > 0 && (
									<>
										<p className="small">Ordered sections/classes</p>
										<OrderedSectionList  list={folderNames}  setList={setFolderNames} className="w-100"/>
									</>
								)}
								<hr />
								<Col>
									{getGraphicsList()}
								</Col>
								<Row className='pl-3'>
									<Button
										onClick={() => {
											togglePresetListModal();
										}}
										className='ml-2 mt-2'
									>
										Load Preset
									</Button>
									<SubmitButton className='ml-2 mt-2'>Save as preset</SubmitButton>
									<Button onClick={(e)=>{onGeneratePressed(e, true)}} className='ml-2 mt-2'>
									Preview {loadingPreview && (<FetchingSpinner className='mx-2' style={{color:'white'}} isFetching={true}/>) }
									</Button>
									<Button onClick={(e)=>{onGeneratePressed(e, false)}} className='ml-2 mt-2'>
									Generate {loadingFullComposite && (<FetchingSpinner className='mx-2' style={{color:'white'}} isFetching={true}/>) }
									</Button>
								</Row>
							</form>
						</FormProvider>
						{/* <ImageContainer /> */}
					</div>
				</ResizePanel>

				{/* Editor Area  */}
				<Col className='p-0'>
					<EditorArea methods={methods} pageBackgroundColor={pageBackgroundColor} defaultHeight={1750} defaultWidth={2500} backgroundImage={pageBackground}>
						<CompositeArea key={0} id='composite_area_1' methods={methods} setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
						{/* <DraggableGraphic type={"composite_area"} methods={methods} setSelectedItem={setSelectedItem} id={0} key={0} values={{}} methods={methods} /> */}
						{watchBoxes && addGraphics()}
					</EditorArea>
				</Col>

				{/* Context Menu  */}
				<ResizePanel style={{width: '350px', maxWidth:'500px', minWidth: '200px'}} direction='w' className=''>
					<div style={{ overflowY: 'scroll', overflowX: 'show', height: 'calc(100vh - (57px))' }} className='context-options-list  w-100'>
						{selectedItem.id > -1 ? getContextMenuItems() : <> </>}
					</div>
				</ResizePanel>

				{/* Preset Modal */}
				<div>
					<Modal size='lg' isOpen={presetListModal}>
						<ModalHeader>Presets</ModalHeader>

						<ModalBody>{presets && createPresetList()}</ModalBody>

						<ModalFooter>
							<FormProvider {...methods}>
								<form className="w-100">
									<Row>
										<Col>
											<Row>
												<Col md={6}>
													<input
														webkitdirectory='true'
														directory='true'
														multiple={true}
														type='file'
														className='form-control'
														id='local_presets'
														{...register('local_presets', {

															// onChange: async (e) => {
															// 	const files = Array.from(e.target.files);
															// 	console.log('files: ', files);
															// },
														})}
													/>
												</Col>
												<Col>
													<Button color='primary' onClick={saveLocalPresets}>
														Upload Local Presets
													</Button>
												</Col>
											</Row>
										</Col>
										<Col className='flex justify-content-end'>
											<Button onClick={togglePresetListModal}>Close</Button>
										</Col>
									</Row>
								</form>
							</FormProvider>
						</ModalFooter>
					</Modal>
				</div>
				{/* Preview Modal */}
				<div>
					<Modal size='lg' isOpen={previewModal}>
						<ModalHeader>Image Preview</ModalHeader>

						<ModalBody className='d-flex justify-content-center'>
							{/* <img className='border' src={previewImages[0]} /> */}
							<Col>
								{previewImages.map((file, idx) => {
									return <>
										{file.filename && (<h3> {file.filename} </h3>)}
										<img className='border mb-3' key={idx} src={`data:image/jpeg;base64,${file.file}`} />
									</>
								})}
							</Col>
						</ModalBody>

						<ModalFooter>
							<FormProvider {...methods}>
								<form>
									<Row>
										<Col>
											<Button color='primary' onClick={()=>{
												previewImages.forEach((file, idx) => {
													download(`data:image/jpeg;base64,${file.file}`, file.filename ? file.filename :  getValues('name') ? `${getValues('name')}_${idx}` : `Composite_${idx}.jpg`)
												})
											}}>Download {previewImages.length > 1 ? 'all' : ''}</Button>
										</Col>
										<Col>
											<Button onClick={togglePreviewModal}>Close</Button>
										</Col>
									</Row>
								</form>
							</FormProvider>
						</ModalFooter>
					</Modal>
				</div>
			</Row>
		</div>
	);
};

export default GenerateComposites;
