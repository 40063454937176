import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormGroup, RichSelect as Select, CustomButton, Input, DatePicker, SubmitButton } from 'components/react-hook-form';
import { useForm, FormProvider, useFormContext, useFormState } from 'react-hook-form';
import { Row, Col, Button, Modal, ModalBody, ModalFooter, Label } from 'reactstrap';
import { ModalHeader } from 'components/ModalHeader';
import { useUserContext, useClient } from 'hooks';
import { createClient } from 'hooks/useClient';
import { useQuery, useQueryClient } from 'react-query';
import { DraggableGraphic, GraphicTypes } from 'components/editors';
import { NotificationManager } from 'react-notifications';

import { toBase64, getScale } from 'components/editors/utils';
import EditorArea from 'components/editors/GenComposites/EditorArea';

import DraggableEditorImage from 'components/editors/DraggableEditorImage';
import { Page } from 'components/common';
import { faEye, faEyeSlash, faTimesCircle, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { includes } from 'lodash';

export const CreateNewStaffPhotoTemplate = () => {
	const client = useClient();
	const { user } = useUserContext();
	const navigate = useNavigate();


	const { id } = useParams();
	useEffect(() => {
	  async function load() {
		if (id) {
		  try {
			getIndividualTemplate(id);
		  } catch (ex) {
			const detail = ex?.response?.data?.detail;
			NotificationManager.error(detail ?? "Something went wrong when trying to load photo job data");
		  }
		}
	  }
	  load();
	}, [id]);
	

	const dataItems = ['title', 'first_name', 'last_name', 'school_name', 'class_name', 'name', 'job_title'];

	const createDefaultBoxes = () => {
		const result = {};

		for (let i = 0; i < 12; i++) {
			const name = 'graphic_' + i;
			const newGraphic = {
				top: 0,
				left: 0,
				text: 'Photo ' + (i + 1),
				ratio: 1.25,
				width: 1.0,
				height: 1.0,
				rotated: false,
				height_ratio: 1.25,
				visible: false,
			};
			result[name] = newGraphic;
		}

		return result;
	};

	const methods = useForm({
		defaultValues: {
			selected_school: null,
			layout: {
				boxes: {
					...createDefaultBoxes(),
				},
			},
		},
		mode: 'all',
	});

	const { watch, setValue, reset, handleSubmit, register, getValues } = methods;

	const [printPanels, setPrintPanels] = useState([]);

	const [scaleValue, setScaleValue] = useState(100);
	const [initialScale, setInitialScale] = useState(0.3);
	const [divideVal, setDivideVal] = useState(1)

	const [proofCards, setProofCards] = useState([]);
	const [schoolList, setSchoolList] = useState([]);

	const [showLayers, setShowLayers] = useState(false);
	const toggleLayers = () => setShowLayers(!showLayers);

	// Edit Modal State

	const newPanelObject = { id: -1, layout: { size_x: 1, size_y: 1 } };

	const [editModal, setEditModal] = useState(false);
	const toggleEditModal = () => setEditModal(!editModal);
	const [editingPanel, setEditingPanel] = useState(newPanelObject);
	const [pageBackground, setPageBackground] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);


	const [selectedPhoto, setSelectedPhoto] = useState({ id: -1, type: 'None', element: null });

	// -------------------------------------------------------------
	// API Stuff
	// -------------------------------------------------------------

	const { refetch: getPrintPanels } = useQuery(
		'get_paint_panels',
		async () => {
			client.get(`print_panels`).then((data) => {
				setPrintPanels(data.data);
			});
			return null;
		},
		{
			refetchOnWindowFocus: false,
		}
	);

	const { refetch: getSchoolList } = useQuery(
		'get_school_list',
		async () => {
			client.get(`school_list`).then((data) => {
				setSchoolList(data.data);
				return null;
			});
			return null;
		},
		{
			refetchOnWindowFocus: false,
		}
	);

	const { refetch: getStaffTemplates } = useQuery(
		'get_paint_packs',
		async () => {
			client.get(`staff_image_templates?school=${getValues('selected_school').id}`).then((data) => {
				setProofCards(data.data);
				return null;
			});
			return null;
		},
		{
			refetchOnWindowFocus: false,
			enabled: false,
		}
	);

	const getIndividualTemplate = (templateID) => {
		client.get(`staff_image_templates/${templateID}`).then((data) => {
			const templateData = data.data;
			var stevenScale = getScale(parseFloat(templateData.layout.size_x), parseFloat(templateData.layout.size_y));
			// setDivideVal(scaleValue * parseFloat(templateData.layout.size_x) < 500 ? 5 : 1)
			const toMultiply = stevenScale * parseFloat(templateData.layout.size_x) < 500;
			console.log("Should multiply: ", toMultiply);
			if (toMultiply) {
				// stevenScale = stevenScale * 5;
				setInitialScale(initialScale * 5)
			}

			setScaleValue(stevenScale);
			for (const [key, value] of Object.entries(templateData.layout.boxes)) {
				console.log(`${key}:`, value);
				if (!key.includes('photo_') && (value.text || value.Text)) {
					// if (value.align === "Centre") {
					// 	templateData.layout.boxes[key].left += (parseFloat(value.width)  / 2)
					// 	// templateData.layout.boxes[key].top += (parseFloat(value.height) / 4)
					// }

					// templateData.layout.boxes[key].top += (parseFloat(value.height) / 2)
				}

				templateData.layout.boxes[key].width = value.width * templateData.layout['size_x'];
				templateData.layout.boxes[key].height = value.height * templateData.layout['size_y'];

			}
			reset(templateData);
			setValue('just_for_form.photos_num', Object.keys(templateData.layout.boxes).filter((key) => key.includes('photo_')).length);
			console.log('this one', getValues('just_for_form.photos_num'));

			if (templateData.background) {
				setPageBackground(templateData.background)
			}

			toggleEditModal();

			const delayInMilliseconds = 1000; //1 second
			setTimeout(function () {
				addEventListeners();
			}, delayInMilliseconds);

			return null;

			// setPrintPacks(data.data);
		});
	};


	const onSavePressed = async ({ ...all }) => {
		setIsSubmitting(true)
		console.log('Saving Now', all);

		const templateToSave =JSON.parse(JSON.stringify(all)); // copy form object;
		console.log({ ...templateToSave });

		console.log(typeof templateToSave.template);
		// if (templateToSave.template && typeof templateToSave.template != typeof 'this' && templateToSave.template[0]) {
		// 	templateToSave.template = pageBackground;
		// }

		templateToSave.background = pageBackground;

		templateToSave.layout.size_y = templateToSave.layout.size_y.toString();
		templateToSave.layout.size_x = templateToSave.layout.size_x.toString();
		templateToSave.layout.font_size = templateToSave.layout.font_size.toString();



		// templateToSave.background_image = pageBackground;

		// if there is no background in state delete it on the backend
		// if (pageBackground.length < 1) {
			// templateToSave.background = 'delete';
			// templateToSave.background_image = 'delete'
		// }

		const {font_size} = templateToSave.layout

		const page = document.getElementById('page').getBoundingClientRect();
		// console.log("Page", page.getBoundingClientRect().width / initialScale)

		for (const [key, value] of Object.entries(templateToSave.layout.boxes)) {
			// console.log(`${key}:`, value);

			if (!key.includes('photo_') && (value.text || value.Text)) {
				// add width and height to graphic
				// const textHeight = measureTextWidth('M', font_size) / templateToSave.layout.size_y
				// const textHeight = value.height / templateToSave.layout.size_y

				// const textWidth = (measureTextWidth(value.text ?? value.Text,  font_size) / templateToSave.layout.size_x)
				// const textWidth = value.width / templateToSave.layout.size_x


				const element = document.getElementById(key)
				const rect = element.getBoundingClientRect()

				// const textWidth = rect.width  / (page.width )
				// const textHeight = rect.height / (page.height )


				const textWidth = measureTextWidth(value.text ?? value.Text,  font_size)  / (page.width)
				const textHeight = measureTextWidth('M', font_size) / (page.height)

				console.log("TEXT WIDTH: ", textWidth, value.width, templateToSave.layout.size_x);
				console.log("TEXT HEIGHT: ", textHeight);

				value.width = parseFloat(textWidth)
				value.height = parseFloat(textHeight)

				if (value.align === "Centre") {
					// value.left -= (parseFloat(textWidth)  / 2)
					// value.top += (parseFloat(textHeight) / 2)
				}

				// value.top -= (parseFloat(textHeight) / 2)

				value['is_text'] = true
				value['text'] = value.Text
			} else {
				value['is_text'] = false
				// console.log( parseFloat(value.width), templateToSave.layout.size_x,  parseFloat(value.width) / templateToSave.layout.size_x)
				value.width = parseFloat(value.width) / templateToSave.layout.size_x;
				value.height = parseFloat(value.height) / templateToSave.layout.size_y;
			}
		}

		// Remove the form helper stuff
		if (!templateToSave.id) {
			templateToSave.school = templateToSave.selected_school.id;
		}
		delete templateToSave.just_for_form;
		delete templateToSave.template;
		delete templateToSave.background_image;

		console.log('Saving this: ', templateToSave);
		if (!!templateToSave.id) {
			const url = `/staff_image_templates/${templateToSave.id}`;

			client
				.put(url, templateToSave)
				.then(() => {
					// getPrintPanels();
					// resetFormState();
					// toggleEditModal();
					setIsSubmitting(false)
					NotificationManager.success("Template saved successfully")
					window.location.reload()
				})
				.catch((e) => {
					NotificationManager.error("Template saving failed")
					setIsSubmitting(false)
					console.error('ERROR: ', e);
				});
		} else {
			client
				.post('/staff_image_templates', templateToSave)
				.then(() => {
					// getPrintPanels();
					// resetFormState();
					// toggleEditModal();
					setIsSubmitting(false)
					NotificationManager.success("Template saved successfully")

					window.location.reload()
				})
				.catch((e) => {
					NotificationManager.error("Template saving failed")
					setIsSubmitting(false)
					console.error('ERROR: ', e);
				});
		}
	};

	function measureTextWidth(str, fontSize = 10) {
		const widths = [
			0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.2796875, 0.2765625, 0.3546875, 0.5546875, 0.5546875, 0.8890625, 0.665625, 0.190625, 0.3328125, 0.3328125, 0.3890625, 0.5828125, 0.2765625, 0.3328125, 0.2765625, 0.3015625, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.2765625, 0.2765625, 0.584375, 0.5828125, 0.584375, 0.5546875, 1.0140625, 0.665625, 0.665625, 0.721875, 0.721875, 0.665625, 0.609375, 0.7765625, 0.721875, 0.2765625, 0.5, 0.665625, 0.5546875, 0.8328125, 0.721875, 0.7765625, 0.665625, 0.7765625, 0.721875, 0.665625, 0.609375, 0.721875, 0.665625, 0.94375, 0.665625, 0.665625, 0.609375, 0.2765625, 0.3546875, 0.2765625, 0.4765625, 0.5546875, 0.3328125, 0.5546875, 0.5546875, 0.5, 0.5546875, 0.5546875, 0.2765625, 0.5546875, 0.5546875, 0.221875, 0.240625, 0.5, 0.221875, 0.8328125, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.3328125, 0.5, 0.2765625,
			0.5546875, 0.5, 0.721875, 0.5, 0.5, 0.5, 0.3546875, 0.259375, 0.353125, 0.5890625,
		];
		const avg = 0.5279276315789471;
		return (
			str
				.split('')
				.map((c) => (c.charCodeAt(0) < widths.length ? widths[c.charCodeAt(0)] : avg))
				.reduce((cur, acc) => acc + cur) * fontSize
		);
	}

	const fontSizeWatcher = watch('layout.font_size');
	useEffect(() => {
		if (fontSizeWatcher) {
			const boxes = getValues('layout.boxes');

			const nonPhotoKeys = Object.keys(boxes).filter((key) => !key.includes('photo_'));
			nonPhotoKeys.forEach((key) => {
				const value = getValues('layout.boxes.' + key);
				const ratio = measureTextWidth('M', fontSizeWatcher) / value.width;
				setValue('layout.boxes.' + key + '.height_ratio', ratio);
			});
		}
	}, [fontSizeWatcher]);

	function deletePrintPanel(id) {
		// console.log(id)
		client
			.delete(`/print_panels/${id}`)
			.then(() => {
				getPrintPanels();
			})
			.catch((e) => {
				console.error('ERROR: ', e.response.data);
			});
	}


	const addEventListeners = () => {
		const elements1 = Array.prototype.slice.call(document.getElementsByClassName('only-draggable-container'));
		const elements2 = Array.prototype.slice.call(document.getElementsByClassName('draggable-container-ratio-lock'));
		const elements3 = Array.prototype.slice.call(document.getElementsByClassName('draggable-container'));

		const elements = elements1.concat(elements2, elements3);

		// var arr = Array.prototype.slice.call( htmlCollection )
		if (elements) {
			elements.forEach((element) => {
				if (!element.classList.contains('finishedDraggingHandler')) {
					element.classList.add('finishedDraggingHandler');
					element.addEventListener('finishedDragging', finishedDraggingHandler, false);
				}
				if (!element.classList.contains('finishedResizingHandler')) {
					element.classList.add('finishedResizingHandler');
					element.addEventListener('finishedResizing', finishedResizingHandler, false);
				}
			});
		}
	};

	const createPrintPackFormItem = (element) => {
		return (
			<FormGroup key={element.id}>
				<Row key={element.id} style={{ alignItems: 'center' }} className='justify-content-center'>
					<Col xs='4' key={1}>
						<h5
							style={{
								float: 'right',
								margin: '0px',
								width: '100%',
								textAlign: 'end',
								textOverflow: 'ellipsis',
								overflow: 'hidden',
								wordWrap: 'break-word',
								display: 'block',
								lineHeight: '1em',
								maxHeight: '2em',
							}}
						>
							{' '}
							{element.name}{' '}
						</h5>
					</Col>
					<Col xs='2' key={2}>
						<CustomButton
							style={{ width: '100%' }}
							buttonColor='primary'
							onClick={() => {
								// handleEditModal(element);
								// getIndividualTemplate(element.id);
								navigate(`/createstaffphototemplate/${element.id}`);
							}}
						>
							Edit
						</CustomButton>
					</Col>
					<Col xs='2' key={3}>
						<CustomButton
							style={{ width: '100%' }}
							buttonColor='danger'
							onClick={() => {
								deletePrintPanel(element.id);
							}}
						>
							Delete
						</CustomButton>
					</Col>
					<Col xs={2}></Col>
				</Row>
			</FormGroup>
		);
	};


	const capitalizeFirstLetterEachWord = (string) => {
		return string.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
	};

	const alignOptions = [
		{ name: 'Left', id: '0' },
		{ name: 'Centre', id: '1' },
		{ name: 'Right', id: '2' },
	];

	const getGraphicsList = () => {
		const toReturn = [];


		dataItems.forEach((item, index) => {
			const itemText = item.replace('_', ' ').toUpperCase();
			const value = getValues('layout.boxes.' + item);

			toReturn.push(
				<Row key={index} className={`border-b-2 pb-1 pt-1 d-flex align-items-center justify-content-between mt-2 ml-0 mr-0`}>
					{/* <Col md={9}> */}

					<Button
						color=''
						onClick={() => {
							setValue('layout.boxes.' + item + '.active', !value?.active);
							// the time delay isn't ideal but it allows this to run
							// once the page has rerendered with the graphic there
							// TODO: add this to some kind of on appear for each element
							window.setTimeout(function () {
								addEventListeners()
							},100);
						}}
					>
						<FontAwesomeIcon className='mr-2' icon={value?.active ? faEye : faEyeSlash} />
					</Button>
					<h6>{capitalizeFirstLetterEachWord(itemText.toLowerCase())}</h6>
					{console.log("ITEM: ", item)}
					<Select
						className='mb-3 w-50'
						options={alignOptions}
						getOptionLabel={(data) => {
							return data['name'];
						}}
						getOptionValue={(data) => {
							return data['id'];
						}}
						{...register(`just_for_form.${item}.align`, {
							rules: {
								required: true,
							},
						})}
						onChange={(selectedOption) => {
							setValue(`just_for_form.${item}.align`, selectedOption);
							setValue(`layout.boxes.${item}.align`, selectedOption.name);
						}}
						isLoading={schoolList.length == 0}
						shouldUnregister={true}
						defaultValue={getValues(`just_for_form.${item}.align`) ?? alignOptions.filter((i)=>{return i.name == getValues(`layout.boxes.${item}.align`) })}
					/>

					{/* </Col> */}
				</Row>
			);
		});

		return toReturn;
	};

	const createEditModalForm = () => {
		return (
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSavePressed)}>
					<Row className='' style={{ overflow: 'hidden', height: 'calc(100vh - 57px)' }}>
						<Col md={4} className='pl-8 pt-3  ' style={{ maxWidth: '400px', overflow: 'scroll', height: 'calc(100%)' }}>
							<FormGroup key={1} label='Panel Name'>
								<Input
									type='text'
									className='form-control'
									name={'name'}
									rules={{
										required: false,
									}}
									// defaultValue={editingPanel.name ?? ''}
								/>
							</FormGroup>
							<label>Paper Size</label>
							<Row key={3} style={{ alignItems: 'center' }} className='justify-content-center'>
								<Col key={2}>
									<FormGroup key={1}>
										<input
											type='number'
											className='form-control'
											placeholder='0'
											{...register('layout.size_x', {
												required: false,
												value: 7,
												valueAsNumber: true,
											})}
											onChange={(e) => {
												const value = e.target.value;
												setValue('layout.size_x', value.toString());
												const page = document.getElementById('page');
												page.style.width = value * scaleValue + 'px';
											}}
											min={0.01}
											max={100}
											step={0.01}
										/>
									</FormGroup>
								</Col>
								<Col sm={1} key={1}>
									<FormGroup key={1} style={{ alignItems: 'center', textAlign: 'center' }} className='justify-content-center'>
										<label style={{ margin: '0px' }}>X</label>
									</FormGroup>
								</Col>
								<Col key={3}>
									<FormGroup key={1}>
										<input
											type='number'
											className='form-control'
											placeholder='0'
											{...register('layout.size_y', {
												required: false,
												value: 5,
												valueAsNumber: true,
											})}
											onChange={(e) => {
												const value = e.target.value;
												setValue('layout.size_y', value.toString());
												const page = document.getElementById('page');
												page.style.height = value * scaleValue + 'px';
											}}
											min={1}
											max={9000}
											step={0.01}
										/>
									</FormGroup>
								</Col>
							</Row>
							<FormGroup key={4} label='Template'>
								<input
									type='file'
									className='form-control'
									id='template'
									{...register('template', {
										required: false,
										onChange: async (e) => {
											const file = e.target.files[0];
											await toBase64(file).then((string) => {
												setPageBackground(string);
											});
										},
									})}
								/>
							</FormGroup>
							<FormGroup key={9} label='Text Size'>
								<input
									type='number'
									className='form-control'
									{...register('layout.font_size', {
										required: false,
										valueAsNumber: true,
									})}
									min={0}
									max={100}
									defaultValue={0}
								/>
							</FormGroup>
							<Row onClick={toggleLayers} className='d-flex align-items-center justify-content-between ml-0 mr-0'>
								{/* <Col> */}
								<h4 className='mr-2'>Graphics</h4>
								<FontAwesomeIcon size='2x' icon={!showLayers ? faAngleDown : faAngleUp} />
								{/* </Col> */}
							</Row>
							{!!showLayers && (
								<div className='' style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
									{getGraphicsList()}
								</div>
							)}
							<FormGroup key={12} label='Number of photos'>
								<input
									type='number'
									className='form-control'
									{...register('just_for_form.photos_num', {
										required: false,
										valueAsNumber: true,
									})}
									min={0}
									max={100}
									// defaultValue={(editingPanel.layout && editingPanel.layout.boxes && Object.keys(editingPanel.layout.boxes).length) ?? 0}
									defaultValue={getValues('just_for_form.photos_num') ?? 0}
									onChange={handlePhotoNumberChange}
								/>
							</FormGroup>
							{selectedPhoto.id > -1 && getImageContextMenu()}
							<Col className='p-0 justify-content-end d-flex'>
								<SubmitButton pending={isSubmitting}>Save</SubmitButton>
							</Col>
						</Col>
						<Col className='p-0 border-l'>
							{/* <EditorArea methods={methods} backgroundImage={previewTemplate} editorStyle={{ height: 'calc(100vh - 76px)' }} defaultWidth={PAGE_SIZE[0]} defaultHeight={PAGE_SIZE[1]} pageBackgroundColor={'gray'}> */}
							<EditorArea methods={methods} editorStyle={{ height: 'calc(100% - 38px)' }} defaultWidth={getValues('layout.size_x') * scaleValue} defaultHeight={getValues('layout.size_y') * scaleValue} pageBackgroundColor={'gray'} backgroundImage={pageBackground} initialScale={initialScale}>
								{createDraggablePhotos()}
							</EditorArea>
						</Col>
					</Row>
				</form>
			</FormProvider>
		);

		return <> error could not create edit form please try again </>;
	};

	const handlePhotoNumberChange = (e) => {
		const numberValue = parseInt(e.target.value);

		const boxes = getValues('layout.boxes');
		const photosKeys = Object.keys(boxes).filter((key) => key.includes('photo_'));

		const photoCount = photosKeys.length;

		if (numberValue > photoCount) {
			// add new boxes

			for (let i = photoCount; i < numberValue; i++) {
				const newBox = {
					top: 0,
					left: 0,
					text: 'Photo ' + (i + 1),
					ratio: 1.25,
					width: 1.0,
					height: 1.0,
					rotated: false,
					height_ratio: 1.25,
					active: true,
				};
				// The plus one here is very important for backwards compatibility
				boxes['photo_' + (i + 1)] = newBox;
			}
		} else if (numberValue < photoCount) {
			// remove those boxes
			photosKeys.forEach((key) => {
				const keyNumber = key.replace('photo_', '');
				// if this box's number is greater than the number of photos
				if (keyNumber > numberValue) {
					delete boxes[key];
				}
			});
		}
		setValue('layout.boxes', { ...boxes });
		setValue('just_for_form.photos_num', numberValue);
	};

	const createDraggablePhotos = () => {
		const toReturn = [];
		// if (getValues('layout.boxes')) {
		for (const [key, value] of Object.entries(watch('layout.boxes'))) {
			// Remember the key is important for ensuring each graphic is unique
			if (key.includes('photo_')) {
				toReturn.push(
				<DraggableGraphic key={key}
					type={GraphicTypes.STAFF_TEMPLATE_PHOTO}
					graphicPrefix='photo_'
					scaleValue={scaleValue}
					initialZoom={initialScale}
					methods={methods}
					setSelectedItem={setSelectedPhoto}
					id={key}
					values={watch('layout.boxes.'+key)}
				/>);
			} else if (key.includes('graphic_')) {
				// do nothing
			} else {
				value["Text"] = key
				toReturn.push(
					<DraggableGraphic
						key={key}
						type={GraphicTypes.STAFF_TEMPLATE_GRAPHIC}
						graphicPrefix={key}
						scaleValue={scaleValue}
						initialZoom={initialScale}
						methods={methods}
						setSelectedItem={setSelectedPhoto}
						id={key}
						values={watch('layout.boxes.'+key)}
						draggableOnly={true}

					/>
				);
			}
		}
		// }

		return toReturn.length > 0 ? toReturn : null;
	};

	const finishedResizingHandler = (e) => {

		const element = e.target;
		const photoId = 'layout.boxes.' + element.id;

		const heightResult = (element.clientHeight / 100) / divideVal;
		const widthResult = (element.clientWidth / 100) / divideVal;

		setValue(photoId + '.height', heightResult);
		setValue(photoId + '.width', widthResult);
		setValue(photoId + '.height_ratio', heightResult / widthResult);
		finishedDraggingHandler(e, element);
	};

	const finishedDraggingHandler = (e, element = null) => {
		if (!element) {
			element = e.target;
		}

		const photoId = 'layout.boxes.' + element.id;
		// const xResult = element.getAttribute('data-x') / parseFloat(getValues('layout.size_x'));
		// const yResult = element.getAttribute('data-y') / parseFloat(getValues('layout.size_y'));

		const xResult = (element.getAttribute('data-x') / parseFloat(getValues('layout.size_x'))) / divideVal;
		const yResult = (element.getAttribute('data-y') / parseFloat(getValues('layout.size_y'))) / divideVal;

		setValue(photoId + '.left', xResult / 100);
		setValue(photoId + '.top', yResult / 100);
	};

	const getImageContextMenu = () => {
		const {element} = selectedPhoto;
		if (!element.classList.contains('finishedResizingHandler')) {
			element.classList.add('finishedResizingHandler');
			element.addEventListener('finishedResizing', finishedResizingHandler, false);
		}
		if (!element.classList.contains('finishedDraggingHandler')) {
			element.classList.add('finishedDraggingHandler');
			element.addEventListener('finishedDragging', finishedDraggingHandler, false);
		}

		const {value} = selectedPhoto;

		const chosenPhotoId = 'layout.boxes.photo_' + selectedPhoto.id;

		if (!getValues(chosenPhotoId)) {
			return <> </>;
		}
		const selectedImage = (
			<div className='border-top pt-2' id={selectedPhoto.id} key={selectedPhoto.id}>
				<FormProvider {...methods}>
					<h3> {value.text ?? ''} </h3>
					<Row key={3} style={{ alignItems: 'center' }} className='justify-content-center'>
						<Col key={2}>
							<FormGroup key={1} label='width'>
								{/* <Input type='number' className='form-control' name={chosenPhotoId + '.width'} value={width} /> */}
								{console.log("", watch('layout.boxes.size_x'))}
								<input
									type='number'
									className='form-control'
									placeholder='0'
									{...register(chosenPhotoId + '.width', {
										required: false,
										// value: {getValues(chosenPhotoId + '.width') },
										valueAsNumber: true,
									})}
									min={0.01}
									max={parseInt(watch('layout.boxes.size_x'))}
									step={0.01}
									onChange={(e) => {
										const {value} = e.target;
										document.getElementById('photo_' + selectedPhoto.id).style.width = value * scaleValue + 'px';
										setValue(chosenPhotoId + '.width', parseFloat(value));
										setValue(chosenPhotoId + '.height_ratio', watch(chosenPhotoId + '.height') / parseFloat(value));
									}}
								/>
							</FormGroup>
						</Col>
						<Col key={3}>
							<FormGroup key={1} label='height'>
								{/* <Input type='number' className='form-control' name={chosenPhotoId + '.height'} value={height} /> */}
								<input
									type='number'
									className='form-control'
									placeholder='0'
									{...register(chosenPhotoId + '.height', {
										required: false,
										// value: { height },
										valueAsNumber: true,
									})}
									min={0.01}
									max={parseInt(watch('layout.boxes.size_y'))}
									step={0.01}
									onChange={(e) => {
										const {value} = e.target;
										document.getElementById('photo_' + selectedPhoto.id).style.height = value * scaleValue + 'px';
										setValue(chosenPhotoId + '.height', parseFloat(value));
										setValue(chosenPhotoId + '.height_ratio', parseFloat(value) / watch(chosenPhotoId + '.width'));
									}}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row className='mb-2' key={8}>
						<Col>
							<FormGroup key={1}>
								<Label check>
									<input
										checked={getValues(chosenPhotoId).rotated}
										// name={chosenPhotoId + '.rotated'}
										{...register(chosenPhotoId + '.rotated', {
											required: false,
										})}
										type='checkbox'
										onChange={(e) => {

											const width = getValues(chosenPhotoId + '.width');
											const height = getValues(chosenPhotoId + '.height');
											const height_ratio = getValues(chosenPhotoId + '.height_ratio');

											// if (checked) {
											setValue(chosenPhotoId + '.height', width);
											setValue(chosenPhotoId + '.width', height);
											// } else {
											// setValue(chosenPhotoId + '.height', height);
											// setValue(chosenPhotoId + '.width', width);
											// }
											setValue(chosenPhotoId + '.height_ratio', 1 / height_ratio);
											setValue(chosenPhotoId + '.rotated', !getValues(chosenPhotoId).rotated ?? true);

											const elementToUpdate = document.getElementById('photo_' + selectedPhoto.id);

											const elementHeight = elementToUpdate.clientHeight;
											const elementWidth = elementToUpdate.clientWidth;

											elementToUpdate.style.height = elementWidth + 'px';
											elementToUpdate.style.width = elementHeight + 'px';

											// syncForm();
											// TODO GET THE ROTATE BUTTON TO RE-RENDER PROPERLY
											setEditingPanel({ ...editingPanel });
										}}
									/>{' '}
									Rotated
								</Label>
							</FormGroup>
						</Col>
					</Row>
				</FormProvider>
			</div>
		);

		if (selectedPhoto.id >= 0) {
			return selectedImage;
		} else {
			return <> Hello </>;
		}
	};

	const displayStaffTemplateList = () => {
		const toShow = [
			<>
			<Row style={{ alignItems: 'center' }} className='justify-content-center'>
					<Col xs='6'>
						<FormGroup>
							<CustomButton disabled={!watch('selected_school')} buttonColor='primary' onClick={toggleEditModal} style={{ width: '100%' }}>
								Create staff template
							</CustomButton>
						</FormGroup>
					</Col>
				</Row>
			<Select
				className='mb-3'
				options={schoolList}
				labelKey="name_city"
				valueKey="id"
				name="selected_school"
				rules={{ required: true }}
				{...register('selected_school', {
					rules: {
						required: true,
					},
				})}
				onChange={(selectedSchool) => {
					setValue('selected_school', selectedSchool);
					getStaffTemplates();
				}}
				isLoading={schoolList.length == 0}
				// loadingMessage={'Loading Jobs'}
			/>
			</>
		];
		proofCards.forEach((element) => {
			toShow.push(createPrintPackFormItem(element));
		});

		return toShow;
	};

	return (
		<>
			{' '}
			{!editModal ? (
				<div className='main-content-container d-flex flex-row'>
					<Page>
          				<div className='font-bold text-2xl tracking-wide pb-3'>Create and edit staff photo templates</div>
						<FormProvider {...methods}>{displayStaffTemplateList()}</FormProvider>
					</Page>
				</div>
			) : (
				createEditModalForm()
			)}
		</>
	);

};

export default CreateNewStaffPhotoTemplate;
