import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button, Modal, ModalBody, ModalFooter, Label } from 'reactstrap';
import { useQuery, useQueryClient } from 'react-query';
import { ModalHeader } from 'components/ModalHeader';
import { toBase64 } from 'components/editors/utils';
import { useForm, FormProvider, useFormContext, useFormState } from 'react-hook-form';
import { RFormGroup as FormGroup, RichSelect as Select, CustomButton, Input, DatePicker } from 'components/react-hook-form';
import { NotificationManager } from 'react-notifications';
import { RSubmitButton as SubmitButton } from 'components/react-hook-form';
import { useUserContext, useClient } from 'hooks';

import 'react-slideshow-image/dist/styles.css';
import { Page } from 'components/common';
import { Slide } from 'react-slideshow-image';

export const CreateNewPanelsCode = ({ setSelectedForm }) => {
	const navigate = useNavigate();
	const client = useClient();
	const { user } = useUserContext();

	const methods = useForm({
		defaultValues: {
			code: ""
		},
		mode: 'all',
	});

	const [showFullForm, setShowFullForm] = useState(false);
	const [printPacks, setPrintPacks] = useState([])
	const [poses, setPoses] = useState([]);

	// submitting spinners
	const [isGettingPoses, setIsGettingPoses] = useState(false)
	const [isCreatingPanels, setIsCreatingPanels] = useState(false)


	function getPoses(){
		setIsGettingPoses(true)
		client
			.get(`proof_cards?search=${getValues('barcode')}`)
			.get('data')
			.then((data) => {
				console.log(data);
				setPoses(data);
				if (!data.length > 0) {
					NotificationManager.error('There are no packs for this code please try again');
				} else {
					const tempPacks = []
					data.forEach(item => {
						item.packs.forEach(pack => {
							pack['poses_arr'] = item.poses
							pack['proof_card_id'] = item.id
						})
						tempPacks.push(...item.packs)
					})
					setPrintPacks([...tempPacks])
					setShowFullForm(true);
				}
				setIsGettingPoses(false)
				return null;
			})
	}

	// const { refetch: getPoses } = useQuery(
	// 	'get_proof_packs',
	// 	async () =>
	// 		client
	// 			.get(`proof_cards?search=${getValues('barcode')}`)
	// 			.get('data')
	// 			.then((data) => {
	// 				console.log(data);
	// 				setPoses(data);
	// 				if (!data.length > 0) {
	// 					NotificationManager.error('There are no packs for this code please try again');
	// 				} else {
	// 					const tempPacks = []
	// 					data.forEach(item => {
	// 						item.packs.forEach(pack => {
	// 							pack['poses_arr'] = item.poses
	// 							pack['proof_card_id'] = item.id
	// 						})
	// 						tempPacks.push(...item.packs)
	// 					})
	// 					setPrintPacks([...tempPacks])
	// 					setShowFullForm(true);
	// 				}
	// 				return null;
	// 			}),
	// 	{
	// 		refetchOnWindowFocus: false,
	// 		refetchOnMount: false,
	// 		enabled: false,
	// 	}
	// );

	// const {
	// 	data: printPacks,
	// 	error: printPanelsError,
	// 	refetch: getPrintPacks,
	// } = useQuery('get_print_packs', async () => client.get(`proof_card_designs`).get('data'), {
	// 	refetchOnWindowFocus: false,
	// });

	const { watch, setValue, formState, reset, handleSubmit, unregister, register, getValues } = methods;


	const watchPack = watch('pack')


	const getChosenPacks = () => {
		const toReturn = []

		watchPack.forEach(pack => {
			console.log(pack.id)
			toReturn.push(
				<Row className="border-b pb-1 pt-1">
					<Col className="d-flex align-items-center">
						{pack.name}
					</Col>
					<Col sm={1} className="d-flex align-items-center justify-center">
						<Label check>
							<Input
								defaultChecked={true}
								disabled = {pack.poses == 1}
								name={'shared-proof'}
								onChange={() => {
									console.log('Check Pressed');
								}}
								type='checkbox'
							/>
						</Label>
					</Col>
					<Col className="d-flex align-items-center">
						{pack.poses_arr[0]}
					</Col>
				</Row>
			)
		})

		return toReturn
	}


	const mainForm = () => {
		return (
			<>
				<FormGroup name="pack" label='Print Packs'>
					<Select
						isMulti
						name="pack"
						labelKey="name"
						valueKey="id"
						options={printPacks}
						rules={{ required: true }}
						onChange={(newValue) => {
							console.log('e', newValue);
							setValue(`pack`, newValue);
						}}
					/>
				</FormGroup>
				{(watchPack && <Row className="border-b">
					<Col className="d-flex align-items-center">
						<h5>Pack</h5>
					</Col>
					<Col sm={1} className="d-flex align-items-center justify-center">
						<h5>Pose</h5>
					</Col>
					<Col className="d-flex align-items-center">
						<h5>Graphic</h5>
					</Col>
				</Row>)}
				{watchPack && getChosenPacks()}
			</>
		);
	}




	const createForm = () => {
		return (
			<FormProvider {...methods}>
				<FormGroup name="barcode" label="Code">
					<Row>
						<Col md={9} className='mb-2'>
							<Input
								type='text'
								className='form-control'
								name='barcode'
								rules={{ required: false }}
							/>
						</Col>
						<Col md={3} className='mb-2'>
							<SubmitButton disabled={!watch('barcode')} className='w-100' color="darker" onClick={() => {
								getPoses()
							}} pending={isGettingPoses}>
								Submit code
							</SubmitButton>
						</Col>
					</Row>
				</FormGroup>


					{ showFullForm && mainForm()}
			</FormProvider>
		);
	};

	const onSavePressed = async (all, e) => {
		setIsCreatingPanels(true)
		console.log('event: ', e);
		console.log('Saving Now', all);

		const panel = all;

		// dont need to send this off
		delete panel.images;

		const toSend = []
		panel.pack.forEach((pack) => {
			console.log('pack: ', pack);
			const toAdd = {
				poses:pack.poses_arr,
				proof_card_id:pack.proof_card_id,
				pack_id: pack.id
			}
			toSend.push(toAdd)
		})


		console.log(toSend)
		// return
		client
			.post('/generate_panels', { job_type: 'PANELS_PROOF', job_data: toSend })
			.then((resp) => {
				setSelectedForm(null);
				NotificationManager.success('Panel has been created');
				const id = resp.data?.id;
				navigate(!!id ? `/viewworkflow/${id}` : "/viewworkflow");
				setIsCreatingPanels(false)
				return null;
			})
			.catch((e) => {
				console.error('ERROR: ', e.response.data);
				NotificationManager.error('There was an issue with this request');
				setIsCreatingPanels(false)
				return null;
			});
	};

	return (
		<>
			{createForm()}
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSavePressed)}>
					<Row className='justify-content-end mt-4'>
						<Col md={2}>
							<FormGroup>
								<Button
									className='w-100'
									onClick={() => {
										setSelectedForm(null);
									}}
									color='danger'
								>
									Cancel
								</Button>
							</FormGroup>
						</Col>
						<Col md={2}>
							<FormGroup>
								<SubmitButton disabled={poses.length === 0 || printPacks.length === 0 || !watchPack} 
									className='w-100' onClick={() => {}} color='darker' pending={isCreatingPanels}>
									Create panels
								</SubmitButton>
							</FormGroup>
						</Col>
					</Row>
				</form>
			</FormProvider>
		</>
	);
};

export default CreateNewPanelsCode;
