import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { NotificationManager } from 'react-notifications';
import { useClient, useUserContext } from 'hooks';
import { useForm, FormProvider, useFormContext, useFormState } from 'react-hook-form';
import { FormGroup, Select, SubmitButton, Input, DatePicker } from 'components/react-hook-form';
import { Alert, Button, Container, Card, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import { Slide, Fade } from 'react-slideshow-image';

// CSS
import 'react-slideshow-image/dist/styles.css';
import 'assets/css/style.css';

// import { Formik, Form, Field, FieldArray } from "formik";

// import * as Yup from "yup";

// import { FileToB64Field, ErrorLabel, passwordRegExp, phoneRegExp, openInNewTab, SubmitButton } from "components/common";

export const Signup = () => {
	const methods = useForm({
		defaultValues: {
			// connected_organisation: selectedOrganisation,
		},
		mode: 'all',
	});
	const {
		register,
		handleSubmit,
		watch,
		setValue,
		getValues,
		formState: { errors },
		clearErrors,
		setError,
	} = methods;

	const client = useClient();
	const { query } = useUserContext();
	const navigate = useNavigate();

	const onSubmit = (formData) => {
		console.log('Mmmm');
		console.log(formData);
		console.log(errors);

		client
			.post('/signup', {
				username: formData.email,
				password: formData.password,
			})
			.then((responseData) => {
				console.log('DATA', responseData);
				// NotificationManager.success("Logged in!");
				navigate('/home');
				// history.replace('/');
			})
			.catch((ex) => {
				console.log(ex.response);
				const errorMessages = ex?.response?.data?.detail;
				for (const [key, value] of Object.entries(errorMessages)) {
					console.log(`${key}:`, value);
					// console.log('adding error: ', message);
					setError(key, { message: value[0] });
				}
				// errorMessages.forEach(message => {
				// 	console.log("adding error: ", message)
				// 	// setError('apiError', { message: message });
				// })
				NotificationManager.error('An error occurred with Signup.');
			});
	};

	const slideImages = [
		{
			url: 'https://images.unsplash.com/photo-1633542804128-9ae60b3d5249?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80',
			caption: 'First Slide',
		},
		{
			url: 'https://images.unsplash.com/photo-1633542804128-9ae60b3d5249?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80',
			caption: 'Second Slide',
		},
		{
			url: 'https://images.unsplash.com/photo-1633542804128-9ae60b3d5249?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80',
			caption: 'Third Slide',
		},
	];

	const Slideshow = () => {
		return (
			<div className='slideshow-container'>
				<Slide easing='ease'>
					<div className='each-slide'>
						<div style={{ backgroundImage: `url(${slideImages[0].url})` }}>{/* <span>Slide 1</span> */}</div>
					</div>
					<div className='each-slide'>
						<div style={{ backgroundImage: `url(${slideImages[1].url})` }}>{/* <span>Slide 2</span> */}</div>
					</div>
					<div className='each-slide'>
						<div style={{ backgroundImage: `url(${slideImages[2].url})` }}>{/* <span>Slide 3</span> */}</div>
					</div>
				</Slide>
			</div>
		);
	};

	return (
		// <div style={{ height: '100vh' }}>

		<>
			{/* Slideshow container */}
			{/* <div style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}>{Slideshow()}</div> */}

			{/* Form Container */}
			<div className='login-container'>
			<img className="mx-auto pt-20" src="logo.png"/>
			<Container fluid='sm' style={{ height: 'calc(100% - 223px)', width: '25%', maxWidth: '1200px', minWidth: '500px' }}>
					<Row className='align-items-center' style={{ height: '100%' }}>
						<Col className='login-card'>
							<FormProvider {...methods}>
								<form onSubmit={(e) => e.preventDefault()}>
									<h3>Create Account</h3>

									<FormGroup>
										<label>Email address</label>
										<Input
											type='email'
											className='form-control'
											name={'email'}
											placeholder='Enter email'
											rules={{
												required: false,
												validate: {
													emailEqual: (value) => value === getValues().email || 'Email confirmation error!',
												},
											}}
											onChange={(e) => {
												const value = e.target.value;
												if (value !== getValues().email) {
													clearErrors('username');
												}
												setValue('email', value);
											}}

											// ref={register({
											// 	validate: {
											// 		emailEqual: (value) => value === getValues().email || 'Email confirmation error!',
											// 	},
											// })}
										/>
										{errors.username && <div className='alert alert-danger mt-1 mb-0 p-2'>{errors.username?.message}</div>}
									</FormGroup>
									<FormGroup>
										<label>Password</label>
										<Input
											type='password'
											className='form-control'
											name={'password'}
											placeholder='Enter password'
											// onChange={clearErrors('password')}
											onChange={(e) => {
												const value = e.target.value;
												if (value !== getValues().password) {
													clearErrors('password');
												}
												setValue('password', value);
											}}
											// rules={{
											// 	required: false,
											// }}
											// {...register('password', {
											// 	// validate: (value) => value === getValues().password || 'The passwords do not match',
											// 	// required: 'You must specify a password',
											// 	minLength: {
											// 		value: 8,
											// 		message: 'Password must have at least 8 characters',
											// 	},
											// })}
										/>
										{errors.password && <div className='alert alert-danger mt-1 mb-0 p-2'>{errors.password?.message}</div>}
									</FormGroup>

									<FormGroup>
										<label>Confirm Password</label>
										<Input
											type='password'
											className='form-control'
											name={'password_confirm'}
											placeholder='Enter password'
											// onChange={clearErrors('password_confirm')}
											// rules={{
											// 	required: false,
											// 	validate: {
											// 		passwordEqual: (value) => value === getValues().password || 'The passwords do not match',
											// 	},
											// }}
											// {...register('password_confirm', {
											// 	validate: (value) => value === getValues().password || 'The passwords do not match',
											// })}
										/>
									</FormGroup>
									{errors.password_confirm && <div className='alert alert-danger mt-1 mb-0 p-2'>{errors.password_confirm?.message}</div>}

									{/* <div className='form-group'>
										<div className='custom-control custom-checkbox'>
											<input type='checkbox' className='custom-control-input' id='customCheck1' />
											<label className='custom-control-label' htmlFor='customCheck1'>
												Remember me
											</label>
										</div>
									</div> */}

									<SubmitButton onClick={handleSubmit(onSubmit)}>Register</SubmitButton>
									<br />
									<br />

									<Row>
										<Col>
											<p className='forgot-password text-left'>
												Already have an account?
												<br />
												<a href='login'>Sign In</a>
											</p>
										</Col>
										<Col>
											<p className='forgot-password text-right'>
												Forgot your password?
												<br />
												<a href='#'>Reset Password</a>
											</p>
										</Col>
									</Row>
								</form>
							</FormProvider>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

export default Signup;
