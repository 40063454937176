import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { FormGroup, RichSelect as Select } from 'components/react-hook-form';
import { Row, Col, Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { ModalHeader } from 'components/ModalHeader';
import { NotificationManager } from 'react-notifications';
import { toBase64 } from 'components/editors/utils';
import { useClient } from 'hooks';
import { useNavigate } from 'react-router';
import { Pagination, Table, Search, useClientPaginatedRowSelectTable, TableContextProvider } from '../../components/common/Tables';



import { ImageGrid, IndividualNoDataModal, PhotoModal } from '.'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import PendingButton from 'components/common/PendingButton';



const UploadPhotoMainModal = ({
  methods,
  toggleClassDetail,
  isOpen,
  setSelectedStudent,
  setShowStudentDetail,
  groupOptions,
  data,
  setData,
  addPerson,
  toggleShowAddGroupModel,
  filesArray,
  setFilesArray,
  photoType,
}) => {
  const { watch, setValue, register, handleSubmit } = methods;
  const navigate = useNavigate();
  const setStudent = useCallback((x) => setSelectedStudent(x), [setSelectedStudent]);
  const setDetail = useCallback((x) => setShowStudentDetail(x), [setShowStudentDetail]);
  const _groupOptions = useMemo(() => groupOptions, [groupOptions]);
  const [noDataIsOpen, setNoDataIsOpen] = useState(false);

  const toggleNoDataModal = () => { setNoDataIsOpen(!noDataIsOpen) };
  const [pending, setPending] = useState(false);
  const dataRef = useRef(null)
  dataRef.current = data;
  const columns = [
    {
      Header: 'Forename',
      accessor: 'first_name',
    },
    {
      Header: 'Surname',
      accessor: 'surname',
    },
    {
      Header: 'Group',
      accessor: 'class_name',
    },
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Absent',
      accessor: 'absent',
      Cell: ({ value }) => {
        return (value ? <FontAwesomeIcon className="text-green-500" icon={faCheckCircle} />
          : <></>);
      },
      disableSortBy: true,
    },
    {
      Header: "Photo",
      accessor: "photo",
      disableSortBy: true,
      Cell: (cellProps) => {
        let photo = cellProps.row.values.photo ?? null;
        if (!photo) {
          return null;
        }
        return (
          <img
            className='rounded'
            style={{ width: '50px', height: '50px', objectFit: 'cover' }}
            src={photo.image ?? (photo.file ? window.URL.createObjectURL(photo.file) : null) ?? null}

          />
        )
      }
    },
  ];

  const getRowProps = (row) => {
    return {
      onClick: async () => {
        setStudent(row.values)
        setValue('students.' + row.values.id, row.values)
        setDetail(true)
      },
      style: { cursor: 'pointer' },
    };
  };
  const [filteredData, setFilteredData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const groupFilter = watch('select_group');
  const photo_type = watch('photo_type')
  useEffect(() => {
    let filtered = data;
    if (groupFilter) filtered = filtered.filter(e => e.class_name === groupFilter.name);
    setFilteredData(filtered);
  }, [data, groupFilter]);

  const handleGroupImageSelect = (file, image, fileObject) => {
    setPhotoModalOpen(true);
    setSelectedImage({ file: file, image: image, fileObject: fileObject })
  }
  // const handleSiblingsImageSelect = (file, image, fileObject) => {
  //   setPhotoModalOpen(true);
  //   setSelectedImage({ file: file, image: image, fileObject: fileObject })
  // }
  // const handleStaffImageSelect = (file, image, fileObject) => {
  //   setPhotoModalOpen(true);
  //   setSelectedImage({ file: file, image: image, fileObject: fileObject })
  // }
  const handleNoDataImageSelect = (file, image, fileObject) => {
    setNoDataIsOpen(true)
    setSelectedImage({ file: file, image: image, fileObject: fileObject })

  }

  const { table } = useClientPaginatedRowSelectTable({
    data: filteredData,
    setData,
    initialState: {
      pageSize: 10
    },
    columns: useMemo(() => columns, []),
    selectionColumn: false
  });

  const client = useClient();

  const onSubmit = async (x) => {
    try {
      setPending(true);
      const payload = await {
        job_id: x.selected_job.id,
        data: Object.values(filesArray).map(e => ({
          photo_type: "No Data",
          encoded_photo: toBase64(e.file),
          photo_name: e.file.name,
        }))
      };
      await client.post('/start_photographer_job', payload);
      NotificationManager.success('Files added successfully');
      toggleClassDetail();
      navigate("/viewphotographerjob");
    }
    catch (ex) {
      console.error('ERROR: ', ex.response.data);
      NotificationManager.error('There was an issue with this request');
    }
    finally {
      setPending(false);
    }
  }

  const [photoModalOpen, setPhotoModalOpen] = useState(false);
  const toggleModal = () => setPhotoModalOpen(x => !x);

  const compareFn = useMemo(() => {
    if (!photo_type) return () => true;
    if (photo_type.id === 1) {
      return (x) => x.group;
    } else if (photo_type.id === 2) {
      return (x) => x.siblings;
    }
    else if (photo_type.id === 3) {
      return (x) => x.staff;
    }
    else if (photo_type.id === 4) {
      return (x) => x.assigned === null;
    }
    return () => true;
  }, [photo_type]);

  const handlePhotoClick = useMemo(() => {
    if (!photo_type) return () => { };
    if (photo_type.id === 4) {
      return handleNoDataImageSelect;
    }
    return handleGroupImageSelect;
  }, [photo_type]);

  return (
    <Modal isOpen={isOpen} toggle={toggleClassDetail} className='md-modal'>
      <ModalHeader toggle={toggleClassDetail} charCode='close' className=""
        close={<FontAwesomeIcon icon={faTimes} onClick={toggleClassDetail} role='button' />}>
      </ModalHeader>
      <ModalBody>
        <Row className="justify-between">
          <Col md={4}>
            <FormGroup label="Group">
              <Select
                options={_groupOptions}
                labelKey="name"
                valueKey="id"
                isClearable
                name="select_group"
                placeholder="Filter by group name..."
                rules={{ required: false }}
                shouldUnregister={true}
                defaultValue={''}
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <Row className="w-full">
              <FormGroup label="Photo Type" className="w-full">
                <Select
                  options={photoType}
                  labelKey="name"
                  valueKey="id"
                  name="photo_type"
                  rules={{ required: false }}
                  shouldUnregister={true}
                  defaultValue={""}
                />
              </FormGroup>
            </Row>
            <Row className="w-full justify-end">
              <Button size="sm" className="m-2" onClick={addPerson}>Add Person</Button>
              <Button size="sm" className="m-2" onClick={toggleShowAddGroupModel}>Add Group</Button>
            </Row>

          </Col>
        </Row>

        {/* Individual */}
        <IndividualNoDataModal
          methods={methods}
          toggleModal={toggleNoDataModal}
          isOpen={noDataIsOpen}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          data={data}
          setData={setData}
          groups={_groupOptions}
          files={filesArray}
          setFilesArray={setFilesArray}
          photoType={photoType}
        />
        <PhotoModal
          methods={methods}
          toggleModal={toggleModal}
          isOpen={photoModalOpen}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          data={data}
          setData={setData}
          groups={_groupOptions}
          files={filesArray}
          setFilesArray={setFilesArray}
        />
        {photo_type?.id == 0 ?
          <TableContextProvider table={table}>
            <Row form>
              <Col>
                <Search placeholder='Search' />
              </Col>
            </Row>
            <Table size="sm" clientSidePagination striped getRowProps={getRowProps} />
            <Pagination />
          </TableContextProvider>
          : (
            <ImageGrid
              methods={methods}
              handleImageSelect={handlePhotoClick}
              files={filesArray}
              height='calc(100vh - 400px)'
              compareFn={compareFn}
            />
          )
        }
      </ModalBody>

      <ModalFooter>
        <Row className='justify-content-end'>
          <Col>
            <Button color="danger" onClick={toggleClassDetail}>Close</Button>
          </Col>
          <Col>
            <PendingButton pending={pending} color='primary'
              disabled={Object.keys(filesArray).length === 0}
              onClick={handleSubmit(onSubmit)}>
              Submit
            </PendingButton>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};


export default UploadPhotoMainModal