import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormGroup, Select, CustomButton, Input, DatePicker, SubmitButton } from 'components/react-hook-form';
import { useForm, FormProvider, useFormContext, useFormState } from 'react-hook-form';
import { Row, Col, Button, Modal, ModalBody, ModalFooter, Label } from 'reactstrap';
import { ModalHeader } from 'components/ModalHeader';
import { useUserContext, useClient } from 'hooks';
import { createClient } from 'hooks/useClient';
import { useQuery, useQueryClient } from 'react-query';

import { Page } from 'components/common';

export const AutoImportSystemPresets = () => {
	const navigate = useNavigate();
	const client = useClient();
	const { user } = useUserContext();

	const methods = useForm({
		defaultValues: {},
		mode: 'all',
	});

	const { watch, setValue, formState, reset, handleSubmit, unregister, register, getValues } = methods;

	const [systemPresets, setPresets] = useState([]);

	// Edit Modal State
	const [editModal, setEditModal] = useState(false);
	const toggleEditModal = () => setEditModal(!editModal);

	const { refetch: getSystemPresets } = useQuery(
		'get_system_presets',
		async () => {
			client.get(`system_preset`).then((data) => {
				// console.log('response data:', data);
				setPresets(data.data);
				return null;
			});
			return null;
		},
		{
			refetchOnWindowFocus: false,
		}
	);



	const getIndividualPreset = (presetID) => {
		client.get(`system_preset/${presetID}`).then((data) => {
			reset(data.data.preset_data);
			setValue('preset_id', presetID)
			toggleEditModal();
			return null;

			// setPrintPacks(data.data);
		});
		return null;
	};


	function deletePreset(id) {
		// console.log(id)
		client
			.delete(`/system_preset/${id}`)
			.then((data) => {
				getSystemPresets();
				return null;
			})
			.catch((e) => {
				console.error('ERROR: ', e.response.data);
				return null;
			});
	}

	// const { refetch: getIndividualPrintPack } = useQuery(
	// 	`get_individual_system_preset`,
	// 	async () => {
	// 		if (editingPack.id != -1) {

	// 		}
	// 		return null;
	// 	},
	// 	{
	// 		refetchOnWindowFocus: false,
	// 	}
	// );

	// const {
	// 	data: printPanelsList,
	// 	error: printPanelsError,
	// 	refetch: getPrintPanelsList,
	// } = useQuery('get_paint_panels_list', async () => client.get(`print_panels`).get('data'), {
	// 	refetchOnWindowFocus: false,
	// });

	// const getPrintPacks = null
	// const getIndividualPrintPack = null;

	// useEffect(() => {
	// 	if (editingPack.active === undefined) {
	// 		if (editingPack.id === -1) {
	// 			// Do Nothing
	// 		} else {
	// 			getIndividualPrintPack();
	// 		}
	// 	}
	// }, [editingPack]);

	const createPresetListItem = (element) => {
		console.log(element);
		return (
			<FormGroup key={element.id}>
				<Row key={element.id} style={{ alignItems: 'center' }} className='justify-content-center'>
					<Col xs='4' key={1}>
						<h5
							style={{
								float: 'right',
								margin: '0px',
								width: '100%',
								textAlign: 'end',
								textOverflow: 'ellipsis',
								overflow: 'hidden',
								wordWrap: 'break-word',
								display: 'block',
								lineHeight: '1em',
								maxHeight: '2em',
							}}
						>
							{' '}
							{element.preset_name}{' '}
						</h5>
					</Col>
					<Col xs='2' key={2}>
						<CustomButton
							style={{ width: '100%' }}
							buttonColor='primary'
							onClick={() => {
								// handleEditModal(element);
								getIndividualPreset(element.id);
							}}
						>
							Edit
						</CustomButton>
					</Col>
					<Col xs='2' key={2}>
						<CustomButton
							style={{ width: '100%' }}
							buttonColor='danger'
							onClick={() => {
								// handleEditModal(element);
								// getIndividualPreset(element.id)
								deletePreset(element.id);
							}}
						>
							delete
						</CustomButton>
					</Col>
				</Row>
			</FormGroup>
		);
	};

	const displayPrintPackList = () => {
		const toShow = [];
		// console.log(printPacks)
		systemPresets.forEach((element) => {
			toShow.push(createPresetListItem(element));
		});

		return toShow;
	};

	// const handleEditModal = (element) => {
	// 	resetFormState();
	// 	setEditingPack(element);
	// 	if (element.id === -1) {
	// 		toggleEditModal();
	// 	}
	// 	// loadPrintPanels();
	// };

	// const resetFormState = () => {
	// 	reset()
	// 	setPosesOptions([{ value: '1', label: '1' }]);
	// 	// setPrintPanels([]);
	// };

	const onSavePressed = ({ ...all }) => {
		console.log('Saving Now', all);

		const packToSave = {preset_name: getValues('name'), preset_data: all};
		// packToSave.id = editingPack.id;

		if (packToSave.preset_data.crop) {
			delete packToSave.preset_data.scale
		} else {
			packToSave.preset_data.crop = false;
		}

		console.log('packToSave', packToSave);

		console.log('MY ID', getValues('preset_id'));

		if (getValues('preset_id')) {
			const url = `/system_preset/${getValues('preset_id')}`;
			client
				.put(url, packToSave)
				.then((data) => {
					getSystemPresets();
					reset({})
					// toggleEditModal();
					setEditModal(false)
					return null;
				})
				.catch((e) => {
					console.error('ERROR: ', e.response.data);
					return null;
				});
		} else {
			client
				.post('/system_preset', packToSave)
				.then((data) => {
					getSystemPresets();
					reset({})
					setEditModal(false)
					return null;
				})
				.catch((e) => {
					console.error('ERROR: ', e.response.data);
					return null;
				});
		}

	};

	const createPrintPanelFormItem = (quantity, pose, panel, id) => {
		return (
			<Row className='mb-3' key={id}>
				<Col key={1} xs='3'>
					<Select
						getOptionLabel={(data) => {
							return data['name'];
						}}
						getOptionValue={(data) => {
							return data['id'];
						}}
						options={[]}
						{...register('panels.' + id + '.panel', {
							rules: {
								required: false,
							},
						})}
						onChange={(newValue) => {
							console.log('e', newValue);
							setValue('panels.' + id + '.panel', newValue);
						}}
						shouldUnregister={true}
						defaultValue={panel ?? ''}
					/>
				</Col>
				<Col key={2} xs='3'>
					<Select
						options={[]}
						// name={editingPack.id + '.panels.' + id + '.pose'}
						// rules={{
						// 	required: false,
						// }}
						getOptionLabel={(data) => {
							// "data" contains the current option object, so you just use your "labelKey" to return the value of that property.
							return data['value'];
						}}
						getOptionValue={(data) => {
							// "data" contains the current option object, so you just use your "valueKey" to return the value of that property.
							return data['label'];
						}}
						{...register('panels.' + id + '.pose', {
							pattern: {
								value: /^[0-9]+$/,
								message: 'Please enter a number',
							},
							min: 0,
							rules: {
								required: false,
							},

							// valueAsNumber: true,
						})}
						onChange={(newValue) => {
							console.log('e', newValue);
							setValue('panels.' + id + '.pose', newValue);
						}}
						shouldUnregister={true}
						defaultValue={pose ?? ''}
					/>
				</Col>
				<Col key={3} xs='3'>
					<Input
						type='number'
						className='form-control'
						name={'panels.' + id + '.quantity'}
						placeholder='enter name'
						rules={{
							required: false,
						}}
						defaultValue={quantity ?? 0}
					/>
				</Col>
				<Col key={4} xs='3'>
					<CustomButton
						onClick={(val) => {
							// const editingPack = editingPackRef.current;
							// const panelsState = editingPack.panels
							// delete panelsState[id];
							// console.log(panelsState);
							// unregister('panels.' + id);
							// editingPack.panels = panelsState;
							// setEditingPack({ ...editingPack });
						}}
						style={{ width: '100%', height: '100%' }}
						buttonColor='danger'
					>
						Remove
					</CustomButton>
				</Col>
			</Row>
		);
	};

	const createEditModalForm = () => {
		const fileOptions = [
			{ id: 0, name: 'JPEG' },
			{ id: 1, name: 'BMP' },
			{ id: 2, name: 'PNG' },
		];

		const SCALE_OPTIONS = [
			{ label: "100%", value: "100%" },
			{ label: "50%", value: "50%" }
		  ];

		return (
			<FormProvider {...methods}>
				<FormGroup key={1} label='Preset Name'>
					<Input
						type='text'
						className='form-control'
						name={'name'}
						placeholder='Enter Name'
						rules={{
							required: false,
						}}
						defaultValue={getValues('preset_name') ?? ''}
					/>
				</FormGroup>
				<FormGroup key={2} label='File Type'>
					<Select
						options={fileOptions}
						getOptionLabel={(data) => {
							return data['name'];
						}}
						getOptionValue={(data) => {
							return data['id'];
						}}
						name={'file_type'}
						rules={{
							required: false,
						}}
						shouldUnregister={true}
						defaultValue={{ id: 0, name: 'JPEG' }}
					/>
				</FormGroup>
				<FormGroup key={4} label='DPI'>
					<input
						type='number'
						className='form-control'
						placeholder='0'
						{...register('dpi', {
							required: false,
							// value: {getValues(chosenPhotoId + '.width') },
							valueAsNumber: true,
						})}
						min={0}
						// max={parseInt(watch('DPI'))}
						// step={1}
						// onChange={(e) => {
						// 	const value = e.target.value;
						// 	// document.getElementById('photo_' + selectedPhoto.id).style.width = value * scaleValue + 'px';
						// 	setValue('DPI', value);
						// }}
					/>
				</FormGroup>
				<br />
				<FormGroup key={5}>
					<Label check>
						<Input
							name={'crop'}
							defaultChecked={getValues('crop') ?? false}
							onChange={() => {
								// console.log('Check Pressed');
							}}
							type='checkbox'
						/>{' '}
						Crop Image
					</Label>
				</FormGroup>
				<br />
				{watch('crop') ?
				(<>
					<FormGroup key={5} label={'Dimensions (px)'}>
						<Row key={3} style={{ alignItems: 'center' }} className='justify-content-center'>
							<Col sm={1} key={1}>
								<FormGroup key={1} style={{ alignItems: 'center', textAlign: 'center' }} className='justify-content-center'>
									<label style={{ margin: '0px' }}>Width:</label>
								</FormGroup>
							</Col>
							<Col key={2}>
								<FormGroup key={1}>
									<Input
										type='number'
										className='form-control'
										name={'width'}
										rules={{
											required: false,
										}}
										defaultValue={getValues('width') ?? 0}
									/>
								</FormGroup>
							</Col>
							<Col sm={1} key={1}>
								<FormGroup key={1} style={{ alignItems: 'center', textAlign: 'center' }} className='justify-content-center'>
									<label style={{ margin: '0px' }}>Height:</label>
								</FormGroup>
							</Col>
							{/* <Col sm={1} key={1}>
							<FormGroup key={1} style={{ alignItems: 'center', textAlign: 'center' }} className='justify-content-center'>
								<label style={{ margin: '0px' }}>X</label>
							</FormGroup>
						</Col> */}
							<Col key={3}>
								<FormGroup key={1}>
									<Input
										type='number'
										className='form-control'
										name={'height'}
										rules={{
											required: false,
										}}
										defaultValue={getValues('height') ?? 0}
									/>
								</FormGroup>
							</Col>
						</Row>

					</FormGroup>
				</>) : (<>
					<FormGroup name="scale" label="Scale">
						<Select
							name="scale"
							// className='w-100'
							options={SCALE_OPTIONS}
							labelKey="label"
							valueKey="value"
							rules={{ required: true }}
						/>
						</FormGroup>
				</>)}
			</FormProvider>
		);
	};



	return (
		<div className='main-content-container d-flex flex-row'>
			<Page>
			<div className='font-bold text-2xl tracking-wide mb-3'> Auto Import System Presets </div>
				<hr />
				<FormProvider {...methods}>
					{displayPrintPackList()}

					<Row style={{ alignItems: 'center' }} className='justify-content-center'>
						<Col xs='6'>
							<FormGroup>
								<CustomButton
									buttonColor='primary'
									onClick={() => {
										reset({});
										toggleEditModal();
									}}
									style={{ width: '100%' }}
								>
									Create New Preset
								</CustomButton>
							</FormGroup>
						</Col>
					</Row>
				</FormProvider>

				{/* Editor Modal */}
				<div>
					<Modal size='lg' isOpen={editModal}>
						<ModalHeader>{watch('preset_name') === undefined ? 'Create New Preset' : 'Editing ' + getValues('preset_name')}</ModalHeader>

						<ModalBody>{editModal ? createEditModalForm() : <></>}</ModalBody>

						<ModalFooter>
							<FormProvider {...methods}>
								<form onSubmit={handleSubmit(onSavePressed)}>
									<Row>
										<Col>
											<Button
												onClick={() => {
													setEditModal(false);
												}}
											>
												Cancel
											</Button>
										</Col>
										<Col>
											<SubmitButton>Save</SubmitButton>
										</Col>
									</Row>
								</form>
							</FormProvider>
						</ModalFooter>
					</Modal>
				</div>
			</Page>
		</div>
	);
};

export default AutoImportSystemPresets;
