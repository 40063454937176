import React, { useRef, useEffect, useState } from 'react';

export const DraggableImage = ({ id, setSelectedItem, src }) => {
	const node = useRef();

	const [borderCol, setBorderCol] = useState('Yellow');

	useEffect(() => {
		// add when mounted
		document.addEventListener('mousedown', handleClick); // return function to be called when unmounted
		return () => {
			document.removeEventListener('mousedown', handleClick);
		};
	}, []);

	const handleClick = (e) => {
		if (node.current.contains(e.target)) {
			// console.log('indside click');
			const result = parseInt(e.target.id.replace('draggable-image-', ''));
			setBorderCol('Blue');

			// const selectedIndex = e.target.options.selectedIndex;
			setSelectedItem({ id: result, type: 'composite_area' });
			// e.target.style.border = '5px solid Red';
			return;
		} // outside click
		// console.log('outside click');
		// console.log(e);
		// e.target.style.border = '0px solid Blue';
		setBorderCol('Red');
		if (e.target.className != 'draggable-container' && e.target.className != 'composite_area') {
			setSelectedItem({ id: -1, type: 'None' });
		}
	};

	// const handleOnclick = (event) => {
	// 	console.log(event);
	// 	event.target.style.border = '5px solid Red';
	// };

	// const handleClickOutside = (event) => {
	// 	console.log(event);
	// 	event.target.style.border = '0px solid Red';
	// };

	return (
		<div ref={node} id={id} style={{ border: '5px solid ' + borderCol }} className='draggable-container'>
			<img className='fill' src={src} alt=''></img>
		</div>
	);
};

export default DraggableImage;
