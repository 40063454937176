
import React, { useState } from 'react';

import { Page } from 'components/common';
import { useClient } from 'hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Col, Row } from 'reactstrap';


export const PhotographerDownloadJobData = () => {
	const methods = useForm({
		defaultValues: {},
		mode: 'all',
	});

	const client = useClient();

    const [jobList, setJobList] = useState([])


	const { watch, setValue, formState, reset, handleSubmit, unregister, register, getValues } = methods;


    // photographer_job_list

    const { refetch: getJobs } = useQuery(
		'get_photographer_job_list',
		async () => {
			client.get(`photographer_job_list`).then((data) => {
				console.log('response data:', data);
				// setPrintPanels(data.data);

                setJobList(data.data)

				// const jobs = data.data
				// jobs.forEach((job) => {
				// 	if(job.has_data){
				// 		console.log('job:', job)
				// 	}
				// })

				// setJobOptions(data.data.sort(compare));
			});
			return null;
		},
		{
			refetchOnWindowFocus: false,
		}
	);

    const showListOfJobs= () => {
        const toReturn = []

        if (!jobList.length > 0) {
            return <h1>Loading...</h1>
        }

        jobList.forEach(job => {
            toReturn.push(
                <Row>
                    <Col>
                        <h6>{job.school_name}</h6>
                    </Col>
                </Row>
            )
        })

        return toReturn
    }

    return (<>
        <Page title={<h1> Download Job Data </h1>}>
            <FormProvider {...methods}>
                {/* <FormGroup key={1} label='Email'>

                    <Input
                        type='text'
                        className='form-control'
                        name={'email'}
                        placeholder='Enter email'
                        rules={{
                            required: false,
                        }}
                    />
                </FormGroup>

                <FormGroup key={1} label='Password'>
                    <Input
                        type='password'
                        className='form-control'
                        name={'password'}
                        placeholder='Enter password'
                        rules={{
                            required: false,
                        }}
                    />
                </FormGroup> */}
                {!!jobList && showListOfJobs()}
           </FormProvider>
        </Page>
    </>)
};

export default PhotographerDownloadJobData;
