import interact from 'interactjs';
/* The dragging code for '.draggable' from the demo above
* applies to this demo as well so it doesn't have to be repeated. */

// var lastDropzone;

var lastDropZone




// enable draggables to be dropped into this
// interact('.editor-dropZone').dropzone({
// 	// only accept elements matching this CSS selector
// 	accept: '.draggable-container',
// 	// Require a 75% element overlap for a drop to be possible
// 	overlap: 0.9,

// 	// listen for drop related events:

// 	ondropactivate: function (event) {
// 		// add active dropzone feedback
// 		// console.log("target ID = " + event.target.id);
// 		// event.target.classList.add('drop-active');
// 		console.log('Picked Up');
// 		// console.log(lastDropZone);
// 		// event.target.classList.zIndex = "50"
// 		// setLastZone(event.target.getBoundingClientRect());
// 		// console.log(getLastZone());
// 		// lastDropzone = event.target.id;
// 	},
//   ondragenter: function (event) {
//     console.log("entered")
// 		// var draggableElement = event.relatedTarget
// 		// var dropzoneElement = event.target
// 		// // feedback the possibility of a drop
// 		// dropzoneElement.classList.add('drop-target')
// 		// draggableElement.classList.add('can-drop')
// 		// draggableElement.textContent = 'Dragged in'
// 	},
//   ondragleave: function (event) {
//     console.log('left');

// 		// remove the drop feedback style
// 		// event.target.classList.remove('drop-target')
// 		// event.relatedTarget.classList.remove('can-drop')
// 		// // event.relatedTarget.textContent = 'Dragged out'
// 		// lastDropZone =   event.target.id
// 		// console.log(lastDropZone);
// 	},
// 	ondrop: function (event) {
//     console.log('dropped item');
// 		// event.relatedTarget.textContent = 'Dropped'
// 		// alert(event.relatedTarget.id
// 		//        + ' was dropped into '
// 		// if (leftDropZone) {

// 		var droppedArea = event.target;
// 		var grabbedZone = event.relatedTarget;

// 		// if (lastDropZone == "editor") {
// 		//   grabbedZone.parentNode.removeChild(grabbedZone);
// 		// }

// 		var x = grabbedZone.getAttribute('data-x');
// 		var y = grabbedZone.getAttribute('data-y');

// 		// if (lastDropZone == "sideBar") {
// 		// if (droppedArea.id == "editor") {
// 		//   console.log("in editor");
// 		//   console.log(window.innerWidth);
// 		//   if (window.innerWidth <= 900) {
// 		//     x = parseInt(x);
// 		//     y = parseInt(y) - 175;
// 		//   }
// 		//   else{
// 		//     x = parseInt(x) - 200;
// 		//     y = parseInt(y) + 25;
// 		//   }

// 		//   console.log(x);
// 		//   x = parseInt(x) + 25;

// 		//   console.log(x);
// 		//   grabbedZone.classList.add("draggableZone")
// 		//   grabbedZone.classList.add('clickableZone');
// 		//   grabbedZone.classList.add('resizableZone');
// 		//   grabbedZone.classList.remove('draggableStart');
// 		//   grabbedZone.id = "Zone" + getZones().length;
// 		//   addTitle(grabbedZone);
// 		//   // addBeacon(grabbedZone);
// 		//   addSensor(grabbedZone);
// 		//   console.log(getZones().length);

// 		//   grabbedZone.style.webkitTransform =
// 		//   grabbedZone.style.transform =
// 		//   'translate(' + x+ 'px, ' + y + 'px)'

// 		//   grabbedZone.setAttribute('data-x', x)
// 		//   grabbedZone.setAttribute('data-y', y)

// 		//   grabbedZone.style.height = "150px"
// 		//   grabbedZone.style.width = "150px"
// 		//   grabbedZone.style.backgroundColor = "#8EE4AF";

// 		//   // var toAdd = grabbedZone.cloneNode(true);
// 		//   // if (lastDropZone == "sideBar") {
// 		//   var dragZone = document.getElementById("dragZoneContainer");
// 		//   console.log("adding child");
// 		//   droppedArea.appendChild(grabbedZone);
// 		//   var clone = document.createElement("div");
// 		//   clone.style.webkitTransform =
// 		//   clone.style.transform =
// 		//   'translate(' + 0+ 'px, ' + 0 + 'px)'
// 		//   clone.setAttribute('data-x', 0)
// 		//   clone.setAttribute('data-y', 0)
// 		//   // clone.classList.add("draggableZone");
// 		//   clone.classList.add("draggableStart");
// 		//   console.log(dragZone);
// 		//   dragZone.appendChild(clone);
// 		// }
// 		// }
// 		// else {
// 		// console.log("not in editor");
// 		// var rect = getLastZone();

// 		// var x = rect.x;
// 		// var y = rect.y;

// 		// grabbedZone.style.webkitTransform =
// 		// grabbedZone.style.transform =
// 		// 'translate(' + 0+ 'px, ' + 0 + 'px)'
// 		// grabbedZone.setAttribute('data-x', 0)
// 		// grabbedZone.setAttribute('data-y', 0)

// 		// }
// 	},
// 	ondropdeactivate: function (event) {
// 		// remove active dropzone feedback
// 		// event.target.classList.remove('drop-active')
// 		// event.target.classList.remove('drop-target')
// 	},
// });



interact('.dropZone').dropzone({
	// only accept elements matching this CSS selector
	accept: '.draggable-container',
	// Require a 75% element overlap for a drop to be possible
	overlap: 0.9,

	// listen for drop related events:

	ondropactivate: function (event) {
		// add active dropzone feedback
		// console.log("target ID = " + event.target.id);
		// event.target.classList.add('drop-active');
		console.log('Picked Up');
		// console.log(lastDropZone);
		// event.target.classList.zIndex = "50"
		// setLastZone(event.target.getBoundingClientRect());
		// console.log(getLastZone());
		// lastDropzone = event.target.id;
	},
	ondragenter: function (event) {
		console.log('entered');
		// var draggableElement = event.relatedTarget
		// var dropzoneElement = event.target
		// // feedback the possibility of a drop
		// dropzoneElement.classList.add('drop-target')
		// draggableElement.classList.add('can-drop')
		// draggableElement.textContent = 'Dragged in'
	},
	ondragleave: function (event) {
		console.log('left');

		// remove the drop feedback style
		// event.target.classList.remove('drop-target')
		// event.relatedTarget.classList.remove('can-drop')
		// // event.relatedTarget.textContent = 'Dragged out'
		// lastDropZone =   event.target.id
		// console.log(lastDropZone);
	},
	ondrop: function (event) {
		console.log('dropped item');
		// event.relatedTarget.textContent = 'Dropped'
		// alert(event.relatedTarget.id
		//        + ' was dropped into '
		// if (leftDropZone) {

		var droppedArea = event.target;
		var grabbedZone = event.relatedTarget;

		// if (lastDropZone == "editor") {
		//   grabbedZone.parentNode.removeChild(grabbedZone);
		// }

		var x = grabbedZone.getAttribute('data-x');
		var y = grabbedZone.getAttribute('data-y');

		// if (lastDropZone == "sideBar") {
		// if (droppedArea.id == "editor") {
		//   console.log("in editor");
		//   console.log(window.innerWidth);
		//   if (window.innerWidth <= 900) {
		//     x = parseInt(x);
		//     y = parseInt(y) - 175;
		//   }
		//   else{
		//     x = parseInt(x) - 200;
		//     y = parseInt(y) + 25;
		//   }

		//   console.log(x);
		//   x = parseInt(x) + 25;

		//   console.log(x);
		//   grabbedZone.classList.add("draggableZone")
		//   grabbedZone.classList.add('clickableZone');
		//   grabbedZone.classList.add('resizableZone');
		//   grabbedZone.classList.remove('draggableStart');
		//   grabbedZone.id = "Zone" + getZones().length;
		//   addTitle(grabbedZone);
		//   // addBeacon(grabbedZone);
		//   addSensor(grabbedZone);
		//   console.log(getZones().length);

		//   grabbedZone.style.webkitTransform =
		//   grabbedZone.style.transform =
		//   'translate(' + x+ 'px, ' + y + 'px)'

		//   grabbedZone.setAttribute('data-x', x)
		//   grabbedZone.setAttribute('data-y', y)

		//   grabbedZone.style.height = "150px"
		//   grabbedZone.style.width = "150px"
		//   grabbedZone.style.backgroundColor = "#8EE4AF";

		//   // var toAdd = grabbedZone.cloneNode(true);
		//   // if (lastDropZone == "sideBar") {
		//   var dragZone = document.getElementById("dragZoneContainer");
		//   console.log("adding child");
		//   droppedArea.appendChild(grabbedZone);
		//   var clone = document.createElement("div");
		//   clone.style.webkitTransform =
		//   clone.style.transform =
		//   'translate(' + 0+ 'px, ' + 0 + 'px)'
		//   clone.setAttribute('data-x', 0)
		//   clone.setAttribute('data-y', 0)
		//   // clone.classList.add("draggableZone");
		//   clone.classList.add("draggableStart");
		//   console.log(dragZone);
		//   dragZone.appendChild(clone);
		// }
		// }
		// else {
		// console.log("not in editor");
		// var rect = getLastZone();

		// var x = rect.x;
		// var y = rect.y;

		// grabbedZone.style.webkitTransform =
		// grabbedZone.style.transform =
		// 'translate(' + 0+ 'px, ' + 0 + 'px)'
		// grabbedZone.setAttribute('data-x', 0)
		// grabbedZone.setAttribute('data-y', 0)

		// }
	},
	ondropdeactivate: function (event) {
		// remove active dropzone feedback
		// event.target.classList.remove('drop-active')
		// event.target.classList.remove('drop-target')
	},
});
