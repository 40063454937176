import React, { useRef, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DraggableImage from './DraggableImage';

export const ImageContainer = () => {

	const [selectedImage, setSelectedImage] = useState(-1);


	useEffect(() => {
		console.log(selectedImage);
	}, [selectedImage]);

	const testLink = 'https://images.unsplash.com/photo-1638300163974-8e9ba222a245?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80';

	var imagesArr = [

	]

	const populateImagesArr = () => {
		for (let i = 0; i < 15; i++) {
			imagesArr.push(testLink)

		}
	}


	var rowsToAdd = []
	const populateRows = () => {
		imagesArr.forEach((image, index) => {
			rowsToAdd.push(
				<div className='col-12'>
					{/* <DraggableImage key={index} id={'draggable-image-' + index} setSelectedImage={setSelectedImage} src={imagesArr[index]}></DraggableImage> */}

					<div className='add-image-button' style={{ backgroundImage: 'url(' + imagesArr[index] + ')', width:'100%' }}>
						<FontAwesomeIcon
							icon={faPlusCircle}
							className='mr-2 fa-3x add-button-center-circle'
							onClick={() => {
								console.log('MMM clicked');
							}}
							style={{ cursor: 'pointer' }}
						/>
					</div>

					{/* <img
						className='add-image-button'
						src={imagesArr[index]}
						alt=''
						onClick={() => {
							console.log('MMM clicked');
						}}
					/> */}
				</div>
			);
		})
	}

	populateImagesArr()
	populateRows()

	return (
		<>
			<label> Images </label>
			<div className='image-container'>

				{/* <img className='fill' src='https://images.unsplash.com/photo-1638300163974-8e9ba222a245?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80' alt='Italian Trulli'></img> */}

				<div className='images-box'>
					<div className='row'>{rowsToAdd}</div>
				</div>
			</div>
		</>
	);
};

export default ImageContainer;
