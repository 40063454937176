import React, { useCallback } from 'react';
import { Row, Modal, ModalBody } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { ModalHeader } from 'components/ModalHeader';

const PhotoModal = ({ toggleModal, isOpen, selectedImage, setSelectedImage, setFilesArray }) => {
  const toggle = useCallback(() => toggleModal(), [toggleModal]);
  const deallocate = () => {
    setFilesArray(obj => {
      if (obj[selectedImage.file.webkitRelativePath])
        obj[selectedImage.file.webkitRelativePath].group = null;
        obj[selectedImage.file.webkitRelativePath].siblings = null;
        obj[selectedImage.file.webkitRelativePath].staff = null;
        obj[selectedImage.file.webkitRelativePath].assigned = null;
        obj[selectedImage.file.webkitRelativePath].individual = null;
        return {...obj};
    });
    setSelectedImage(null);
    toggle();
  };
	return (
		<Modal isOpen={isOpen} toggle={toggle} style={{width: '400px', height: '400px'}}>
			<ModalHeader toggle={toggle} charCode='close'
        close={<FontAwesomeIcon icon={faTimes} className='' onClick={() => toggle()} role='button' />}>

          
        </ModalHeader>
        { selectedImage?.file && selectedImage?.file?.assigned &&
          <div className="text-3xl text-center tracking-wide mt-2">{selectedImage.file.assigned}</div>
        }
			<ModalBody>
				<Row className="justify-center">
        <div className="relative">
        <img className='rounded img-thumbnail m-2' style={{ width: '350px', height: '350px', objectFit: 'cover' }} src={selectedImage?.image} role='button' />
                    <FontAwesomeIcon
									icon={faTimesCircle}
									className='mr-2 fx-2x add-button-center-circle absolute top-4 right-5 cursor-pointer'
									onClick={deallocate}
                  />
                </div>
						
				</Row>
			</ModalBody>
		</Modal>
	);
};

export default PhotoModal;
