import React, { useEffect } from 'react';
import { FormGroup, Select, SubmitButton } from 'components/react-hook-form';
import { useForm, FormProvider } from 'react-hook-form';
import { Row, Col, Button } from 'reactstrap';
import { useClient } from 'hooks';
import { AssignOrderedList } from 'components/editors';


import { useQuery } from 'react-query';

import { Page } from 'components/common';

export const AssignPrintPacksToProofCards = () => {
	const client = useClient();

	const methods = useForm({
		defaultValues: {},
		mode: 'all',
	});

	const { setValue, handleSubmit, register, watch, getValues } = methods;

	const {
		data: printPacks,
	} = useQuery('get_print_packs', async () => client.get(`print_packs`).get('data'), {
		refetchOnWindowFocus: false,
	});

	const {
		data: proofCards,
	} = useQuery('get_proof_packs', async () => client.get(`proof_card_designs`).get('data'), {
		refetchOnWindowFocus: false,
	});


	const editingPack = watch('chosenProofPack')


	const { refetch: getIndividualPrintPack } = useQuery(
		`get_individual_paint_pack`,
		async () => {
			if (editingPack.id != -1) {
				client.get(`proof_card_designs/${editingPack.id}`).then((resp) => {
					// setEditingPack({ ...data.data });
					setValue('chosenProofPack', resp.data);
					// reorder the packs based off the pack ordering otherwise you cant reorder correctly
					if (resp.data.pack_ordering) {
						const list = resp.data.pack_ordering
						const keysSorted = Object.keys(list).sort(function(a,b){return list[a]-list[b]})
						setValue('chosenProofPack.packs', keysSorted.map(key => parseInt(key)))
					}
					return null;
				});
				return null;
			}
			return null;
		},
		{
			refetchOnWindowFocus: false,
		}
	);

	useEffect(() => {
		if (editingPack?.include_in_promo === undefined) {
			if (editingPack?.id !== -1) {
				getIndividualPrintPack();
			}
		}
	}, [editingPack]);

	const addPrintPack = () => {
		const newPack = getValues('just_for_from.add_pack_select')
		const packs = editingPack.packs
		packs.push(newPack.id);
		setValue('chosenProofPack.packs', packs)
		setValue('just_for_from.add_pack_select', null)
	};

	const onSavePressed = ({ ...all }) => {
		// make a copy to prevent overwriting original values
		const copy = {...all}
		const proofCardToSave = {
			packs: copy.chosenProofPack.packs,
		};

		const orderedPacks = {}
		copy.chosenProofPack.packs.forEach((pack, index) => {
			orderedPacks[pack] = index + 1;
		})

		proofCardToSave['ordering'] = orderedPacks
		const url = `/proof_card_designs/${editingPack.id}`;
		// return
		client
			.patch(url, proofCardToSave)
			.then((data) => {
				getIndividualPrintPack();
				return null;
			})
			.catch((e) => {
				console.error('ERROR: ', e);
				return null;
			});
	};

	return (
		<div className='main-content-container d-flex flex-row'>
			<Page>
			<div className='font-bold text-2xl tracking-wide mb-3'> Assign Print Packs To Proof Card </div>
				<hr />
				<FormProvider {...methods}>
						<FormGroup label="Proof Card Design">

							<Select
								options={proofCards}
								getOptionLabel={(data) => {
									return data['name'];
								}}
								getOptionValue={(data) => {
									return data['id'];
								}}
								{...register('chosenProofPack', {
									rules: {
										required: false,
									},
								})}
								onChange={(newValue) => {
									setValue('chosenProofPack', newValue);
								}}
								shouldUnregister={true}
								defaultValue={''}
							/>
						</FormGroup>
					<br />
					{!!editingPack && editingPack?.id != -1 && (
						<>
							<Row>
								<Col md={9}>
									<Select
										getOptionLabel={(data) => {
											return data['name'];
										}}
										getOptionValue={(data) => {
											return data['id'];
										}}
										name={'just_for_from.add_pack_select'}
										rules={{
											required: false,
										}}
										shouldUnregister={true}
										// only allow each pack to be added one
										options={printPacks.filter(pack => !getValues('chosenProofPack.packs').includes(pack.id))}

									/>
								</Col>
								<Col>
									<Button color='primary' className='w-100' onClick={addPrintPack} disabled={!watch('just_for_from.add_pack_select')}>
										Add print pack
									</Button>
								</Col>
							</Row>
							{editingPack.packs && editingPack.packs.length > 0 ? (
								<>
									<AssignOrderedList  className="w-100" printPacks={printPacks} editingPack={editingPack}  methods={methods}/>
								</>
							) : (
								<Row key={8} style={{ alignItems: 'center' }} className='justify-content-center mt-5'>
									No Print Packs, please add one
								</Row>
							)}
							<br />
						</>
					)}
					<hr />
					<form onSubmit={handleSubmit(onSavePressed)}>
						<Row key={4} style={{ alignItems: 'center' }} className='justify-content-end'>
							<Col xs='2' key={4}>
								<SubmitButton style={{ width: '100%', overflow: 'hidden' }}> Save</SubmitButton>
							</Col>
						</Row>
					</form>
				</FormProvider>
			</Page>
		</div>
	);
};

export default AssignPrintPacksToProofCards;
