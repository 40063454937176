import React from "react";
import { Progress } from 'reactstrap';

const ProgressBarRich = ({ value, label, striped = false, animated = true, max=null, ...props }) => {
  return (
    <Progress striped={striped} animated={animated} max={max} value={value} {...props} >
      {label}
    </Progress>
  );
}

export default ProgressBarRich;
