import { useRecoilCallback, useRecoilValue } from "recoil";
import {
  tokenAtom,
} from "atoms";
import { userSelector } from "selectors";
import { createClient } from "../hooks/useClient";

export const useUserContext = () => {
  const user = useRecoilValue(userSelector);
  // console.log({user});
  const clear = useRecoilCallback(({ reset }) => () => {
    reset(tokenAtom);
  });

  const logout = clear;

  async function getToken(email, password) {
    return createClient()
      .post("auth", {
        email,
        password,
      })
      .get("data")
      .get("token");
  }

  const login = useRecoilCallback(
    ({ snapshot, gotoSnapshot }) => async (username, password) => {
      const token = await getToken(username, password);
      console.log({token});

      snapshot = snapshot.map(({ set }) => {
        set(tokenAtom, token);
        return null;
      });
      console.log({snapshot});
      gotoSnapshot(snapshot);
    }
  );

  return {
    user,
    login,
    logout,
    getToken,
    clear,
  };
};

export default useUserContext;
