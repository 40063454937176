import { useState } from "react";
import { useQuery } from "react-query";
import { useClient } from "../../../../hooks"
import { uniqueId } from "lodash";
// import { props } from "bluebird";

export const useListQuery = ({
  url,
  setData,
  table,
  refresh_token = null,
  query_params = {},
  query_arguments = {},
  onSuccess = (data)=>{}
}) => {
  const { state, setTotal } = table;
  const { limit, offset, ordering, search } = state;
  const client = useClient();
  const [id] = useState(() => uniqueId("useListQuery-"));
  
  return useQuery(
    [
      id,
      {
        limit,
        offset,
        ordering,
        search,
        refresh_token,
        ...Object.keys(query_params),
      },
    ],
    async () => {
      const data = await client
        .get(url, {
          params: {
            offset,
            limit,
            search,
            ordering,
            ...query_params,
          },
        })
        .get("data");
      return {
        total: data.count,
        data: data.results,
      };
    },
    {
      ...query_arguments,
      onSuccess: ({ total, data }) => {
        setTotal(total);
        setData(data);
        onSuccess(data)
      },
    }
  );
};
