import React from 'react'
import { SketchPicker } from 'react-color'
import reactCSS from 'reactcss'


class ColorPicker extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showPicker: false,
			colour: this.props.defaultColour != null ? this.props.isHex ? this.hexToRgb(this.props.defaultColour): this.props.defaultColour : {
				r: '255',
				g: '255',
				b: '255',
				a: '1',
			},
		};
		// Set initial value to white
		this.props.setValue(this.props.hookFormTitle, !this.props.isHex ? [255, 255, 255] : "#ffffff");

		if(this.props.defaultColour){
			this.props.setValue(this.props.hookFormTitle, this.props.defaultColour);
		}
	}

	onClick = () => {
		this.setState({
			showPicker: !this.state.showPicker,
		});
	};

	onClose = () => {
		this.setState({
			showPicker: false,
		});
	};

	hexToRgb = (hex) => {
		var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result ? {
		  r: parseInt(result[1], 16),
		  g: parseInt(result[2], 16),
		  b: parseInt(result[3], 16),
		  a:1
		} : null;
	}

	onChange = (colour) => {
		this.setState({
			colour: colour.rgb,
		});
		// Format and store the value in the react hook from
		if (this.props.isHex) {
			const hex = colour.hex;
			this.props.setValue(this.props.hookFormTitle, hex);
		} else {
			const rgb = colour.rgb;
			const colourArray = [rgb.r, rgb.g, rgb.b];
			this.props.setValue(this.props.hookFormTitle, colourArray);
		}
	};

	render() {
		const styles = reactCSS({
			default: {
				colour: {
					width: '40px',
					height: '15px',
					borderRadius: '3px',
					background: `rgba(${this.state.colour.r}, ${this.state.colour.g}, ${this.state.colour.b}, ${this.state.colour.a})`,
				},
				popover: {
					position: 'fixed',
					zIndex: '3',
				},
				cover: {
					position: 'fixed',
					top: '0px',
					right: '0px',
					bottom: '0px',
					left: '0px',
				},
				swatch: {
					padding: '6px',
					background: '#ffffff',
					borderRadius: '4px',
					cursor: 'pointer',
					display: 'inline-block',
					boxShadow: '0 0 0 1px rgba(0,0,0,.2) inset',
				},
			},
		});


		return (
			<div className='w-100 h-100'>
				<div className='w-100 h-100' style={styles.swatch} onClick={this.onClick}>
					<div className='w-100 h-100' style={styles.colour} />
				</div>
				{this.state.showPicker ? (
					<div style={styles.popover}>
						<div style={styles.cover} onClick={this.onClose} />
						<SketchPicker color={this.state.colour} onChange={this.onChange} />
					</div>
				) : null}
			</div>
		);
	}
}

export default ColorPicker