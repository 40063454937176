import React from "react";
import { Button } from "reactstrap";
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export const PendingButton = ({
  pending,
  disabled,
  component: Component = Button,
  children,
  icon,
  translate = true,
  ...props
}) => (
  <Component disabled={pending || disabled} {...props}>
    {!!pending && <FontAwesomeIcon className="mr-2" icon={faSpinner} spin />}
    {!!icon && <span className={icon} />}
    {translate ? <Trans>{children}</Trans> : children}
  </Component>
);

export default PendingButton;
