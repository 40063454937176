import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormGroup, CustomButton, Input, SubmitButton } from 'components/react-hook-form';
import { useForm, FormProvider } from 'react-hook-form';
import { Row, Col, Button} from 'reactstrap';
import { useUserContext, useClient } from 'hooks';
import { useQuery } from 'react-query';
import { DraggableGraphic, GraphicTypes } from 'components/editors';
import { toBase64, getScale } from 'components/editors/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faTimesCircle, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { Page, Search } from 'components/common';
import EditorArea from 'components/editors/GenComposites/EditorArea';
import { NotificationManager } from 'react-notifications';


const PAGE_SIZE = [2480, 1753];

const dataItems = ['name', 'class', 'school', 'admin_no', 'proof_code', 'barcode', 'deadline_date'];

export const CreateNewProofCard = () => {
	const navigate = useNavigate();
	const client = useClient();
	const { user } = useUserContext();

	const createDefaultBox = (itemText, is_text = false) => {
		return {
			text: itemText,
			width: 0.2,
			height: 0.1,
			centre: { x: 0.5, y: 0.5 },
			visible: false,
			is_text: is_text,
			ratio_x: 1,
			fixed_ratio: false,
		};
	};

	const createDefaultBoxes = () => {
		const toReturn = {};

		dataItems.forEach((item) => {
			toReturn[item] = createDefaultBox(item);
		});

		for (let i = 1; i < 13; i++) {
			toReturn[`pose_${i}`] = createDefaultBox(`pose_${i}`,false);
			toReturn[`pose_${i}_barcode`] = createDefaultBox(`pose_${i}_barcode`,true);
			toReturn[`pose_${i}_label`] = createDefaultBox(`pose_${i}_label`,true);
		}

		return toReturn;
	};

	const methods = useForm({
		defaultValues: {
			design: {
				boxes: { ...createDefaultBoxes() },
				poses: '0',
				text_height: 0,
				data_name: '0',
				data_class: '0',
				data_school: '0',
				data_admin_no: '0',
				data_proof_code: '0',
				data_deadline_date: '0',
			},
		},
		mode: 'all',
	});

	const [scaleValue, setScaleValue] = useState(100);

	useEffect(() => {
		var stevenScale = getScale(parseFloat(PAGE_SIZE[0]), parseFloat(PAGE_SIZE[1]));
		const toMultiply = stevenScale * parseFloat(PAGE_SIZE[0]) < 500;
		if (toMultiply) {
			stevenScale = stevenScale * 5;
		}
		setScaleValue(stevenScale);
	}, []);

	const { watch, setValue, formState, reset, handleSubmit, unregister, register, getValues } = methods;

	const resetBoxes = () => {
		setValue('design.boxes', createDefaultBoxes());
	};

	const [proofCards, setProofCards] = useState([]);
	const [search, setSearch] = useState("");
	const [previewTemplate, setPreviewTemplate] = useState(null);
	const [previewWatermark, setPreviewWatermark] = useState(null);

	// Edit Modal State
	const [editModal, setEditModal] = useState(false);
	const toggleEditModal = () => setEditModal(!editModal);

	const [showLayers, setShowLayers] = useState(false);
	const toggleLayers = () => setShowLayers(!showLayers);

	const cards = useMemo(() => {
		if (!proofCards) return [];
		return proofCards.filter(e => e.name.toLowerCase().includes(search.toLowerCase()))
	}, [proofCards, search]);

	const [selectedItem, setSelectedItem] = useState({ id: -1, type: 'None', element: null });

	// -------------------------------------------------------------
	// API Stuff
	// -------------------------------------------------------------

	const { refetch: getProofPacks } = useQuery(
		'get_paint_packs',
		async () => {
			client.get(`proof_card_designs`).then((data) => {
				// console.log('response data:', data);

				setProofCards(data.data);
				return null;
			});
			return null;
		},
		{
			refetchOnWindowFocus: false,
		}
	);

	const getIndividualProofCard = (cardID) => {
		client.get(`proof_card_designs/${cardID}`).then((data) => {
			// console.log('response data:', data);
			// setEditingCard({ ...data.data });

			const cardData = data.data;

			// If this is an older proof card design without a deadline date defined,
			// then add it now.
			
			if (cardData.design.boxes.deadline_date == undefined) {
				cardData.design.boxes.deadline_date = createDefaultBox('deadline_date');
			}

			var stevenScale = getScale(parseFloat(PAGE_SIZE[0]), parseFloat(PAGE_SIZE[1]));
			const toMultiply = stevenScale * parseFloat(PAGE_SIZE[0]) < 500;
			if (toMultiply) {
				stevenScale = stevenScale * 5;
			}

			setPreviewWatermark(cardData.watermark ?? null);
			setPreviewTemplate(cardData.template ?? null);

			cardData.design.text_height = cardData.design.text_height * 1000;

			setScaleValue(stevenScale);
			reset(cardData);
			// editingPackRef.current = editingPack;
			toggleEditModal();

			var delayInMilliseconds = 1000; //1 second

			setTimeout(function () {
				addEventListeners();
			}, delayInMilliseconds);

			// if (getValues('design.boxes.deadline_date') == undefined) {
			// 	setValue('design.boxes.deadline_date', createDefaultBox('deadline_date'));
			// }

			return null;

			// setPrintPacks(data.data);
		});
	};

	const {
		data: printPanelsList,
		error: printPanelsError,
		refetch: getPrintPanelsList,
	} = useQuery('get_paint_panels_list', async () => client.get(`print_panels`).get('data'), {
		refetchOnWindowFocus: false,
	});

	function deletePrintPack(id) {
		// console.log(id)
		client
			.delete(`/proof_card_designs/${id}`)
			.then((data) => {
				getProofPacks();
				return null;
			})
			.catch((e) => {
				console.error('ERROR: ', e.response.data);
				return null;
			});
	}


	const onSavePressed = ({ ...all }) => {
		console.log('Saving Now', all);

		// const cardToSave = all[editingCard.id]
		// packToSave.id = editingPack.id;

		const cardToSave = getValues();

		console.log('cardToSave', cardToSave);
		console.log('MY ID: ', cardToSave.id);

		cardToSave.design.text_height = cardToSave.design.text_height / 1000;


		dataItems.forEach((item) => {
			// const itemText = item.replace('_', ' ').toUpperCase();
			// const value = getValues('design.boxes.' + item);
			// const dataValue = getValues('design.data_' + item);
			console.log(item)
			cardToSave.design['data_' + item] = cardToSave.design.boxes[item].visible ? '1' : '0';

		})

		if (cardToSave.id) {
			const url = `/proof_card_designs/${cardToSave.id}`;

			client
				.put(url, cardToSave)
				.then((data) => {
					getProofPacks();
					resetForm();
					toggleEditModal();
					NotificationManager.success('Proof card saved successfully');
					return null;
				})
				.catch((e) => {
					console.error('ERROR: ', e.response.data);
					return null;
				});
		} else {
			client
				.post('/proof_card_designs', cardToSave)
				.then((data) => {
					getProofPacks();
					resetForm();
					toggleEditModal();
					NotificationManager.success('Proof card created successfully');

					return null;
				})
				.catch((e) => {
					console.error('ERROR: ', e.response.data);
					return null;
				});
		}
	};

	// -------------------------------------------------------------
	// End Of API Stuff
	// -------------------------------------------------------------

	const createPrintPackFormItem = (element) => {
		return (
			<FormGroup key={element.id}>
				<Row key={element.id} style={{ alignItems: 'center' }} className='justify-content-center'>
					<Col xs='4' key={1}>
						<h5
							style={{
								float: 'right',
								margin: '0px',
								width: '100%',
								textAlign: 'end',
								textOverflow: 'ellipsis',
								overflow: 'hidden',
								wordWrap: 'break-word',
								display: 'block',
								lineHeight: '1em',
								maxHeight: '2em',
							}}
						>
							{' '}
							{element.name}{' '}
						</h5>
					</Col>
					<Col xs='2' key={2}>
						<CustomButton
							style={{ width: '100%' }}
							buttonColor='primary'
							onClick={() => {
								// handleEditModal(element);
								getIndividualProofCard(element.id);
							}}
						>
							Edit
						</CustomButton>
					</Col>
					<Col xs='2' key={3}>
						<CustomButton
							style={{ width: '100%' }}
							buttonColor='danger'
							onClick={() => {
								deletePrintPack(element.id);
							}}
						>
							Delete
						</CustomButton>
					</Col>
					<Col xs={2}></Col>
				</Row>
			</FormGroup>
		);
	};

	const displayPrintPackList = () => {
		const packsToShow = [];
		proofCards.forEach((element) => {
			packsToShow.push(createPrintPackFormItem(element));
		});

		if (packsToShow.length > 0) {
			return packsToShow;
		}

		return <> No Proof Cards </>;
	};



	const addEventListeners = () => {
		const elements1 = Array.prototype.slice.call(document.getElementsByClassName('only-draggable-container'));
		const elements2 = Array.prototype.slice.call(document.getElementsByClassName('draggable-container-ratio-lock'));
		const elements3 = Array.prototype.slice.call(document.getElementsByClassName('draggable-container'));

		const elements = elements1.concat(elements2,elements3)

		console.log(elements);
		// var arr = Array.prototype.slice.call( htmlCollection )
		if (elements) {
			elements.forEach((element) => {
				// console.log("Element: ", element)
				console.log('Adding drag listener');
				if (!element.classList.contains('finishedDraggingHandler')) {
					element.classList.add('finishedDraggingHandler');
					element.addEventListener('finishedDragging', finishedDraggingHandler, false);
				}
				if (!element.classList.contains('finishedResizingHandler')) {
					element.classList.add('finishedResizingHandler');
					element.addEventListener('finishedResizing', finishedResizingHandler, false);
				}
			});
		}
	};

	const createDraggablePoses = () => {
		const toReturn = [];

		const boxes = watch('design.boxes')
		const text_height =  watch('design.text_height')

		if (boxes) {
			for (const [key, value] of Object.entries(boxes)) {
				var resizable = key.includes('pose') && !key.includes('label')
				var aspectLock = key.includes('pose') && !key.includes('barcode')
				if (!key.includes('pose')) {
					value['text_size'] = text_height
				}
				toReturn.push(
					<DraggableGraphic
						key={key}
						startDark={true}
						aspectLock = {aspectLock}
						draggableOnly={!resizable}
						type={key.includes('pose') ? GraphicTypes.PROOF_CARD_POSE : GraphicTypes.PROOF_CARD_GRAPHIC}
						graphicPrefix={'pose_'}
						setSelectedItem={setSelectedItem}
						scaleValue={scaleValue}
						methods={methods}
						id={key}
						values={watch('design.boxes.' + key)}
					/>
				);
			}
		}
		return toReturn.length > 0 ? toReturn : <>Noting TO return</>;
	};

	const finishedResizingHandler = (e) => {
		// var graphicId = selectedItem.type + '_' + selectedItem.id;
		console.log('Caught Resize Event!!');
		// console.log(e.target);
		// console.log(graphicId)

		const element = e.target;

		const graphicId = 'design.boxes.' + element.id;
		// const rect = element.getBoundingClientRect();

		const widthResult = element.clientWidth / parseFloat(PAGE_SIZE[0]);
		const heightResult = element.clientHeight / parseFloat(PAGE_SIZE[1]);

		// const heightRounded = Math.round(heightResult * 100) / 100;
		// const widthRounded = Math.round(widthResult * 100) / 100;

		setValue(graphicId + '.height', heightResult);
		setValue(graphicId + '.width', widthResult);

		finishedDraggingHandler(e, element);
		// } else {

		// 	setValue(graphicId + '.height', element.clientHeight);
		// 	setValue(graphicId + '.width', element.clientWidth);
		// }

		// selectedItem.element = element;
		// setSelectedItem({ ...selectedItem });
	};


	const finishedDraggingHandler = (e, element = null) => {
		console.log("FINISHED DRAGGING");
		if (!element) {
			element = e.target;
		}
		const graphicId = 'design.boxes.' + element.id;
		console.log(graphicId)

		// console.log('Caught Drag Event!!', element);
		// const x = element.attributes["data-x"].value
		// const y = element.attributes["data-y"].value

		const xResult = element.getAttribute('data-x') / parseFloat(PAGE_SIZE[0]);
		const yResult = element.getAttribute('data-y') / parseFloat(PAGE_SIZE[1]);

		const widthResult = element.clientWidth /  parseFloat(PAGE_SIZE[0]) / 2;
		const heightResult = element.clientHeight / parseFloat(PAGE_SIZE[1]) / 2;

		// console.log('xResult: ' + xResult   + ', yResult: ' + yResult);

		// console.log(xResult, yResult);

		// console.log(xResult / 100, yResult / 100);

		setValue(graphicId + '.centre.x', (xResult + widthResult));
		setValue(graphicId + '.centre.y', (yResult + heightResult));

		// console.log(element.id + " 1: " + getValues('design.boxes.'+element.id+'.centre'))
	};



	const createDataListItem = (labelText, formID) => {
		const value = watch('design.' + formID);
		console.log('value: ', value);

		return (
			<Row className={`border-b-1 pb-1 pt-1`}>
				<Col md={9}>
					<h5>{labelText}</h5>
				</Col>

				<Col>
					<Button
						color=''
						onClick={() => {
							setValue('design.' + formID, value == '1' ? '0' : '1');
						}}
					>
						<FontAwesomeIcon className='mr-2' icon={value == '1' ? faEye : faEyeSlash} />
					</Button>
				</Col>
			</Row>
		);
	};

	const capitalizeFirstLetterEachWord=(string)=> {
		return string.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
	}

	const displayPosesList = () => {
		const toReturn = [
			// createDataListItem("Name", "data_name"),
			// createDataListItem("Class", "data_class")
		];

		const boxes = watch('design.boxes');

		if (!boxes) {
			return (
				<>
					<Row className={`border-b-1 pb-1 pt-1`}>
						<Col>No poses are defined in this save file would you like to generate default poses?</Col>
					</Row>
					<Row className={`border-b-1 pb-1 pt-1`}>
						<Col>
							<Button
								onClick={() => {
									resetBoxes();
								}}
							>
								Reset Poses
							</Button>
						</Col>
					</Row>
				</>
			);
		}

		// const dataItemsText = ['Name', 'Class', 'School', 'Admin No.', 'Proof Code', 'Barcode', 'Deadline Date']

		dataItems.forEach((item) => {
			const itemText = item.replace('_', ' ').toUpperCase();
			const value = getValues('design.boxes.' + item);
			const dataValue = getValues('design.data_' + item);

			// console.log(value);

			toReturn.push(
				<Row className={`border-b-2 pb-1 pt-1 d-flex align-items-center justify-content-between mt-2 ml-0 mr-0`}>
					{/* <Col md={9}> */}
						<h5>{itemText == "PROOF CODE" ? 'Proof Card ID' : capitalizeFirstLetterEachWord(itemText.toLowerCase())}</h5>
					{/* </Col>

					<Col> */}
						<Button
							color=''
							onClick={() => {
								setValue('design.boxes.' + item + '.visible', !value.visible);
								// console.log('item: ', item);
								// console.log('dataValue: ', dataValue);

								setValue('design.data_' + item, value.visible ? '1' : '0');

								// console.log("result = ", value.visible ? '1' : '0')
							}}
						>
							<FontAwesomeIcon className='mr-2' icon={value.visible ? faEye : faEyeSlash} />
						</Button>
					{/* </Col> */}
				</Row>
			);
		});

		for (let i = 1; i < 13; i++) {
			const pose = boxes[`pose_${i}`];
			const poseBarcode = boxes[`pose_${i}_barcode`];
			const poseLabel = boxes[`pose_${i}_label`];
			const poseNum = parseInt(watch('design.poses'));
			// console.log('poses num: ', poseNum);

			const listItem = (
				<>
					<Row className={`border-b${!pose.visible ? '-2' : '-1'} pb-1 pt-1 d-flex align-items-center justify-content-between mt-2 ml-0 mr-0`}>
						{/* <Col md={9}> */}
							<h5>Pose {i}</h5>
						{/* </Col>

						<Col> */}
							<Button
								color=''
								onClick={() => {
									setValue('design.boxes.pose_' + i + '.visible', !pose.visible);
									setValue('design.poses', pose.visible ? (poseNum + 1).toString() : (poseNum - 1).toString());
									if(!pose.visible) {
										setValue('design.boxes.pose_' + i + '_barcode.visible', false);
										setValue('design.boxes.pose_' + i + '_label.visible', false);
									}
								}}
							>
								<FontAwesomeIcon className='mr-2' icon={pose.visible ? faEye : faEyeSlash} />
							</Button>
						{/* </Col> */}
					</Row>
					{!!pose.visible && (
						<>
							<Row className='pl-4 pb-1 pt-1 d-flex align-items-center justify-content-between mt-2 ml-0 mr-0'>
								{/* <Col md={8}> */}
									<h6>Pose {i} Barcode</h6>
								{/* </Col> */}

								{/* <Col> */}
									<Button
										color=''
										onClick={() => {
											setValue('design.boxes.pose_' + i + '_barcode.visible', !poseBarcode.visible);
										}}
									>
										<FontAwesomeIcon className='mr-2' icon={poseBarcode.visible ? faEye : faEyeSlash} />
									</Button>
								{/* </Col> */}
							</Row>
							<Row className='pl-4 border-b-2 pb-1 pt-1 d-flex align-items-center justify-content-between mt-2 ml-0 mr-0'>
								{/* <Col md={8}> */}
									<h6>Pose {i} Label</h6>
								{/* </Col> */}

								{/* <Col> */}
									<Button
										color=''
										onClick={() => {
											setValue('design.boxes.pose_' + i + '_label.visible', !poseLabel.visible);
										}}
									>
										<FontAwesomeIcon className='mr-2' icon={poseLabel.visible ? faEye : faEyeSlash} />
									</Button>
								{/* </Col> */}
							</Row>
						</>
					)}
				</>
			);
			toReturn.push(listItem);
		}
		return toReturn;
	};



	const createEditModalForm = () => {
		return (
			<FormProvider {...methods}>
				<Row className='' style={{ overflow: 'hidden', height: 'calc(100vh - 57px)' }}>
					<Col md={4} className='pl-8 pt-3  ' style={{ maxWidth: "400px", overflow: 'scroll', height: 'calc(100%)' }}>
						<FormGroup key={1} label='Card Name'>
							<Input
								type='text'
								className='form-control'
								name={'name'}
								placeholder='Enter Name'
								rules={{
									required: false,
								}}
								defaultValue={getValues('name') ?? ''}
							/>
						</FormGroup>
						{/* TODO fix this element */}
						<FormGroup key={14} label='Template'>
							<input
								type='file'
								className='form-control'
								name={'just_for_form.template'}
								// placeholder='enter name'
								rules={{
									required: false,
								}}
								{...register('just_for_form.template', {
									required: false,
								})}
								onChange={async (e) => {
									const file = e.target.files[0];
									console.log(file);
									await toBase64(file).then((string) => {
										console.log(string);
										setPreviewTemplate(string);

										setValue('template', string);
									});
								}}
								// defaultValue={editingCard.graphic ?? ''}
							/>
						</FormGroup>
						{/* {!!previewTemplate && <img className='rounded mb-2' src={`data:image/jpeg;base64, ${previewTemplate}`} />} */}
						{previewTemplate != '' && previewTemplate != null && (
							<div style={{ position: 'relative' }}>
								<img src={`data:image/jpeg;base64, ${previewTemplate}`} className='rounded mb-2' style={{ width: '100%' }} />

								<FontAwesomeIcon
									icon={faTimesCircle}
									className='mr-2 fa-3x add-button-center-circle'
									onClick={() => {
										setPreviewTemplate('');
										setValue('template', 'delete');
										// e.target.value = '';
									}}
									style={{ position: 'absolute', top: '4px', right: '5px', cursor: 'pointer' }}
								/>
							</div>
						)}

						<FormGroup key={14} label='Watermark'>
							<input
								type='file'
								className='form-control'
								name={'just_for_form.watermark'}
								// placeholder='enter name'
								rules={{
									required: false,
								}}
								{...register('just_for_form.watermark', {
									required: false,
								})}
								onChange={async (e) => {
									const file = e.target.files[0];
									console.log(file);
									await toBase64(file).then((string) => {
										console.log(string);
										setPreviewWatermark(string);

										setValue('template', 'delete');
									});
								}}
								// defaultValue={editingCard.graphic ?? ''}
							/>
						</FormGroup>
						{/* {!!previewWatermark && <img className='rounded mb-2' src={`data:image/jpeg;base64, ${previewWatermark}`} />} */}
						{previewWatermark != '' && previewWatermark != null && (
							<div style={{ position: 'relative' }}>
								<img src={`data:image/jpeg;base64, ${previewWatermark}`} className='rounded mb-2' style={{ width: '100%' }} />

								<FontAwesomeIcon
									icon={faTimesCircle}
									className='mr-2 fa-3x add-button-center-circle'
									onClick={() => {
										// console.log('MMM clicked');
										setPreviewWatermark('');
										setValue('watermark', 'delete');
										// e.target.value = '';
									}}
									style={{ position: 'absolute', top: '4px', right: '5px', cursor: 'pointer' }}
								/>
							</div>
						)}


						<FormGroup key={9} label='Text Size'>
							<input
								type='number'
								className='form-control'
								{...register('design.text_height', {
									required: false,
									valueAsNumber: true,
								})}
								min={0}
								max={100}
								// defaultValue={(editingPanel.layout && editingPanel.layout.boxes && Object.keys(editingPanel.layout.boxes).length) ?? 0}
								defaultValue={0}
								// onChange={handlePhotoNumberChange}
							/>
						</FormGroup>
						<hr />
						{selectedItem.id > -1 && getPoseContextMenu()}
						<Row onClick={toggleLayers} className="d-flex align-items-center justify-content-between ml-0 mr-0">
							{/* <Col> */}
								<h4 className="mr-2">Poses</h4>
								<FontAwesomeIcon size='2x' icon={!showLayers ? faAngleDown : faAngleUp} />
							{/* </Col> */}
						</Row>
						{!!showLayers && (
							<div className='' style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
								{displayPosesList()}
							</div>)
						}

						<form onSubmit={handleSubmit(onSavePressed)} >
							<Row className='d-flex align-items-center justify-content-between mt-2 ml-0 mr-0'>

									<Button
										onClick={() => {
											resetForm();

											setEditModal(false);
											// handleEditModal({ id: -1 });
										}}
									>
										Cancel
									</Button>

									<SubmitButton>Save</SubmitButton>

							</Row>
						</form>

						{/* {console.log("hello yes this is the selected item,", selectedItem)} */}
					</Col>
					<Col  className='p-0 border-l'>
						<EditorArea methods={methods} backgroundImage={previewTemplate} editorStyle={{ height: 'calc(100vh - 76px)' }} defaultWidth={PAGE_SIZE[0]} defaultHeight={PAGE_SIZE[1]} pageBackgroundColor={'gray'}>
							{createDraggablePoses()}

						</EditorArea>

					</Col>

				</Row>


			</FormProvider>
		);
	};

	const resetForm = () => {
		reset({
			design: {
				boxes: { ...createDefaultBoxes() },
			},
		});
		setPreviewTemplate(null);
		setPreviewWatermark(null);
	};

	const getPoseContextMenu = () => {
		const value = selectedItem.value;
		const chosenItemId = 'design.boxes.' + value.text;
		console.log(value.text);
		const pose = getValues(chosenItemId)

		if (value.text.includes('label') || value.text.includes('barcode')) {
			return <></>
		}

		return (
			<div className='border-top pt-2' id={selectedItem.id} key={selectedItem.id}>
				<FormProvider {...methods}>
					<h3> {value.text ?? ''} </h3>
					<FormGroup key={8} label='Photo Ratio'>
						<Row>
							<Col>
								<input
									type='number'
									className='form-control'
									{...register(chosenItemId + '.ratio_x', {
										required: false,
										valueAsNumber: true,
									})}
									min={0}
									defaultValue={0}
									onChange={(e)=>{
										const value = e.target.value;
										// Calculate result
										const result = getValues('just_for_from.' + chosenItemId + '.fixed_ratio') / value

										// save value
										setValue(chosenItemId + '.fixed_ratio', result )
										setValue(chosenItemId + '.ratio_x', value)

									}}
								/>
							</Col>
							<Col>
								<input
									type='number'
									className='form-control'
									{...register('just_for_from.' + chosenItemId + '.fixed_ratio', {
										required: false,
										valueAsNumber: true,
									})}
									min={0}
									defaultValue={getValues(chosenItemId + '.fixed_ratio') * getValues(chosenItemId + '.ratio_x')}
									onChange={(e)=>{
										const value = e.target.value;
										// Calculate result
										const result = value / getValues(chosenItemId + '.ratio_x')
										// save value
										setValue(chosenItemId + '.fixed_ratio', result )
										setValue('just_for_from.' + chosenItemId + '.fixed_ratio', value )

									}}
								/>
							</Col>
						</Row>
					</FormGroup>
				</FormProvider>
			</div>
		);
	};

	return (
		<>
			{' '}
			{!editModal ? (
				<div className='main-content-container d-flex flex-row'>
					<Page>
					<div className='font-bold text-2xl tracking-wide mb-3'> Create And Edit Proof Cards </div>
						<hr />
						<FormProvider {...methods}>
              <Row form className="py-3">
                <Col>
                  <Search
                    search={search}
                    setSearch={setSearch}
                    placeholder="Search for proof cards..."
                  />
                </Col>
                <Col xs="3">
                  <FormGroup>
                    <CustomButton className="w-100" color="darker" onClick={() => toggleEditModal()}>
                      Create proof card
                    </CustomButton>
                  </FormGroup>
                </Col>
              </Row>
              {cards.map((element) => {
                return (
                  <FormGroup key={element.id}>
                    <Row key={element.id}>
                      <Col xs='8' key={1}>
                        <div className="w-100 text-lg tracking-wide truncate block">
                          {element.name}
                        </div>
                        {/* <h5
													style={{
														float: 'right',
														margin: '0px',
														width: '100%',
														textAlign: 'end',
														textOverflow: 'ellipsis',
														overflow: 'hidden',
														wordWrap: 'break-word',
														display: 'block',
														lineHeight: '1em',
														maxHeight: '2em',
													}}
												>
													{' '}
													{element.name}{' '}
												</h5> */}
                      </Col>
                      <Col xs='2' key={2}>
                        <CustomButton
                          className="w-100"
                          color='darker'
                          onClick={() => getIndividualProofCard(element.id)}
                        >
                          Edit
                        </CustomButton>
                      </Col>
                      <Col xs='2' key={3}>
                        <CustomButton
                          className="w-100"
                          buttonColor='danger'
                          onClick={() => deletePrintPack(element.id)}
                        >
                          Delete
                        </CustomButton>
                      </Col>
                      {/* <Col xs={2}></Col> */}
                    </Row>
                  </FormGroup>
                )
              })}
							{/* {displayPrintPackList()} */}
						</FormProvider>
					</Page>
				</div>
			) : (
				createEditModalForm()
			)}
		</>
	);
};

export default CreateNewProofCard;
