import { useFormState } from "react-hook-form";
import React from "react";
import { PendingButton } from "components/common";

export const CustomButton = ({ pending, control, children, buttonColor = "primary", ...props }) => {
  const { isSubmitting } = useFormState({
    control,
  });

  return (
		<PendingButton
      type='submit'
      pending={!!(pending || isSubmitting)}
      color={buttonColor}
      translate={false}
      {...props}
    >
			{children}
		</PendingButton>
  );
};
