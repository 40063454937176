import React, {useState, useEffect,forwardRef, useRef, useImperativeHandle } from "react";
import { Button, Col, Row } from "reactstrap";
import { Trans } from "react-i18next";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";

import { useClient } from 'hooks';

// import { useTableContext } from "../hooks";

const defaultShowOptions = [10, 25, 50, 100, 200];

const PaginationButton = ({ hidden, icon, children, className, ...props }) => {
  return (
    <Button
      size="sm"
      color="link"
      className={classNames(
        hidden ? "invisible" : "",
        "btn-link--inline",
        className
      )}
      {...props}
    >
      {icon ? <FontAwesomeIcon icon={icon} size="lg" /> : null}
      {children}
    </Button>
  );
};

export const GalleryPagination = forwardRef(({ showOptions = defaultShowOptions, setGalleryFetching, setData, data, selectedJob}, ref) => {

    const client = useClient();
    const totalImages = data?.state?.total

    const [limit, setLimit] = useState(10)
    const [offset, setOffset] = useState(0)

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({

      refetchData(){
        requestGalleryData(offset, limit)
      }

    }));

    const [canNextPage, setCanNextPage] = useState(false)
    const [canPreviousPage, setCanPreviousPage] = useState(offset + limit < totalImages)


    useEffect(() => {
        console.log("its changing")
        setCanNextPage( offset + limit < totalImages)
        setCanPreviousPage( offset > 0)
    }, [totalImages, offset, limit])

    async function requestGalleryData(offset, limit) {
        setData([])
        setGalleryFetching(true)
        setOffset(offset);
        setLimit(limit);
        const respData = await client.get( `photographer_file_upload/gallery/${selectedJob?.id}?offset=${offset}&limit=${limit}`).get('data');
        setData(respData.results)
        setGalleryFetching(false)
    }

    // request wrapper functions
    function previousPage() {
        requestGalleryData(offset - limit, limit)
    }

    function nextPage() {
        requestGalleryData(offset + limit, limit)
    }

    function firstPage(){
        requestGalleryData(0, limit)
    }

    function lastPage(){
        requestGalleryData(totalImages - limit, limit)
    }

    function setPageSize(pageSize) {
        requestGalleryData(offset, pageSize)
    }

    function refetchData(){
        requestGalleryData(offset, limit)
    }


  return (
    <div>
      <Row className="text-sm space-y-2 sm:space-y-0">
        <Col sm="auto">
          <div>
            <span className="text-hc-darker font-medium">
              <Trans>Total results:</Trans>{" "}
            </span>
            <span className="text-gray-500">{totalImages}</span>
          </div>
        </Col>
        <Col sm="auto">
          <div>
            <span className="text-hc-darker font-medium">
              <Trans>Show:</Trans>
            </span>{" "}
            {showOptions.map((i) => (
              <PaginationButton
                key={i}
                disabled={limit === i}
                className="ml-2"
                onClick={() => setPageSize(i)}
              >
                {i}
              </PaginationButton>
            ))}
          </div>
        </Col>
        <Col sm="auto" className="sm:ml-auto">
          <div className="space-x-1">
            <PaginationButton
              onClick={firstPage}
              disabled={!canPreviousPage}
              icon={faAngleDoubleLeft}
            />
            <PaginationButton
              onClick={previousPage}
              disabled={!canPreviousPage}
              icon={faAngleLeft}
            />
            <span className="text-sm text-gray-600">
              <Trans>
                {/* Page {{ pageIndex: pageIndex + 1 }} of {{ pageCount }} */}
              </Trans>
            </span>
            <PaginationButton
              onClick={nextPage}
              disabled={!canNextPage}
              icon={faAngleRight}
            />
            <PaginationButton
              onClick={lastPage}
              disabled={!canNextPage}
              icon={faAngleDoubleRight}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
});
