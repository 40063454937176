import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormGroup, Select, CustomButton, Input, DatePicker, SubmitButton } from 'components/react-hook-form';
import { useForm, FormProvider, useFormContext, useFormState } from 'react-hook-form';
import { Row, Col, Button, Modal, ModalBody, ModalFooter, Label, Alert } from 'reactstrap';
import { ModalHeader } from 'components/ModalHeader';
import { useUserContext, useClient } from 'hooks';
import { createClient } from 'hooks/useClient';
import { useQuery, useQueryClient } from 'react-query';

import {toBase64} from 'components/editors/utils';

import { Page, Search } from 'components/common';

import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const CreateNewPrintPacks = () => {
	const navigate = useNavigate();
	const client = useClient();
	const { user } = useUserContext();

	const methods = useForm({
		defaultValues: {},
		mode: 'all',
	});

	const { watch, setValue, formState, reset, handleSubmit, unregister, register } = methods;

	const [printPacks, setPrintPacks] = useState(null);
  const [search, setSearch] = useState("");
	// Edit Modal State
	const [editModal, setEditModal] = useState(false);
	const toggleEditModal = () => setEditModal(!editModal);
	const [editingPack, setEditingPack] = useState({ id: -1 });
	const [imagePreview, setImagePreview] = useState("");

  const packs = useMemo(() => {
    if (!printPacks) return [];
    return printPacks.filter(e => e.name.toLowerCase().includes(search.toLowerCase()))
}, [printPacks, search]);

	// const [printPanels, setPrintPanels] = useState([]);
	const [posesOptions, setPosesOptions] = useState([{ value: "1", label: "1"}]);

	const posesRef = useRef(null)
	posesRef.current = posesOptions

	const editingPackRef = useRef(null);
	editingPackRef.current = editingPack;



	// const panelsRef = useRef(null);
	// panelsRef.current = printPanels;

	const { refetch: getPrintPacks } = useQuery(
		'get_paint_packs',
		async () => {
			client.get(`print_packs`).then((data) => {
				// console.log('response data:', data);
				setPrintPacks(data.data);
				return null;
			});
			return null;
		},
		{
			refetchOnWindowFocus: false,
		}
	);

	const { refetch: getIndividualPrintPack } = useQuery(
		`get_individual_paint_pack`,
		async () => {
			if (editingPack.id != -1) {
				client.get(`print_packs/${editingPack.id}`).then((data) => {
					// console.log('response data:', data);

					for (const [key, value] of Object.entries(data.data.panels)) {
						console.log(`${key}:`, value);
						value.formId = parseInt(key)
					}


					setEditingPack({ ...data.data });
					setImagePreview(data.data.graphic)


					// Populate Poses Options
					const valuesArr = [];
					for (let i = 0; i < data.data.poses; i++) {
						valuesArr.push({ value: (i + 1).toString(), label: (i + 1).toString() });
					}
					setPosesOptions([...valuesArr]);
					// editingPackRef.current = editingPack;
					toggleEditModal();
					return null;

					// setPrintPacks(data.data);
				});
				return null;
			}
			return null;
		},
		{
			refetchOnWindowFocus: false,
		}
	);

	const {
		data: printPanelsList,
		error: printPanelsError,
		refetch: getPrintPanelsList,
	} = useQuery('get_paint_panels_list', async () => client.get(`print_panels`).get('data'), {
		refetchOnWindowFocus: false,
	});



	// const getPrintPacks = null
	// const getIndividualPrintPack = null;


	useEffect(() => {
		if (editingPack.active === undefined) {
			if (editingPack.id === -1) {
				// Do Nothing
			} else {
				getIndividualPrintPack();
			}
		}
	}, [editingPack]);


	function deletePrintPack(id) {
		// console.log(id)
		client
			.delete(`/print_packs/${id}`)
			.then((data) => {
				getPrintPacks();
				return null;
			})
			.catch((e) => {
				console.error('ERROR: ', e.response.data);
				return null;
			});
	}




	// const removePrintPanels = (id) => {
	// 	delete printPanels[id];
	// 	console.log(printPanels);
	// 	unregister(editingPack.id  + '.panels.' + id  );
	// 	setPrintPanels([ ...printPanels ]);
	// };

	const addNewPrintPanel = () => {
		if (!editingPack.panels) {
			editingPack.panels = []
		}
		editingPack.panels.push({
			formId: editingPack.panels.length,
			panel: null,
			pose: 1,
			quantity: 0,
		});
		setEditingPack({...editingPack})
		// printPanels.push({
		// 	id: printPanels.length,
		// 	// poseOptions: posesRef.current,
		// 	editingName: editingPack.id,
		// });
		// setPrintPanels([ ...printPanels ]);
	};

	// const removePrintRef = useRef(null);
	// removePrintRef.current = removePrintPanels;

	function getPrintPackByValue(value, key = 'id') {
		var result = printPacks.find((obj) => {
			return obj[key] === value;
		});
	}

	const createPrintPackFormItem = (element) => {
		return (
			<FormGroup key={element.id}>
				<Row key={element.id} style={{ alignItems: 'center' }} className='justify-content-center'>
					<Col xs='4' key={1}>
						<h5
							style={{
								float: 'right',
								margin: '0px',
								width: '100%',
								textAlign: 'end',
								textOverflow: 'ellipsis',
								overflow: 'hidden',
								wordWrap: 'break-word',
								display: 'block',
								lineHeight: '1em',
								maxHeight: '2em',
							}}
						>
							{' '}
							{element.name}{' '}
						</h5>
					</Col>
					<Col xs='2' key={2}>
						<CustomButton
							style={{ width: '100%' }}
							buttonColor='primary'
							onClick={() => {
								handleEditModal(element);
							}}
						>
							Edit
						</CustomButton>
					</Col>
					<Col xs='2' key={3}>
						<CustomButton
							style={{ width: '100%' }}
							buttonColor='danger'
							onClick={() => {
								deletePrintPack(element.id);
							}}
						>
							Delete
						</CustomButton>
					</Col>
					<Col xs={2}></Col>
				</Row>
			</FormGroup>
		);
	};


	const displayPrintPackList = () => {
		const packsToShow = [];
		// console.log(printPacks)
		printPacks.forEach((element) => {
			packsToShow.push(createPrintPackFormItem(element));
		});


		if (packsToShow.length > 0) {
			return packsToShow;
		}

		return <> No Print Packs </>;
	};

	const handleEditModal = (element) => {
		resetFormState();
		setEditingPack(element);
		if (element.id === -1) {
			toggleEditModal();
		}
		setImagePreview("")
		// loadPrintPanels();
	};

	const resetFormState = () => {
		reset()
		setPosesOptions([{ value: '1', label: '1' }]);
		// setPrintPanels([]);
	};



	const onSavePressed = async (all, e) => {
		console.log('event: ', e);
		console.log('Saving Now', all);

		const packToSave = all[editingPack.id]
		// packToSave.id = editingPack.id;

		if (packToSave.panels === undefined) {
			packToSave.panels = []
		} else {
			var temp = []
			for (let i of Object.values(packToSave.panels)) i && temp.push(i); // copy each non-empty value to the 'temp' array

			packToSave.panels = temp;

			packToSave.panels.forEach(panel => {
				// make each panel pose into an Int
				panel.pose = parseInt(panel.pose.value)
				panel.panel = panel.panel.id
			});


		}
		// const formData = new FormData();
		// Array.from(packToSave.graphic).forEach((x, index) => {
		// 	console.log('x: ', x)
		// 	formData.append(index, x);
		// });

		// formData.append(0, packToSave.graphic[0]);


		// console.log("graphic", packToSave.graphic)
		// console.log('graphic type', typeof packToSave.graphic);

		// console.log("formData", formData);

		// packToSave.graphic = e.target.graphic.files[0]
		console.log(packToSave.graphic);
		// if (packToSave.graphic[0]) {
		// 	packToSave.graphic = await toBase64(packToSave.graphic[0]);
		// } else {
		// 	packToSave.graphic = null;
		// }

		packToSave.graphic = packToSave.graphic_string

		delete packToSave.graphic_string

		console.log('packToSave', packToSave);

		if (editingPack.id != -1) {
			const url = `/print_packs/${editingPack.id}`;

			client
				.put(url, packToSave)
				.then((data) => {
					getPrintPacks();
					resetFormState();
					toggleEditModal();
					return null
				})
				.catch((e) => {
					console.error('ERROR: ', e.response.data);
					return null;
				});

		} else {
			client
				.post('/print_packs', packToSave)
				.then((data) => {
					getPrintPacks();
					resetFormState();
					toggleEditModal();
					return null
					
				})
				.catch((e) => {
					console.error('ERROR: ', e.response.data);
					return null
				});
		}
	};


	const createPrintPanelFormItem = (quantity, pose, panel, id) => {

		return (
			<Row className='mb-3' key={id}>
				<Col key={1} xs='3'>
					<Select
						getOptionLabel={(data) => {
							return data['name'];
						}}
						getOptionValue={(data) => {
							return data['id'];
						}}
						options={printPanelsList}
						{...register(editingPack.id + '.panels.' + id + '.panel', {
							rules: {
								required: false,
							},
						})}
						onChange={(newValue) => {
							console.log('e', newValue);
							setValue(editingPack.id + '.panels.' + id + '.panel', newValue);
						}}
						shouldUnregister={true}
						defaultValue={panel ?? ''}
					/>
				</Col>
				<Col key={2} xs='3'>
					<Select
						options={posesRef.current}
						// name={editingPack.id + '.panels.' + id + '.pose'}
						// rules={{
						// 	required: false,
						// }}
						getOptionLabel={(data) => {
							// "data" contains the current option object, so you just use your "labelKey" to return the value of that property.
							return data['value'];
						}}
						getOptionValue={(data) => {
							// "data" contains the current option object, so you just use your "valueKey" to return the value of that property.
							return data['label'];
						}}
						{...register(editingPack.id + '.panels.' + id + '.pose', {
							pattern: {
								value: /^[0-9]+$/,
								message: 'Please enter a number',
							},
							min: 0,
							rules: {
								required: false,
							},

							// valueAsNumber: true,
						})}
						onChange={(newValue) => {
							console.log('e', newValue);
							setValue(editingPack.id + '.panels.' + id + '.pose', newValue);
						}}
						shouldUnregister={true}
						defaultValue={pose ?? ''}
					/>
				</Col>
				<Col key={3} xs='3'>
					<Input
						type='number'
						className='form-control'
						name={editingPack.id + '.panels.' + id + '.quantity'}
						placeholder='enter name'
						rules={{
							required: false,
						}}
						defaultValue={quantity ?? 0}
					/>
				</Col>
				<Col key={4} xs='3'>
					<CustomButton
						onClick={(val) => {
							const editingPack = editingPackRef.current;
							const panelsState = editingPack.panels
							delete panelsState[id];
							console.log(panelsState);
							unregister(editingPack.id + '.panels.' + id);
							editingPack.panels = panelsState;
							setEditingPack({ ...editingPack });
						}}
						style={{ width: '100%', height: '100%' }}
						buttonColor='danger'
					>
						Remove
					</CustomButton>
				</Col>
			</Row>
		);
	};

	const handleFileChange = (e) => {
		console.log(e.target.value)
		// setValue('graphic', e.target.value.files[0]); // you get all the files object here
	};

	const createEditModalForm = () => {

		return (
			<FormProvider {...methods}>
				<FormGroup key={1} label='Pack Name'>
					<Input
						type='text'
						className='form-control'
						name={editingPack.id + '.name'}
						placeholder='Enter Name'
						rules={{
							required: false,
						}}
						defaultValue={editingPack.name ?? ''}
					/>
				</FormGroup>
				<FormGroup key={2} label='Description'>
					<Input
						type='text'
						className='form-control'
						name={editingPack.id + '.description'}
						placeholder='Enter Description'
						rules={{
							required: false,
						}}
						defaultValue={editingPack.description ?? ''}
					/>
				</FormGroup>
				<br />
				<Row key={3}>
					<Col key={1}>
						<Label check>
							<Input
								name={editingPack.id + '.special'}
								defaultChecked={editingPack.special ?? false}
								onChange={() => {
									// console.log('Check Pressed');
								}}
								type='checkbox'
							/>{' '}
							Is Special Product
						</Label>
					</Col>
					<Col key={2}>
						<Label check>
							<Input
								name={editingPack.id + '.active'}
								defaultChecked={editingPack.active ?? true}
								onChange={() => {
									// console.log('Check Pressed');
								}}
								type='checkbox'
							/>{' '}
							Is Active
						</Label>
					</Col>
				</Row>
				<br />
				<FormGroup key={4} label='Pack Price (£)'>
					<Input
						type='number'
						className='form-control'
						name={editingPack.id + '.price'}
						placeholder='enter name'
						rules={{
							required: false,
						}}
						defaultValue={editingPack.price ?? 0}
					/>
				</FormGroup>
				<br />
				<Row key={5}>
					<Col>
						<Label check>
							<Input name={editingPack.id + '.includes_cd'} defaultChecked={editingPack.includes_cd ?? false} type='checkbox' /> CD
						</Label>
					</Col>
					<Col>
						<Label check>
							<Input defaultChecked={editingPack.includes_download ?? false} name={editingPack.id + '.includes_download'} type='checkbox' /> Download
						</Label>
					</Col>
				</Row>
				<br />
				<FormGroup key={6} label='Number of Poses'>
					<Input
						type='number'
						className='form-control'
						name={editingPack.id + '.poses'}
						placeholder='enter name'
						rules={{
							required: false,
						}}
						defaultValue={editingPack.poses ?? 1}
						onChange={(e) => {
							// console.log(e.target.value);
							const count = e.target.value;
							const valuesArr = [];
							for (let i = 0; i < count; i++) {
								valuesArr.push({ value: (i + 1).toString(), label: (i + 1).toString() });
							}
							setPosesOptions([...valuesArr]);
						}}
					/>
				</FormGroup>
				<FormGroup key={7} label='Graphic'>
					<input
						type='file'
						className='form-control'
						// name={editingPack.id + '.graphic'}
						// placeholder='enter name'
						// rules={{
						// 	required: false,
						// }}
						// onChange={handleFileChange}
						// defaultValue={editingPack.graphic ?? ''}
						id='graphic'
						{...register(editingPack.id + '.graphic', {
							required: false,
						})}
						onChange = {async (e) => {
								const file = e.target.files[0];
								console.log(file);
								await toBase64(file).then((string) => {
									console.log(string);
									setImagePreview(string);
									setValue(editingPack.id + '.graphic_string', string)
								});
							}}
					/>
				</FormGroup>
				{imagePreview != '' && imagePreview != null && (
					<div style={{ position: 'relative' }}>
						<img src={`data:image/jpeg;base64, ${imagePreview}`} style={{ width: '100%' }} />

						<FontAwesomeIcon
							icon={faTimesCircle}
							className='mr-2 fa-3x add-button-center-circle'
							onClick={() => {
								// console.log('MMM clicked');
								setImagePreview("");
								setValue(editingPack.id + '.graphic_string', "delete");
								// e.target.value = '';
							}}
							style={{ position: 'absolute', top: '4px', right: '5px', cursor: 'pointer' }}
						/>
					</div>
				)}

				<br />
				<Row key={8}>
					<Col>
						<Label check>
							<Input defaultChecked={editingPack.highlight_description ?? false} name={editingPack.id + '.highlight_description'} type='checkbox' /> Highlight Description
						</Label>
					</Col>
				</Row>
				<br />
				{editingPack.panels && Object.keys(editingPack.panels).length > 0 && (
					<Row key={9}>
						<Col xs='3'>
							<Label> Print Panel </Label>
						</Col>
						<Col xs='3'>
							<Label> Pose </Label>
						</Col>
						<Col xs='3'>
							<Label> Quantity </Label>
						</Col>
						<Col xs='3'></Col>
					</Row>
				)}
				{displayPrintPanels()}
				<Row key={10} style={{ alignItems: 'center' }} className='justify-content-center'>
					<Col xs='6'>
						<FormGroup>
							<CustomButton buttonColor='primary' onClick={addNewPrintPanel} style={{ width: '100%' }}>
								Add Print Panel
							</CustomButton>
						</FormGroup>
					</Col>
				</Row>
			</FormProvider>
		);

		return <> error could not create edit form please try again </>;
	};

	const displayPrintPanels = () => {
		const arrToShow = [];
		if (!editingPack.panels) {
			return <> </>;
		}

		for (const [key, value] of Object.entries(editingPack.panels)) {
			// console.log(value)
			if (value) {
				const chosenPanel = printPanelsList.filter((obj) => {
					return obj.id === value.panel;
				});
				// console.log(chosenPanel[0])
				const panel = chosenPanel[0] ? { name: chosenPanel[0].name, id: value.panel.toString() } : '';
				const pose = value.pose ? { value: value.pose.toString(), label: value.pose.toString() } : '';
				const quantity = value.quantity ?? 0
				const id = value.formId ?? null

				arrToShow.push(createPrintPanelFormItem(quantity, pose, panel, id));
			}
		}

		if (arrToShow.length > 0) {
			return arrToShow;
		} else {
			return <> </>;
		}
	};

	return (
		<div className='main-content-container d-flex flex-row'>
			<Page>
			<div className='font-bold text-2xl tracking-wide mb-3'> Print Packs </div>
				<hr />
				<FormProvider {...methods}>
        <Row form className="py-3">
						<Col>
						<Search
							search={search}
							setSearch={setSearch}
							placeholder="Search for print packs..."
						/>
						</Col>
						<Col xs="3">
							<FormGroup>
								<CustomButton className="w-100" color="darker" onClick={() => toggleEditModal()}>
									Create print pack
								</CustomButton>
							</FormGroup>
						</Col>
					</Row>
          {packs.map((element) => {
            return (
              <FormGroup key={element.id}>
                <Row key={element.id}>
                  <Col xs='8' key={1}>
                  <div className="w-100 text-lg tracking-wide truncate block">
                  {element.name}
                  </div>
                    {/* <h5
                      style={{
                        float: 'right',
                        margin: '0px',
                        width: '100%',
                        textAlign: 'end',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        wordWrap: 'break-word',
                        display: 'block',
                        lineHeight: '1em',
                        maxHeight: '2em',
                      }}
                    >
                      {' '}
                      {element.name}{' '}
                    </h5> */}
                  </Col>
                  <Col xs='2' key={2}>
                  <CustomButton className="w-100" color="darker" onClick={() => handleEditModal(element)}>
                      Edit
                    </CustomButton>
                  </Col>
                  <Col xs='2' key={3}>
                    <CustomButton className="w-100" buttonColor='danger' onClick={() => deletePrintPack(element.id)}>
                      Delete
                    </CustomButton>
                  </Col>

                </Row>
              </FormGroup>
            );
          })}
          {(!printPacks || printPacks.length === 0) &&
          <Alert color="info">
            No print packs found
          </Alert>
          }
          {/* {displayPrintPackList()} */}
				</FormProvider>

				{/* Editor Modal */}
				<div>
					<Modal size='lg' isOpen={editModal}>
						<ModalHeader>{editingPack.name === undefined ? 'Create New Print Pack' : 'Editing ' + editingPack.name}</ModalHeader>

						<ModalBody>{editModal ? createEditModalForm() : <></>}</ModalBody>

						<ModalFooter>
							<FormProvider {...methods}>
								<form onSubmit={handleSubmit(onSavePressed)}>
									<Row>
										<Col>
											<Button
												onClick={() => {
													resetFormState();
													handleEditModal({ id: -1 });
												}}
											>
												Cancel
											</Button>
										</Col>
										<Col>
											<SubmitButton>Save</SubmitButton>
										</Col>
									</Row>
								</form>
							</FormProvider>
						</ModalFooter>
					</Modal>
				</div>
			</Page>
		</div>
	);
};

export default CreateNewPrintPacks;
