import React from 'react';
import classNames from 'classnames';

export const DisplayInfo = ({ values }) => {
  return (
    <div className="border-t border-gray-200 pb-3">
      <dl>
        {values.map((v, idx) => (
          <div key={idx} className={classNames(idx % 2 ? "bg-white" : "bg-gray-50", "px-1 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6")}>
            <dt className="text-sm font-medium text-gray-500">{v[0]}</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{v[1]}</dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

export default DisplayInfo;