
// From Template
export Login from './Login.jsx';
export Signup from './Signup.jsx';
export Home from './Home.jsx';

// General
export GenComposites from './GenComposites.jsx';
export StartNewJob from './StartNewJob.jsx';
export GenerateProofs from './GenerateProofs.jsx'; // Replaces StartNewJob+GenProofCards
export CreateNewPanels from './CreateNewPanels/CreateNewPanels.jsx';
export GenProofCards from './GenProofCards.jsx';
// export GenerateProofCards from './GenerateProofCards.jsx';
export SettingsAndPresets from './SettingsAndPresets.jsx';
export ProcessStaffImageJobs from './ProcessStaffImageJobs.jsx';
export ProcessOrders from './ProcessOrders.jsx';
export ViewWorkflow from './ViewWorkflow.jsx';

// Settings and Presets
export CreateNewPrintPacks from './CreateNewPrintPacks.jsx';
export PrintPanels from './PrintPanels.jsx';
export AssignPrintPacksToProofCards from './AssignPrintPacksToProofCards.jsx';
export CreateNewProofCard from './CreateNewProofCard.jsx';
export AutoImportSystemPresets from './AutoImportSystemPresets.jsx';
export CreateNewStaffPhotoTemplate from './CreateNewStaffPhotoTemplate.jsx';

// Photographer App
export PhotographerDownloadJobData from './PhotographerDownloadJobData.jsx'
export PhotographerStartJob from './PhotographerStartJob.jsx'
export PhotographerUploadJobPhotos from './PhotographerUploadJobPhotos.jsx'
export ViewPhotographerJobs from './ViewPhotographerJobs.jsx'
