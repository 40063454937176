import React, { useEffect, useState, useMemo } from 'react';
import { DraggableGraphic, GraphicTypes } from 'components/editors';
import { ModalHeader } from 'components/ModalHeader';
import { CustomButton, FormGroup, Input, SubmitButton } from 'components/react-hook-form';
import { useClient, useUserContext } from 'hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Col, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';

import EditorArea from 'components/editors/GenComposites/EditorArea';
import { getScale, toBase64 } from 'components/editors/utils';

import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Page, Search } from 'components/common';





export const PrintPanels = () => {
	const client = useClient();

	const createDefaultBoxes = () => {
		const result = {}

		for (let i = 0; i < 12; i++) {
			const name = "graphic_" + i
			const newGraphic = {
				top: 0,
				left: 0,
				text: 'Photo ' + (i + 1),
				ratio: 1.25,
				width: 1.0,
				height: 1.0,
				rotated: false,
				height_ratio: 1.25,
				visible: false,
			}
			result[name] = newGraphic

		}

		return result
	}

	const methods = useForm({
		defaultValues: {
			layout:{
				boxes:{
					...createDefaultBoxes()
				}
			}
		},
		mode: 'all',
	});

	const { watch, setValue, reset, handleSubmit, register, getValues } = methods;

  const [panelData, setPanelData] = useState([]);

	const [scaleValue, setScaleValue] = useState(100);
  const [search, setSearch] = useState("");

	// Edit Modal State

	const newPanelObject = { id: -1, layout: { size_x: 1, size_y: 1 } };

	const [editModal, setEditModal] = useState(false);
	const toggleEditModal = () => setEditModal(!editModal);
	const [editingPanel, setEditingPanel] = useState(newPanelObject);
	const [pageBackground, setPageBackground] = useState('');

	const [selectedPhoto, setSelectedPhoto] = useState({ id: -1, type: 'None', element: null });

  const panels = useMemo(() => {
      return panelData.filter(e => e.name.toLowerCase().includes(search.toLowerCase()))
  }, [panelData, search]);

	const { refetch: getPrintPanels } = useQuery(
		'get_paint_panels',
		async () => {
			client.get(`print_panels`).then((data) => {
				setPanelData(data.data);
			});
			return null;
		},
		{
			refetchOnWindowFocus: false,
		}
	);

	const { refetch: getIndividualPrintPanel } = useQuery(
		`get_individual_paint_pack`,
		async () => {
			if (editingPanel.id != -1) {
				client.get(`print_panels/${editingPanel.id}`).then((data) => {
					// console.log('response data:', data);
					console.log('received response');

					const packData = data.data;

					if (!packData.layout) {
						packData.layout = {};
					}
					if (!packData.layout.boxes) {
						packData.layout.boxes = {};
					}
					setEditingPanel({ ...packData });

					// set all the form values to the ones from the backend
					setPageBackground(packData.template);

					// const scaledXandY = getSizeOfPage(parseFloat(packData.layout.size_x), parseFloat(packData.layout.size_y), 10)

					// packData.layout.size_x = scaledXandY[0]
					// packData.layout.size_y = scaledXandY[1]


					var stevenScale = getScale(parseFloat(packData.layout.size_x), parseFloat(packData.layout.size_y));
					const toMultiply = (stevenScale * parseFloat(packData.layout.size_x)) < 500;
					if (toMultiply) {
						stevenScale *= 5
					}

					setScaleValue(stevenScale)

					for (const [key, value] of Object.entries(packData.layout.boxes)) {
						console.log(`${key}:`, value);
			
						packData.layout.boxes[key].width = value.width * packData.layout["size_x"]
						packData.layout.boxes[key].height = value.height * packData.layout["size_y"]
			

					}
					// console.log("size: ", getSizeOfPage(parseFloat(packData.layout.size_x), parseFloat(packData.layout.size_y), 10))
					// setScaleValue(getSizeOfPage(packData.layout.size_x, packData.layout.size_y, 100))
					// console.log("Scale: ", getScale(parseFloat(packData.layout.size_x), parseFloat(packData.layout.size_y)))
					// setScaleValue(getScale(parseFloat(packData.layout.size_x), parseFloat(packData.layout.size_y)))


					packData.photos_num = Object.keys(packData.layout.boxes).length


					reset(packData);

					toggleEditModal();

					// setPrintPacks(data.data);
					// syncForm("object");
				});
			}
			return null;
		},
		{
			refetchOnWindowFocus: false,
		}
	);

	useEffect(() => {
		if (editingPanel.layout === undefined) {
			if (editingPanel.id === -1) {
				// Do Nothing
			} else {
				getIndividualPrintPanel();
			}
		}
	}, [editingPanel]);

	useEffect(() => {
		console.log(getValues())
		console.log(selectedPhoto);
	}, [selectedPhoto]);

	function deletePrintPanel(id) {
		// console.log(id)
		client
			.delete(`/print_panels/${id}`)
			.then(() => {
				getPrintPanels();
			})
			.catch((e) => {
				console.error('ERROR: ', e.response.data);
			});
	}

	const handleEditModal = (element) => {
		resetFormState();
		setEditingPanel(element);
		if (element.id === -1) {
			toggleEditModal();
		}
		reset({})
		// loadPrintPanels();
	};

	const resetFormState = () => {
		reset({});
		setPageBackground('');
		// setPosesOptions([{ value: '1', label: '1' }]);
		// setPanelData([]);
	};

	const onSavePressed = async ({ ...all }) => {
		console.log('Saving Now', all);

		const panelToSave = all;
		console.log(panelToSave)
		// packToSave.id = editingPack.id;

		// if (panelToSave.panels === undefined) {
		// 	panelToSave.panels = []
		// } else {
		// 	panelToSave.panels.forEach(panel => {
		// 		// make each panel pose into an Int
		// 		panel.pose = parseInt(panel.pose.value)
		// 	});
		// }

		// panelToSave.layout = {
		// 	size_x:panelToSave.x,
		// 	size_y:panelToSave.y,
		// }

		// panelToSave.template = "hehe"
		console.log(typeof panelToSave.template);
		if (panelToSave.template && typeof panelToSave.template != typeof 'this' && panelToSave.template[0]) {
			panelToSave.template = await toBase64(panelToSave.template[0]);
		}

		for (const [key, value] of Object.entries(panelToSave.layout.boxes)) {
			console.log(`${key}:`, value);

			panelToSave.layout.boxes[key].width = value.width / panelToSave.layout["size_x"]
			panelToSave.layout.boxes[key].height = value.height / panelToSave.layout["size_y"]
			panelToSave.layout.boxes[key].height_ratio = (panelToSave.layout.boxes[key].height / panelToSave.layout.boxes[key].width) * (panelToSave.layout["size_y"] / panelToSave.layout["size_x"]);
			console.log(panelToSave.layout["size_x"])
			delete panelToSave.layout.boxes[key].Text
			delete panelToSave.layout.boxes[key].is_text


		}



		if (editingPanel.id != -1) {
			const url = `/print_panels/${editingPanel.id}`;

			client
				.put(url, panelToSave)
				.then(() => {
					getPrintPanels();
					resetFormState();
					toggleEditModal();
				})
				.catch((e) => {
					console.error('ERROR: ', e.response.data);
				});
		} else {
			client
				.post('/print_panels', panelToSave)
				.then(() => {
					getPrintPanels();
					resetFormState();
					toggleEditModal();
				})
				.catch((e) => {
					console.error('ERROR: ', e.response.data);
				});
		}
	};


	const createEditModalForm = () => {
		// console.log("answer")

		return (
			<FormProvider {...methods}>
				<FormGroup key={1} label='Panel Name'>
					<Input
						type='text'
						className='form-control'
						name={'name'}
						rules={{
							required: false,
						}}
						// defaultValue={editingPanel.name ?? ''}
					/>
				</FormGroup>
				<label>Paper Size</label>
				<Row key={3} style={{ alignItems: 'center' }} className='justify-content-center'>
					<Col key={2}>
						<FormGroup key={1}>
							<input
								type='number'
								className='form-control'
								placeholder='0'
								{...register('layout.size_x', {
									required: false,
									value: 7,
									valueAsNumber: false,
								})}
								onChange={(e) => {
									const {value} = e.target;
									setValue('layout.size_x', value.toString());
									const page = document.getElementById('page');
									page.style.width = value * scaleValue + 'px';
								}}
								min={1}
								max={9000}
								step={1}
							/>
						</FormGroup>
					</Col>
					<Col sm={1} key={1}>
						<FormGroup key={1} style={{ alignItems: 'center', textAlign: 'center' }} className='justify-content-center'>
							<label style={{ margin: '0px' }}>X</label>
						</FormGroup>
					</Col>
					<Col key={3}>
						<FormGroup key={1}>
							<input
								type='number'
								className='form-control'
								placeholder='0'
								{...register('layout.size_y', {
									required: false,
									value: 5,
									// valueAsNumber: true,
								})}
								onChange={(e) => {
									const {value} = e.target;
									setValue('layout.size_y', value.toString());
									const page = document.getElementById('page');
									page.style.height = value * scaleValue + 'px';
								}}
								min={1}
								max={9000}
								step={1}
							/>
						</FormGroup>
					</Col>
				</Row>
				<FormGroup key={4} label='Template'>
					<input
						type='file'
						className='form-control'
						id='template'
						{...register('template', {
							required: false,
							onChange: async (e) => {
								const file = e.target.files[0];
								console.log(file);
								await toBase64(file).then((string) => {
									console.log('String: ', string);
									setPageBackground(string);
								});
							},
						})}
					/>
				</FormGroup>
				<Row className='mb-2' key={8}>
					<Col>
						<Label check>
							<Input defaultChecked={(editingPanel.layout && editingPanel.layout.single_pose) ?? false} name={editingPanel.id + '.layout.single_pose'} type='checkbox' /> Use Same Pose For All
						</Label>
					</Col>
				</Row>

				<FormGroup key={5} label='Number of photos'>
					<input
						type='number'
						className='form-control'
						{...register('photos_num', {
							required: false,
							valueAsNumber: true,
						})}
						min={0}
						max={12}
						// defaultValue={(editingPanel.layout && editingPanel.layout.boxes && Object.keys(editingPanel.layout.boxes).length) ?? 0}
						defaultValue={0}
						onChange={handlePhotoNumberChange}
					/>
				</FormGroup>
				{/* {getGraphicsList()} */}
				{/* {console.log("hello yes this is the selected item,", selectedPhoto)} */}
				{(selectedPhoto.id > -1) && getImageContextMenu()}
			</FormProvider>
		);

		return <> error could not create edit form please try again </>;
	};

	const handlePhotoNumberChange = (e) => {
		const changeValue = parseInt(e.target.value);

		const boxes = getValues("layout.boxes") ?? {}
		const boxesNum = Object.keys(boxes).length ?? 0;



		// Add any new boxes
		if (boxesNum < changeValue) {
			// const diff = changeValue - boxesNum;

			for (let i = boxesNum; i < changeValue; i++) {
				// console.log("Adding new box")
				const newBox = {
					top: 0,
					left: 0,
					text: 'Photo ' + (i + 1),
					ratio: 1.25,
					width: 1.0,
					height: 1.0,
					rotated: false,
					height_ratio: 1.25,
				};

				boxes['photo_' + i] = newBox;

			}

		} else {
			// Delete any boxes higher than the selected index
			for (const [key] of Object.entries(boxes)) {
				const index = parseInt(key.replace('photo_', ''))
				if (index > changeValue) {
					delete boxes[key]
				}
			}
		}

		setValue("layout.boxes", boxes)
		setValue('photos_num', changeValue);
	};

	const createDraggablePhotos = () => {

		const toReturn = [];
		// if (getValues('layout.boxes')) {
			for (const [key] of Object.entries(getValues('layout.boxes'))) {
				toReturn.push(<DraggableGraphic
					graphicPrefix='photo_'
					scaleValue={scaleValue}
					methods={methods}
					setSelectedItem={setSelectedPhoto}
					id={key}
					type={GraphicTypes.PRINT_PANEL}
					values={watch('layout.boxes.'+key)}
				/>);
			}
		// }

		return toReturn.length > 0 ? toReturn : null;
	};

	const finishedResizingHandler = (e) => {
		const photoId = 'layout.boxes.photo_' + selectedPhoto.id;
		// const elementNamePrefix = selectedPhoto.type + '-' + selectedPhoto.id;
		// const chosenPhotoId = editingPanel.id + ('.layout.boxes.photo_' + selectedPhoto.id);
		// console.log('Caught Event!!');
		// console.log(e.target);
		console.log('Caught Resize Event!!');

		console.log(photoId);

		const element = e.target;
		console.log(element.clientHeight);
		console.log(element.clientWidth);

		// const heightResult = (element.clientHeight/100) / parseInt(getValues('layout.size_y'));
		// const widthResult =(element.clientWidth/100) / parseInt(getValues('layout.size_x'));

		const heightResult = (element.clientHeight/100);
		const widthResult =(element.clientWidth/100);

		setValue(photoId + '.height', heightResult);
		setValue(photoId + '.width', widthResult);

		// selectedPhoto.element = element;
		// setSelectedPhoto({ ...selectedPhoto });
		finishedDraggingHandler(e, element);
	};

	const finishedDraggingHandler = (e, element = null) => {
		console.log('Caught Drag Event!!');

		const photoId = 'layout.boxes.photo_' + selectedPhoto.id;

		if (!element) {
			element = e.target;
		}

		// const x = element.attributes["data-x"].value
		// const y = element.attributes["data-y"].value

		const xResult = element.getAttribute('data-x') / parseFloat(getValues('layout.size_x'));
		const yResult = element.getAttribute('data-y') / parseFloat(getValues('layout.size_y'));
		console.log("xResult: " + xResult + ", yResult: " + yResult);

		// const heightResult = element.clientHeight / (parseInt(getValues('layout.size_x')) / 2);
		// const widthResult = element.clientWidth / (parseInt(getValues('layout.size_y')) / 2);

		// console.log(parseInt(getValues('layout.size_y')))
		// console.log(parseInt(getValues('layout.size_x')))

		console.log(xResult, yResult);

		// console.log("x: " + x + ", y: " + y);
		// setValue(chosenPhotoId + '.left', x);
		// setValue(chosenPhotoId + '.top', y);

		console.log((xResult)/100, (yResult)/100);

		setValue(photoId + '.left', (xResult)/100);
		setValue(photoId + '.top', (yResult)/100);

		// selectedPhoto.element = element;
		// setSelectedPhoto({ ...selectedPhoto });
	};

	const getImageContextMenu = () => {
		// console.log("Showing context menu now")
		// const selectedPhoto = selectedPhotoRef.current;

		// console.log('Rendering Context Menu Now');
		const {element} = selectedPhoto;
		if (!element.classList.contains('finishedResizingHandler')) {
			element.classList.add('finishedResizingHandler');
			element.addEventListener('finishedResizing', finishedResizingHandler, false);
		}
		if (!element.classList.contains('finishedDraggingHandler')) {
			element.classList.add('finishedDraggingHandler');
			element.addEventListener('finishedDragging', finishedDraggingHandler, false);
		}

		const {value} = selectedPhoto;

		const chosenPhotoId = 'layout.boxes.photo_' + selectedPhoto.id;

		if (!getValues(chosenPhotoId)) {
			return <> </>;
		}

		console.log(getValues('layout.size_y'));
		const selectedImage = (
			<div className='border-top pt-2' id={selectedPhoto.id} key={selectedPhoto.id}>
				<FormProvider {...methods}>
					<h3> {value.text ?? ''} </h3>
					<Row key={3} style={{ alignItems: 'center' }} className='justify-content-center'>
						<Col key={2}>
							<FormGroup key={1} label='width'>
								{/* <Input type='number' className='form-control' name={chosenPhotoId + '.width'} value={width} /> */}
								<input
									type='number'
									className='form-control'
									placeholder='0'
									{...register(chosenPhotoId + '.width', {
										required: false,
										// value: {getValues(chosenPhotoId + '.width') },
										valueAsNumber: true,
									})}
									min={1}
									max={parseInt(watch('layout.size_x'))}
									step={1}
									onChange={(e) => {
										const {value} = e.target;
										document.getElementById('photo_' + selectedPhoto.id).style.width = value * scaleValue + 'px';
										setValue(chosenPhotoId + '.width', value);
									}}
								/>
							</FormGroup>
						</Col>
						<Col key={3}>
							<FormGroup key={1} label='height'>
								{/* <Input type='number' className='form-control' name={chosenPhotoId + '.height'} value={height} /> */}
								<input
									type='number'
									className='form-control'
									placeholder='0'
									{...register(chosenPhotoId + '.height', {
										required: false,
										// value: { height },
										valueAsNumber: true,
									})}
									min={1}
									max={parseInt(watch('layout.size_y'))}
									step={1}
									onChange={(e) => {
										const {value} = e.target;
										document.getElementById('photo_' + selectedPhoto.id).style.height = value * scaleValue + 'px';
										setValue(chosenPhotoId + '.height', value);
									}}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row className='mb-2' key={8}>
						<Col>
							<FormGroup key={1}>
								<Label check>
									<input
										checked={getValues(chosenPhotoId).rotated}
										// name={chosenPhotoId + '.rotated'}
										{...register(chosenPhotoId + '.rotated', {
											required: false,
										})}
										type='checkbox'
										onChange={(e) => {

											const width = getValues(chosenPhotoId + '.width');
											const height = getValues(chosenPhotoId + '.height');

											// if (checked) {
											setValue(chosenPhotoId + '.height', width);
											setValue(chosenPhotoId + '.width', height);
											// } else {
											// setValue(chosenPhotoId + '.height', height);
											// setValue(chosenPhotoId + '.width', width);
											// }

											setValue(chosenPhotoId + '.rotated', !getValues(chosenPhotoId).rotated ?? true);

											const elementToUpdate = document.getElementById('photo_' + selectedPhoto.id);

											const elementHeight = elementToUpdate.clientHeight;
											const elementWidth = elementToUpdate.clientWidth;

											elementToUpdate.style.height = elementWidth + 'px';
											elementToUpdate.style.width = elementHeight + 'px';

											// syncForm();
											// TODO GET THE ROTATE BUTTON TO RE-RENDER PROPERLY
											setEditingPanel({ ...editingPanel });
											// console.log("values here", getValues(editingPanel.id + '.layout.boxes'))
										}}
									/>{' '}
									Rotated
								</Label>
							</FormGroup>
						</Col>
					</Row>
					{/* <Row className="mb-3">
						<Col>
							<CustomButton buttonColor='secondary' onClick={() => {
								const width = getValues(chosenPhotoId).width;
								const height = getValues(chosenPhotoId).height;
								const rotated = getValues(chosenPhotoId).rotated;

								// console.log("width: " + width + " height: " + height)
								// setValue(chosenPhotoId.height, width);
								// setValue(chosenPhotoId.width, height);
								// setValue(chosenPhotoId.rotated, !rotated);
								// console.log("values: ", getValues())
								// setEditingPanel({ ...editingPanel });
							}}>
								Rotate
							</CustomButton>
						</Col>
					</Row> */}
				</FormProvider>
			</div>
		);

		if (selectedPhoto.id >= 0) {
			return selectedImage;
		} else {
			return <> Hello </>;
		}
	};

	return (
		<div className='main-content-container d-flex flex-row'>
			<Page>
			<div className='font-bold text-2xl tracking-wide mb-3'>
				Print Panels
			</div>
				<hr />
				<FormProvider {...methods}>

					<Row form className="py-3">
						<Col>
						<Search
							search={search}
							setSearch={setSearch}
							placeholder="Search for print panels..."
						/>
						</Col>
						<Col xs="3">
							<FormGroup>
								<CustomButton
									color='darker'
									onClick={() => {
										handleEditModal(newPanelObject);
									}}
									style={{ width: '100%' }}
									>
									Create print panel
								</CustomButton>
							</FormGroup>
						</Col>
					</Row>
					{panels.map((element) => {
						return (
							<FormGroup key={element.id}>
							<Row key={element.id}>
								<Col xs='8' key={1}>
									<div className="w-100 text-lg tracking-wide truncate block">
                    {element.name}
                  </div>
								</Col>
								<Col xs='2' key={2}>
									<CustomButton
										style={{ width: '100%' }}
										color='darker'
										onClick={() => {
											handleEditModal(element);
										}}
									>
										Edit
									</CustomButton>
								</Col>
								<Col xs='2' key={3}>
									<CustomButton
										style={{ width: '100%' }}
										buttonColor='danger'
										onClick={() => {
											deletePrintPanel(element.id);
										}}
									>
										Delete
									</CustomButton>
								</Col>
							</Row>
						</FormGroup>
						);
					})
					}
					{panels.length === 0 &&
					<Alert color="info">
						No print panels
					</Alert>}
				</FormProvider>

				{/* Editor Modal */}
				<div>
					<Modal isOpen={editModal} className='wide-modal'>
						<ModalHeader>{editingPanel.name === undefined ? 'Create New Print Panel' : 'Editing ' + editingPanel.name}</ModalHeader>

						<ModalBody className='pt-0'>
							<Row>
								<Col xl={3}>{createEditModalForm()}</Col>


								<Col xl={9} className='p-0 border-l'>
									<EditorArea methods={methods} editorStyle={{ height: 'calc(100% - 38px)' }} defaultWidth={getValues('layout.size_x') * scaleValue} defaultHeight={getValues('layout.size_y') * scaleValue} pageBackgroundColor={'gray'}>
										{watch('photos_num') && !!watch('layout.boxes') && createDraggablePhotos()}
									</EditorArea>
								</Col>
							</Row>
						</ModalBody>

						<ModalFooter>
							<FormProvider {...methods}>
								<form onSubmit={handleSubmit(onSavePressed)}>
									<Row>
										{/* <Col>
											<Button
												onClick={() => {
													console.log(getValues());
												}}
											>
												Log
											</Button>
										</Col> */}
										<Col>
											<Button
												onClick={() => {
													resetFormState();
													handleEditModal(newPanelObject);
												}}
											>
												Cancel
											</Button>
										</Col>
										<Col>
											<SubmitButton>Save</SubmitButton>
										</Col>
									</Row>
								</form>
							</FormProvider>
						</ModalFooter>
					</Modal>
				</div>
			</Page>
		</div>
	);
};

export default PrintPanels;
