import React, { useState, useEffect } from 'react'
import { ReOrderableItem, ReOrderableList } from 'react-reorderable-list'
import 'react-reorderable-list/dist/index.css'
import { Row, Col, Label } from 'reactstrap';
import { FormGroup, Select, CustomButton, Input,  SubmitButton } from 'components/react-hook-form';
import {faTrash, faBars} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export const AssignOrderedList = ({printPacks, methods}) => {

    const { watch, setValue } = methods;

    const editingPack = watch('chosenProofPack')

    const list = editingPack.packs

    function setList(newList) {
        console.log(newList)
        setValue('chosenProofPack.packs', newList)
    }


    const removePrintPack = (id) => {
        const packs = editingPack.packs
        delete packs[id];
        setValue('chosenProofPack.packs', packs.filter(pack => {
            return pack !== null;
        }));
	};

    function getPrintPackByValue(value, key = 'id') {
		var result = printPacks.find((obj) => {
			return obj[key] == value;
		});
		return result;
	}


    return (
        <div className = "w-100" style={{ display: 'flex', gap: '30px' }}>
        <ReOrderableList
            //The unique identifier for this list. Should be unique from other lists and list groups.
            name='list2'
            //your list data
            list={list}
            //the list update callback
            onListUpdate={(newList) => {
                console.log(newList)
                setList([...newList])
            }}
            style={{
            width: '100%'
            }}
            componentProps={{
                    style: { width: '100%', minHeight: '200px', minWidth: '100px' }
                }}
            className = "w-100"
        >
            {list.map((element, index) => (
            <ReOrderableItem className = "w-100" key={`item-${index}`} >
                <Row role='button' style={{
                        // border: '1px solid rgba(1,1,1,0.2)',
                        margin: '10px',
                        padding: '5px',
                        borderRadius: '5px',
                        alignItems: 'center',
                    }}
                    className='justify-content-center'>
                    <Col key={1} xs={1}>
                        <FontAwesomeIcon className="mr-2" icon={faBars} />
					</Col>
                    <Col key={2} >
                        {getPrintPackByValue(parseInt(element)).name}
					</Col>
                    <Col key={4} xs='2'>
						<CustomButton
							onClick={() => {
								removePrintPack(index);
							}}
							// style={{ width: '100%', height: '100%' }}
							buttonColor='danger'
						>
							Remove
                            {/* {element} */}
						</CustomButton>
					</Col>
                </Row>
            </ReOrderableItem>
            ))}
        </ReOrderableList>
        </div>
    )
}