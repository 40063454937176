import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import cx from "classnames";

export const FetchingSpinner = ({
  isFetching,
  icon = faSpinner,
  spin = true,
  delay = 200,
  className,
  ...props
}) => {
  const [visible, setVisible] = useState();

  useEffect(() => {
    if (isFetching) {
      setVisible(false);

      const timeout = setTimeout(() => {
        setVisible(true);
      }, delay);

      return () => clearTimeout(timeout);
    }
  }, [isFetching, delay]);

  const show = visible && isFetching;

  return (
    <FontAwesomeIcon
      icon={icon}
      spin={spin}
      className={cx(
        className,
        "transition-opacity text-gray-500 duration-300",
        !show ? "opacity-0" : "opacity-100"
      )}
      {...props}
    />
  );
};
