import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button, Modal, ModalBody, ModalFooter, Label } from 'reactstrap';
import { useQuery, useQueryClient } from 'react-query';
import { ModalHeader } from 'components/ModalHeader';
import { toBase64 } from 'components/editors/utils';
import { useForm, FormProvider, useFormContext, useFormState } from 'react-hook-form';
import { FormGroup, Select, CustomButton, Input, DatePicker } from 'components/react-hook-form';
import { RSubmitButton as SubmitButton } from 'components/react-hook-form';
import { NotificationManager } from 'react-notifications';

import { useUserContext, useClient } from 'hooks';

import 'react-slideshow-image/dist/styles.css';
import { Page } from 'components/common';
import { Slide } from 'react-slideshow-image';

export const CreateNewPanelsMulti = ({ setSelectedForm }) => {
	const navigate = useNavigate();
	const client = useClient();
	const { user } = useUserContext();

	const methods = useForm({
		defaultValues: {},
		mode: 'all',
	});

	const {
		data: printPanels,
		error: printPanelsError,
		refetch: getPrintPacks,
	} = useQuery('get_print_panels', async () => client.get(`print_panels`).get('data'), {
		refetchOnWindowFocus: false,
	});

	const { watch, setValue, formState, reset, handleSubmit, unregister, register, getValues } = methods;

	const watchImages = watch('images');
	const watchPanels = watch('panel');

	const [isCreatingPanels, setIsCreatingPanels] = useState(false)









	const createForm = () => {
		return (
			<FormProvider {...methods}>
				<Row>
					<Col>
						<FormGroup label='Folder'>
							<input
								webkitdirectory='true'
								directory='true'
								multiple={true}
								type='file'
								className='form-control'
								id='images'
								{...register('images', {
									required: false,
								})}
								onChange={async (images) => {
									console.log('e', images);
									const files = images.target.files
									console.log('Files: ', files);

									// if (image.id > 0) {
									// 	// just one image has been selected
									// 	// const tempImage = await toBase64(files[image.name]);
									// 	image.images = [await toBase64(files[image.name])];
									// } else {
										// use all images

										const images_base64 = [];
										for (const [key, value] of Object.entries(files)) {
											console.log(`${key}`, value);
											const imageObject = {
												data: await toBase64(value),
												name: value.name,
												type: value.type,
											}
											images_base64.push(imageObject);
										}
										// images.images = images;
									// }
									// image.images = toBase64(files[image.name]);
									console.log('images:', images_base64);
									setValue(`images_to_save`, images_base64);
								}}
							/>
						</FormGroup>
					</Col>
					<Col>
						<FormGroup label='Print Packs'>
							<Select
								getOptionLabel={(data) => {
									return data['name'];
								}}
								getOptionValue={(data) => {
									return data['id'];
								}}
								options={printPanels}
								{...register(`panel`, {
									rules: {
										required: false,
									},
								})}
								onChange={(newValue) => {
									console.log('e', newValue);
									setValue(`panel`, newValue);
								}}
								shouldUnregister={true}
								// defaultValue={panel ?? ''}
							/>
						</FormGroup>
					</Col>
				</Row>
			</FormProvider>
		);
	};

	const onSavePressed = async (all, e) => {
		setIsCreatingPanels(true)
		console.log('event: ', e);
		console.log('Saving Now', all);

		const panel = all;

		// dont need to send this off
		panel["images"] = panel.images_to_save;







		// return

		client
			.post('/generate_panels', {  job_type: 'PANELS_MULTI', job_data: panel })
			.then((resp) => {
				// console.log('data: ', response)
				NotificationManager.success('Panel has been created');
				setSelectedForm(null);
				const id = resp.data?.id;
				navigate(!!id ? `/viewworkflow/${id}` : "/viewworkflow");
				setIsCreatingPanels(false)
				return null;
			})
			.catch((e) => {
				console.error('ERROR: ', e.response.data);
				NotificationManager.error('There was an issue with this request');
				setIsCreatingPanels(false)
				return null;
			});
	};

	return (
		<>
			{createForm()}
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSavePressed)}>
					<Row className='justify-content-end mt-4'>
						<Col md={2}>
							<FormGroup>
								<Button
									className='w-100'
									onClick={() => {
										setSelectedForm(null);
									}}
									color='danger'
								>
									Cancel
								</Button>
							</FormGroup>
						</Col>
						<Col md={2}>
							<FormGroup>
								<SubmitButton pending={isCreatingPanels} disabled={false} className='w-100' onClick={() => {}} color='primary'>
									Create Panels
								</SubmitButton>
							</FormGroup>
						</Col>
					</Row>
				</form>
			</FormProvider>
		</>
	);
};

export default CreateNewPanelsMulti;
