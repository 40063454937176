import React, { useMemo } from 'react';
import { Row } from 'reactstrap';


const ImageGrid = ({ handleImageSelect, files, height, compareFn }) => {
  const onClick = (file, image, fileObject) => {
    handleImageSelect(file, image, fileObject)
  }

  const photos = useMemo(() => {
    return Object.values(files).filter(e => compareFn(e));
  }, [files, compareFn]);
  return (
    <Row className=' d-flex justify-content-center overflow-y-scroll' style={{ height: height }}>
      { photos ? (
        photos.map((f, idx) => {
          const image = window.URL.createObjectURL(f.file)
          return (
            <img
              key={idx}
              loading="lazy"
              className='rounded img-thumbnail m-2'
              style={{ width: '125px', height: '125px', objectFit: 'cover' }}
              src={image}
              role='button'
              onClick={() => { onClick(f.file, image, f) }}
            />);
        }
        )) : (
          <Row className=' d-flex justify-content-center '> <p>No Images Found</p> </Row>
        )
      }
    </Row>
  );
};


export default React.memo(ImageGrid);