import React, { useState, useMemo, useRef } from 'react';
import { FormGroup, Select, Input, SubmitButton } from 'components/react-hook-form';
import { useForm, FormProvider } from 'react-hook-form';
import { Row, Col, Button, Modal, ModalBody, ModalFooter, Label } from 'reactstrap';
import { useClient } from 'hooks';
import { useQuery } from 'react-query';
import { Page } from 'components/common';
import { toBase64 } from 'components/editors/utils';
import { NotificationManager } from 'react-notifications';
import { useNavigate } from 'react-router';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FetchingSpinner } from 'components/common';
import { ModalHeader } from 'components/ModalHeader';


const AddGroupModal = ({
    isOpen,
    handleToggle,
    addGroup,
    methods,
    groupOptions,
  }) => {
    const { getValues } = methods;

    return (
      <Modal isOpen={isOpen} toggle={handleToggle} >
        <ModalHeader
          toggle={handleToggle}
          charCode='close'
          close={
            <FontAwesomeIcon
              icon={faTimes}
              className=''
              onClick={handleToggle}
              role='button'
            />
          }
        >
        </ModalHeader>

        <ModalBody>         
            <FormGroup key={0} label='Group Name'>
            <Input
                type='text'
                className='form-control'
                name={'new_group'}
                rules={{
                    required: true,
                }}
            />
            </FormGroup>

        </ModalBody>
  
        <ModalFooter>
          <Row className='justify-content-end'>

            <Col>
              <Button
                color='primary'
                onClick={async ()=>{
                    addGroup({id:groupOptions.length, name:getValues('new_group')});
                    handleToggle()
                }}
              >
                Save
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }


export default AddGroupModal