import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useUserContext, useClient } from "hooks";

import { Page } from "components/common";

export const SettingsAndPresets = () => {
	const navigate = useNavigate();
	const client = useClient();
	const { user } = useUserContext();

	return (
		<Page>
			<h1> Settings And Presets </h1>
		</Page>
	);
};

export default SettingsAndPresets;
