import interact from "interactjs";

const gridSnap = 25;
const minSize = 50;

var dragzoneCallback = null;

function setDragzoneCallback(callback) {
	dragzoneCallback = callback;
}

interact('.composite_area')
	.resizable({
		// resize from all edges and corners
		edges: { left: true, right: true, bottom: true, top: true },

		modifiers: [
			// keep the edges inside the parent
			interact.modifiers.restrictEdges({
				outer: 'parent',
			}),


			// interact.modifiers.restrictSize({
			// 	min: { width: minSize, height: minSize },
			// }),
		],
		listeners: {
			end(e) {
				// console.log('Finished Resizing');
				const event = new Event('finishedResizing');
				e.target.dispatchEvent(event);
			},
		},

		inertia: false,
	})
	.draggable({
		// enable inertial throwing
		inertia: false,
		// keep the element within the area of it's parent
		modifiers: [
			interact.modifiers.restrictRect({
				restriction: 'parent',
				endOnly: false,
			}),
		],
		// enable autoScroll
		autoScroll: false,

		// call this function on every dragmove event
		onmove: dragMoveListener,
		// call this function on every dragend event
		// onend: function (e) {
		// 	console.log('Finished Dragging');
		// 	const event = new Event('finishedDragging');
		// 	document.dispatchEvent(event);
		// },
		listeners: {
			end(e) {
				// console.log('Finished Resizing');
				// const event = new Event('finishedResizing');
				const event = new Event('finishedDragging');
				e.target.dispatchEvent(event);
			},
		},
	})
	.on('resizemove', resizeMoveListener);




interact('.only-draggable-container')
	.draggable({
		// enable inertial throwing
		inertia: false,
		// keep the element within the area of it's parent
		modifiers: [
			interact.modifiers.restrictRect({
				restriction: 'parent',
				endOnly: false,
			}),
			// interact.modifiers.snap({
			//   targets: [interact.createSnapGrid({ x: gridSnap, y: gridSnap })],
			//   range: Infinity,
			//   relativePoints: [{ x: 0, y: 0 }]
			// })
		],
		// enable autoScroll
		autoScroll: false,

		// call this function on every dragmove event
		onmove: dragMoveListener,
		// call this function on every dragend event
		onend: function (e) {
			// console.log('Finished Dragging');
			const event = new Event('finishedDragging');
			e.target.dispatchEvent(event);
		},
	})







interact('.draggable-container')
	.resizable({
		// resize from all edges and corners
		edges: { left: true, right: true, bottom: true, top: true },

		modifiers: [
			// keep the edges inside the parent
			interact.modifiers.restrictEdges({
				outer: 'parent',
			}),
			// interact.modifiers.snap({
			// 	targets: [interact.createSnapGrid({ x: gridSnap, y: gridSnap })],
			// 	range: Infinity,
			// 	relativePoints: [{ x: 0, y: 0 }],
			// }),


			// minimum size
			interact.modifiers.restrictSize({
				min: { width: minSize, height: minSize },
			}),

		],
		listeners: {
			end(e) {
				// console.log('Finished Resizing');
				const event = new Event('finishedResizing');
				e.target.dispatchEvent(event);
			},
		},

		inertia: false,
	})
	.draggable({
		// enable inertial throwing
		inertia: false,
		// keep the element within the area of it's parent
		modifiers: [
			interact.modifiers.restrictRect({
				restriction: 'parent',
				endOnly: false,
			}),
			// interact.modifiers.snap({
			//   targets: [interact.createSnapGrid({ x: gridSnap, y: gridSnap })],
			//   range: Infinity,
			//   relativePoints: [{ x: 0, y: 0 }]
			// })
		],
		// enable autoScroll
		autoScroll: false,

		// call this function on every dragmove event
		onmove: dragMoveListener,
		// call this function on every dragend event
		onend: function (e) {
			// console.log('Finished Dragging');
			const event = new Event('finishedDragging');
			e.target.dispatchEvent(event);
		},
	})
	.on('resizemove', resizeMoveListener);


interact('.draggable-container-ratio-lock')
.resizable({
	// resize from all edges and corners
	edges: { left: true, right: true, bottom: true, top: true },

	modifiers: [
		// keep the edges inside the parent
		interact.modifiers.restrictEdges({
			outer: 'parent',
		}),
		// interact.modifiers.snap({
		// 	targets: [interact.createSnapGrid({ x: gridSnap, y: gridSnap })],
		// 	range: Infinity,
		// 	relativePoints: [{ x: 0, y: 0 }],
		// }),

		interact.modifiers.aspectRatio({
			// make sure the width is always double the height
			ratio: 'preserve',
		}),

		// minimum size
		interact.modifiers.restrictSize({
			min: { width: minSize, height: minSize },
		}),

	],
	listeners: {
		end(e) {
			// console.log('Finished Resizing');
			const event = new Event('finishedResizing');
			e.target.dispatchEvent(event);
		},
	},

	inertia: false,
})
.draggable({
	// enable inertial throwing
	inertia: false,
	// keep the element within the area of it's parent
	modifiers: [
		interact.modifiers.restrictRect({
			restriction: 'parent',
			endOnly: false,
		}),
		// interact.modifiers.snap({
		//   targets: [interact.createSnapGrid({ x: gridSnap, y: gridSnap })],
		//   range: Infinity,
		//   relativePoints: [{ x: 0, y: 0 }]
		// })
	],
	// enable autoScroll
	autoScroll: false,

	// call this function on every dragmove event
	onmove: dragMoveListener,
	// call this function on every dragend event
	onend: function (e) {
		// console.log('Finished Dragging');
		const event = new Event('finishedDragging');
		e.target.dispatchEvent(event);
	},
})
.on('resizemove', resizeMoveListener);

interact('.draggable-container-circle')
	.resizable({
		// resize from all edges and corners
		edges: { left: true, right: true, bottom: true, top: true },

		modifiers: [
			// keep the edges inside the parent
			interact.modifiers.restrictEdges({
				outer: 'parent',
			}),
			// interact.modifiers.snap({
			// 	targets: [interact.createSnapGrid({ x: gridSnap, y: gridSnap })],
			// 	range: Infinity,
			// 	relativePoints: [{ x: 0, y: 0 }],
			// }),

			// minimum size
			// interact.modifiers.restrictSize({
			// 	min: { width: minSize, height: minSize },
			// }),

			interact.modifiers.aspectRatio({
				// make sure the width is always double the height
				ratio: 1,
				// also restrict the size by nesting another modifier
				modifiers: [interact.modifiers.restrictSize({ max: 'parent' })],
			}),
		],
		listeners: {
			end(e) {
				// console.log('Finished Resizing');
				const event = new Event('finishedResizing');
				e.target.dispatchEvent(event);
			},
		},

		inertia: false,
	})
	.draggable({
		// enable inertial throwing
		inertia: false,
		// keep the element within the area of it's parent
		modifiers: [
			interact.modifiers.restrictRect({
				restriction: 'parent',
				endOnly: false,
			}),
			// interact.modifiers.snap({
			//   targets: [interact.createSnapGrid({ x: gridSnap, y: gridSnap })],
			//   range: Infinity,
			//   relativePoints: [{ x: 0, y: 0 }]
			// })
		],
		// enable autoScroll
		autoScroll: false,

		// call this function on every dragmove event
		onmove: dragMoveListener,
		// call this function on every dragend event
		onend: function (e) {
			// console.log('Finished Dragging');
			const event = new Event('finishedDragging');
			e.target.dispatchEvent(event);
		},
	})
	.on('resizemove', resizeMoveListener);







// Event listeners (Changed to handle scale in zoom screen)
function dragMoveListener(event, edges = {left: true, top: true, right: true, bottom: true}) {
	const {left, top, right, bottom} = edges
	var container = document.getElementById("editor-area");
    var scaleX = container.getBoundingClientRect().width / container.offsetWidth;
    var scaleY = container.getBoundingClientRect().height / container.offsetHeight;
	var target = event.target
	var x = target.getAttribute('data-x') ?? 0;
	var y = target.getAttribute('data-y') ?? 0;
	// keep the dragged position in the data-x/data-y attributes
	if (left || right) {
		x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx / scaleX
	}
	if (top || bottom) {
		y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy / scaleY
	}
    // translate the element
    target.style.webkitTransform =
    target.style.transform =
      'translate(' + x + 'px, ' + y + 'px)';
    target.setAttribute('data-x', Math.round(x));
    target.setAttribute('data-y', Math.round(y));
}



function resizeMoveListener(event)
{
    //get scale of drop container
    //see http://stackoverflow.com/questions/5603615/get-the-scale-value-of-an-element
    var container = document.getElementById("editor-area");
    var scaleX = container.getBoundingClientRect().width / container.offsetWidth;
    var scaleY = container.getBoundingClientRect().height / container.offsetHeight;

    //apply transform
    var target = event.target;
    var x = event.rect.width/scaleX;
    var y = event.rect.height/scaleY;

    //get data-x offsets of container
    const offset_left = target.getAttribute('data-x');
    const offset_top  = target.getAttribute('data-y');

    x = checkBounds(offset_left, x, container.getBoundingClientRect().width)
    y = checkBounds(offset_top, y, container.getBoundingClientRect().height)

    target.style.width  = x + 'px';
    target.style.height = y + 'px';

	const edges = event.edges
	// console.log(edges)
	const {left, top, right, bottom} = edges

	if (right && top) {
		dragMoveListener(event, {left:false, top, right:false, bottom});
	}
	else if (left && bottom) {
		dragMoveListener(event, {left, top:false, right, bottom:false});
	}

	else if ((left || top)) {
		// if(event.deltaRect.top != 0 || event.deltaRect.left != 0) {
			dragMoveListener(event, edges);
		// }
	}
}

function checkBounds(offset, dimension, limit)
{
    offset = (parseFloat(offset) || 0);
    if (offset + dimension > limit)
    {
        //larger than container
        dimension = limit - offset
    }
	// console.log(dimension)
    return dimension;
}