import { Button } from "reactstrap";
import React from "react";
import { useFormContext } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export const RSubmitButton = ({
  children,
  pending: pendingProp,
  disabled,
  ...props
}) => {
  const { formState } = useFormContext() ?? {};
  const { isSubmitting } = formState ?? {};
  const isPending = !!(isSubmitting || pendingProp);

  return (
    <Button
      color="primary"
      type="submit"
      disabled={!!(isPending || disabled)}
      {...props}
    >
      {isPending ? (
        <FontAwesomeIcon className="mr-2" icon={faSpinner} spin />
      ) : null}
      {children}
    </Button>
  );
};
