import React, { useState, useMemo, useRef } from 'react';
import { FormGroup, Select, Input, SubmitButton } from 'components/react-hook-form';
import { useForm, FormProvider } from 'react-hook-form';
import { Row, Col, Button, Modal, ModalBody, ModalFooter, Label } from 'reactstrap';
import { Pagination, Table, Search, useListQuery, useListTable, TableContextProvider } from '../../components/common/Tables';
import {ImageGrid} from '.'


const IndividualClassDetail = ({ methods, toggleClassDetail, isOpen, table, getRowProps, groupOptions, files }) => {
	const { watch, setValue, formState, reset, handleSubmit, unregister, register, getValues } = methods;


    const handleImageSelect = (file, image) => {
        console.log('image has been clicked')
    }

	return (
        <>
            {/* <Col xs={12} lg={6}> */}
                {/* Class Detail Table */}
                <TableContextProvider table={table}>
                    <Row form>
                        <Col>
                            <Search placeholder='Search' />
                        </Col>
                    </Row>
                    <Table getRowProps={getRowProps} />
                    <Pagination />
                </TableContextProvider>
            {/* </Col> */}
            {/* <Col xs={12} lg={6}>
                <ImageGrid
                    methods={methods}
                    handleImageSelect={handleImageSelect}
                    files={files ?? []}
                    height='100%'
                />
            </Col> */}
        </>
	);
};


export default IndividualClassDetail