import React, { useRef, useEffect, useState } from 'react';
import { RFormGroup as FormGroup, Select, Input } from 'components/react-hook-form';
import { FormProvider } from 'react-hook-form';
import { Row, Col, Button} from 'reactstrap';
import ColorPicker from 'components/editors/ColourPicker.jsx';


const alignment_options = [
	{ id: 'left', name: 'Left' },
	{ id: 'center', name: 'Center' },
	{ id: 'right', name: 'Right' },
];
const text_formats = [
	{ id: 'single', name: 'Single Line' },
	{ id: 'default', name: 'Two line' },
	{ id: 'none', name: 'No Text' },
];

const ordering_by_options = [
	{value: "firstname", name: "Alphabetical by firstname"},
	{value: "surname", name: "Alphabetical by surname"}
]

export function CompositeContextMenu({ selectedItem, methods, fontOptions }) {

	const { watch, getValues, setValue, formState, reset, handleSubmit, unregister, register } = methods;
	const elementNamePrefix = "design.boxes." + selectedItem.type + '_' + selectedItem.id;

	// console.log("FONT OPTIONS", fontOptions);

	return (
		<FormProvider {...methods}>
			{/* <FormGroup label='Background'>
            <div className='form-control p-0'>
                <ColorPicker className='w-100 h-100' setValue={setValue} hookFormTitle={'design.boxes.composite_area_1.background.colour'} />
            </div>
            </FormGroup> */}
			{/* <FormGroup label='Composite Border'>
                <Input
                    type='text'
                    className='form-control'
                    name={elementNamePrefix + '.name'}
                    placeholder='enter name'
                    rules={{
                        required: false,
                    }}
                />
            </FormGroup> */}
			<Row className='m-0'>
				<Col className='pl-0'>
					<FormGroup label='Width'>
						<input
							type='number'
							className='form-control'
							placeholder='0'


							{...register(elementNamePrefix + '.width', {
								required: false,
								value: selectedItem.element.style.width,
								valueAsNumber: true
							})}
							min={0}
							max={1}
							step={0.1}
							onChange={(e) => {
								// set element value to ratio of page
								const value = e.target.value;
								const yValue = parseFloat(selectedItem.element.getAttribute('data-y') ?? 0);

								const result = value * parseFloat(watch('full_width'));
								// console.log(result);
								selectedItem.element.style.width = result + 'px';

								// const rect = selectedItem.element.getBoundingClientRect();
								// const x = getValues(elementNamePrefix + '.centre.x');
								// const resultLoc = x * parseFloat(getValues('full_width')) - rect.width / 2;
								// selectedItem.element.setAttribute('data-x', parseFloat(resultLoc));
								// selectedItem.element.style.transform = `translate(${resultLoc}px, ${yValue}px) `;
								// selectedItem.element.style.WebkitTransform = `translate(${resultLoc}px, ${yValue}px) `;
								// Store value in the form
								setValue(elementNamePrefix + '.width', parseFloat(value));
							} } />
					</FormGroup>
				</Col>
				<Col className='pr-0'>
					<FormGroup label='Height'>
						<input
							type='number'
							className='form-control'
							placeholder='0'


							{...register(elementNamePrefix + '.height', {
								required: false,
								value: selectedItem.element.style.height,
								valueAsNumber: true
							})}
							min={0}
							max={1}
							step={0.1}
							onChange={(e) => {
								// set element value to ratio of page
								const value = e.target.value;
								const xValue = parseFloat(selectedItem.element.getAttribute('data-x') ?? 0);

								const result = value * parseFloat(watch('full_height'));
								// console.log(result);
								selectedItem.element.style.height = result + 'px';

								// const rect = selectedItem.element.getBoundingClientRect();
								// const y = getValues(elementNamePrefix + '.centre.y');
								// const resultLoc = y * parseFloat(getValues('full_height')) - rect.height / 2;
								// selectedItem.element.setAttribute('data-y', parseFloat(resultLoc));
								// selectedItem.element.style.transform = `translate(${xValue}px, ${resultLoc}px) `;
								// selectedItem.element.style.WebkitTransform = `translate(${xValue}px, ${resultLoc}px) `;
								// Store value in the form
								setValue(elementNamePrefix + '.height', parseFloat(value));
							} } />
					</FormGroup>
				</Col>
			</Row>

			{/* <FormGroup label='Text Style'>
                <Select
                    // isMulti
                    getOptionLabel={(data) => {
                        return data['name'];
                    }}
                    getOptionValue={(data) => {
                        return data['id'];
                    }}
                    options={text_formats}
                    {...register(elementNamePrefix + `.text_format`, {
                        rules: {
                            required: false,
                        },
                    })}
                    onChange={(newValue) => {
                        // console.log('e', newValue);
                        setValue(`text_style`, newValue);
                    }}
                    shouldUnregister={true}
                    // defaultValue={panel ?? ''}
                />
            </FormGroup> */}

			<Row>
				<Col className=''>
					<Button className='w-100' color='primary' onClick={()=>{
						setValue(elementNamePrefix + '.centre', {x:0.5, y:0.5})

						const rect = selectedItem.element.style
						console.log(rect)

						const x = (parseFloat(watch('full_width')) / 2)  - parseFloat(rect.width.replace('px', '') / 2)
						const y = (parseFloat(watch('full_height')) / 2) - parseFloat(rect.height.replace('px', '') / 2)

						console.log(x, y)

						selectedItem.element.setAttribute('data-y', y)
						selectedItem.element.setAttribute('data-x',  x)
					}}>
						Centre
					</Button>
				</Col>
			</Row>

			<Row className='m-0 mt-2 align-items-end'>
				<Col className='pl-0'>
					<FormGroup label='Horizontal Padding'>
						<Input
							type='number'
							className='form-control'
							name={elementNamePrefix + '.padding_x'}
							placeholder='0'
							rules={{
								required: false
							}}
							defaultValue={1}
							min={0}
							onChange={(e) => {
								// const value = e.target.value;
								// selectedItem.element.style.width = value + 'px';
							} } />
					</FormGroup>
				</Col>
				<Col className='pr-0'>
					<FormGroup label='Vertical Padding'>
						<Input
							type='number'
							className='form-control'
							name={elementNamePrefix + '.padding_y'}
							placeholder='0'
							rules={{
								required: false
							}}
							defaultValue={1}
							min={0}
							onChange={(e) => {
								// const value = e.target.value;
								// selectedItem.element.style.height = value + 'px';
							} } />
					</FormGroup>
				</Col>
			</Row>
			<Row className='m-0 align-items-end'>
				<Col className='pl-0'>
					<FormGroup label='Force Rows'>
						<Input
							type='number'
							className='form-control'
							name={elementNamePrefix + '.max_rows'}
							placeholder='0'
							rules={{
								required: false
							}}
							defaultValue={0}
							min={0} />
					</FormGroup>
				</Col>
				<Col className='pr-0'>
					<FormGroup label='Force Columns'>
						<Input
							type='number'
							className='form-control'
							name={elementNamePrefix + '.max_cols'}
							placeholder='0'
							rules={{
								required: false
							}}
							defaultValue={0}
							min={0} />
					</FormGroup>
				</Col>
			</Row>
			<FormGroup label='Photo Alignment'>
				<Select
					// isMulti
					getOptionLabel={(data) => {
						return data['name'];
					} }
					getOptionValue={(data) => {
						return data['id'];
					} }
					options={alignment_options}
					{...register('just_for_form.' + elementNamePrefix + `.alignment`, {
						rules: {
							required: false
						}
					})}
					onChange={(newValue) => {
						// console.log('e', newValue);
						setValue(elementNamePrefix + `.alignment`, newValue.name);
						setValue('just_for_form.' + elementNamePrefix + `.alignment`, newValue);
					} }
					shouldUnregister={true} />
			</FormGroup>

			<FormGroup label='Photo Label Font'>
				<Row>
					<Col className='pr-0'>
						{/* <ColorPicker className='w-100 h-100' /> */}
						<ColorPicker className='w-100 h-100' setValue={setValue} hookFormTitle={'unsure.colour'} />
					</Col>
					<Col md={8} className='pl-2'>
						<Select
							// isMulti
							getOptionLabel={(data) => {
								return data['name'];
							} }
							getOptionValue={(data) => {
								return data['id'];
							} }
							options={fontOptions}
							{...register('just_for_form.' + elementNamePrefix + `.label_font`, {
								rules: {
									required: false
								}
							})}
							onChange={(newValue) => {
								// console.log('e', newValue);
								setValue('just_for_form.' + elementNamePrefix + `.label_font`, newValue);
								setValue(elementNamePrefix + `.label_font`, newValue.value);

							} }
							shouldUnregister={true} />


					</Col>
				</Row>
			</FormGroup>

			<FormGroup label='Photo Border'>
				<Row>
					<Col className='pr-0'>
						<ColorPicker className='w-100 h-100' defaultValue={{ r: '255', g: '255', b: '255', a: '1' }} setValue={setValue} hookFormTitle={elementNamePrefix + '.border_colour'} />
					</Col>
					<Col md={8} className='pl-2'>
						<Input
							type='number'
							className='form-control'
							name={elementNamePrefix + '.border_width'}
							placeholder='0'
							rules={{
								required: false
							}}
							valueAsNumber={true}
							defaultValue={0}
							min={0} />
					</Col>
				</Row>
				<Row>
					<Col className='d-flex'>
						<input
							id='border_fill_checkbox'
							type="checkbox"
							checked={watch(elementNamePrefix + '.border_fill')}
							name={elementNamePrefix + '.border_fill'}
							onChange={(e) => {
								const checked = e.target.checked;
								// console.log('e', newValue);
								setValue(elementNamePrefix + '.border_fill', checked);
							} } />
						<label className='m-0 ml-2' htmlFor="border_fill_checkbox"><span class="fa fa-check" />Fill?</label>
					</Col>
				</Row>
			</FormGroup>

			<FormGroup label='Composite Border'>
				<Row>
					<Col className='pr-0'>
						<ColorPicker className='w-100 h-100' setValue={setValue} hookFormTitle={elementNamePrefix + '.comp_border_colour'} />
					</Col>
					<Col md={8} className='pl-2'>
						<Input
							type='number'
							className='form-control'
							name={elementNamePrefix + '.comp_border_width'}
							placeholder='0'
							rules={{
								required: false
							}}
							valueAsNumber={true}
							defaultValue={0}
							min={0} />
					</Col>
				</Row>
				<Row>
					<Col className='d-flex'>
						<input
							id='comp_border_fill_checkbox'
							type="checkbox"
							checked={watch(elementNamePrefix + '.comp_border_fill')}
							name={elementNamePrefix + '.comp_border_fill'}
							onChange={(e) => {
								const checked = e.target.checked;
								// console.log('e', newValue);
								setValue(elementNamePrefix + '.comp_border_fill', checked);
							} } />
						<label className='m-0 ml-2' htmlFor="comp_border_fill_checkbox"><span class="fa fa-check" />Fill?</label>
					</Col>
				</Row>
			</FormGroup>
			<FormGroup label='Ordering'>
				<Select
					// isMulti
					getOptionLabel={(data) => {
						return data['name'];
					} }
					getOptionValue={(data) => {
						return data['value'];
					} }
					options={ordering_by_options}
					{...register('just_for_form.' + elementNamePrefix + `.ordering_by`, {
						rules: {
							required: false
						}
					})}
					onChange={(newValue) => {
						// console.log('e', newValue);
						setValue(elementNamePrefix + `.ordering_by`, newValue.value);
						setValue('just_for_form.' + elementNamePrefix + `.ordering_by`, newValue);
					} }
					shouldUnregister={true} />
			</FormGroup>
			{/* <FormGroup label='Ordering'>
                <Select
                    getOptionLabel={(data) => {
                        return data['name'];
                    }}
                    getOptionValue={(data) => {
                        return data['id'];
                    }}
                    options={ordering_by_options}
                    {...register(elementNamePrefix + `.ordering_by`, {
                        rules: {
                            required: false,
                        },
                    })}
                    onChange={(newValue) => {
                        // console.log('e', newValue);
                        setValue(elementNamePrefix + `.ordering_by`, newValue);
                    }}
                    shouldUnregister={true}
                    // defaultValue={panel ?? ''}
                />
            </FormGroup> */}
			{/* <FormGroup
                htmlFor={elementNamePrefix + ".visible"}
                check
                label={"Visible"}
                name={elementNamePrefix + ".visible"}
                className="mb-2"
            >
                <Input
                type="checkbox"
                shouldUnregister
                id={elementNamePrefix + ".visible"}
                name={elementNamePrefix + ".visible"}
                checked={true}
                defaultValue={true}
                className="form-check-input"
                />
            </FormGroup> */}
		</FormProvider>
	);
}

export default CompositeContextMenu;
