import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormGroup, Select, SubmitButton, Input } from 'components/react-hook-form';
import { useForm, FormProvider, useFormContext, useFormState } from 'react-hook-form';
import { Row, Col, Button, Modal, ModalBody, ModalFooter, Label,ListGroup, ListGroupItem } from 'reactstrap';
import { ModalHeader } from 'components/ModalHeader';

import { useQuery, useQueryClient } from 'react-query';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import ResizePanel from "react-resize-panel";

import { toBase64 } from 'components/editors/utils';
import ColorPicker from 'components/editors/ColourPicker.jsx';

import { DraggableImage, ImageContainer, CompositeArea, DraggableGraphic, GraphicContextMenu, CompositeContextMenu } from 'components/editors';
import { useUserContext, useClient } from 'hooks';
import { NotificationManager } from 'react-notifications';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faBackspace, faChevronLeft, faChevronRight, faCoffee, faCompressArrowsAlt, faExpandArrowsAlt, faHouseUser, faLaptopHouse, faLock, faMinus, faMinusCircle, faPlusCircle, faQuestion, faQuestionCircle, faRecycle, faUnlock, faUserLock } from '@fortawesome/free-solid-svg-icons';




export const EditorArea = ({ id, setSelectedItem, selectedItem, eventFunction, backgroundImage, methods, pageBackgroundColor, editorStyle,  defaultWidth = 2500, defaultHeight=1700, initialScale=0.3, ...props}) => {

    const zoomButton = 'mr-1';

	const [helpModal, setHelpModal] = useState(false);
    const toggleHelpModal = () => setHelpModal(!helpModal);

    const [panLock, setPanLock] = useState(false);

    const [showMenu, setShowMenu] = useState(true);
    const toggleMenu= () => setShowMenu(!showMenu);

	useEffect(() => {
		const event = new Event('toggleLock');
		event.flag = panLock
		document.dispatchEvent(event);
	}, [panLock]);


	const { watch, getValues, setValue, formState, reset, handleSubmit, unregister, register } = methods;


	return (
            <>
            <TransformWrapper wheel={{ touchPadDisabled: true }} panning={{ disabled: !panLock }} minScale={0.1} initialPositionY={300} initialPositionX={400} maxScale={2} limitToBounds={false} initialScale={initialScale}>
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <React.Fragment>
                        <div className='tools p-2 bg-light border-b border-l border-t right-0 position-absolute z-10' style={{borderBottomLeftRadius: "6px"}}>
                                <Button color="" className={zoomButton} onClick={toggleMenu}>
                                	<FontAwesomeIcon icon={showMenu ? faChevronRight : faChevronLeft} size="lg" />
                                </Button>
								{showMenu && (
									<>
										<Button color="" className={zoomButton} onClick={() => zoomIn(0.1)}>
											<FontAwesomeIcon icon={faExpandArrowsAlt} size="lg" />
										</Button>
										<Button color="" className={zoomButton} onClick={() => zoomOut(0.1)}>
											<FontAwesomeIcon icon={faCompressArrowsAlt} size="lg" />
										</Button>
										<Button color="" className={zoomButton} onClick={() => resetTransform()}>
											<FontAwesomeIcon icon={faRecycle} size="lg" />
										</Button>
										<Button color="" className={zoomButton} onClick={() => setPanLock(!panLock)}>
											<FontAwesomeIcon icon={!panLock ? faUnlock : faLock} size="lg" />
										</Button>
										<Button color="" className={zoomButton} onClick={toggleHelpModal}>
											<FontAwesomeIcon icon={faQuestionCircle} size="lg" />
										</Button>
								</>
								)}
                        </div>
                        <TransformComponent wrapperClass='w-100 h-screen border-b border-t' contentClass='w-100 h-100'>
                            <div id='editor-area' className='composite-editor-area drag-area editor-dropZone pt-10 graph-paper d-flex align-items-center justify-content-center'>
                                <div id='page' className='align-middle shadow shadow-lg position-absolute ' style={{ width: defaultWidth+'px', height: defaultHeight+'px', backgroundColor: pageBackgroundColor, backgroundImage: `url("data:image/jpeg;base64, ${backgroundImage}")`, backgroundSize: "100% 100%", backgroundRepeat: 'no-repeat', }}>
                                    {props.children}
                                </div>
                            </div>
                        </TransformComponent>
                    </React.Fragment>
                )}
            </TransformWrapper>
            {/* Help Modal */}
            <div>
					<Modal size='lg' isOpen={helpModal}>
						<ModalHeader>Help</ModalHeader>

						<ModalBody>

							<ListGroup>

								<ListGroupItem>
									<Row className="m-0">
										<Col className="p-0 d-flex justify-content-center" xs={1}>
											<FontAwesomeIcon icon={faExpandArrowsAlt} size="lg" />
										</Col>
										<Col>
											Zoom In
										</Col>
									</Row>
								</ListGroupItem>
								<ListGroupItem>
									<Row className="m-0">
										<Col className="p-0 d-flex justify-content-center" xs={1}>
											<FontAwesomeIcon icon={faCompressArrowsAlt} size="lg" />
										</Col>
										<Col>
										Zoom Out
										</Col>
									</Row>
								</ListGroupItem>
								<ListGroupItem>
									<Row className="m-0">
										<Col className="p-0 d-flex justify-content-center" xs={1}>
											<FontAwesomeIcon icon={faRecycle} size="lg" />
										</Col>
										<Col>
											Reset View
										</Col>
									</Row>
								</ListGroupItem>
								<ListGroupItem>
									<Row className="m-0 ">
										<Col className="p-0 flex justify-content-center align-items-center" xs={1}>
											<FontAwesomeIcon icon={faLock} size="lg" className='mr-3'/>
											<FontAwesomeIcon icon={faUnlock} size="lg" />
										</Col>
										<Col className="d-flex align-items-center">
											Lock and Unlock the ability to pan around the editor
											<br/>
											(This will prevent you from dragging graphics around while unlocked)
										</Col>
									</Row>
								</ListGroupItem>
                                <ListGroupItem>
									<Row className="m-0 ">
										<Col className="p-0 d-flex justify-content-center" xs={1}>
											<FontAwesomeIcon icon={faChevronLeft} size="lg" className='mr-3' />
											{/* <br/> */}
											<FontAwesomeIcon icon={faChevronRight} size="lg" />
										</Col>
										<Col className="d-flex align-items-center">
											Show and hide the menu
										</Col>
									</Row>
								</ListGroupItem>




							</ListGroup>
						</ModalBody>

						<ModalFooter>
							<Row>
								<Col>
									<Button onClick={toggleHelpModal}>Close</Button>
								</Col>
							</Row>
						</ModalFooter>
					</Modal>
				</div>
            </>
	);
};

export default EditorArea;
