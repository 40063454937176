import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import ErrorBoundary from "./components/ErrorBoundary";
import { NotificationContainer } from "react-notifications";
import { RecoilRoot, useTransactionObservation_UNSTABLE } from "recoil";
import { persistedAtoms } from "./atoms";
import { UserAtomEffect } from "./atomEffects";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import "assets/fonts/dm-sans/stylesheet.css";
//import "assets/css/bootstrap.min.css";
import "assets/css/select2.min.css";
import "assets/css/style.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

const PersistenceObserver = () => {
  useTransactionObservation_UNSTABLE(({ atomValues, modifiedAtoms }) => {
    for (const modifiedAtom of modifiedAtoms) {
      let value = atomValues.get(modifiedAtom);
      if (value === undefined) {
        localStorage.removeItem(modifiedAtom);
      } else {
        localStorage.setItem(modifiedAtom, JSON.stringify(value));
      }
    }
  });
  return null;
};

// console.log('process.env.USE_SENTRY', process.env.USE_SENTRY);
if (process.env.USE_SENTRY == 'true' || process.env.USE_SENTRY == 'yes') {
  Sentry.init({
    dsn: "https://7601b830c38536782e754a6556be39d2@o4506536462385152.ingest.sentry.io/4506536616984576", // hardcoded to prod?
    integrations: [new BrowserTracing(), new Sentry.Replay()],
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
    tunnel: "/api/sentry_tunnel"
  });
}

export const App = () => {
  return (
    <Suspense fallback={null}>
      <RecoilRoot
        initializeState={({ set }) => {
          for (const atom of persistedAtoms) {
            const key = atom.key.replace(/__withFallback$/, "");

            if (localStorage.hasOwnProperty(key)) {
              try {
                const value = JSON.parse(localStorage[key]);
                set(atom, value);
              } catch (ex) {
                console.error(ex);
              }
            }
          }
        }}
      >
        <PersistenceObserver />
          <NotificationContainer />
          <BrowserRouter>
            <UserAtomEffect />
            <ErrorBoundary>
              <Suspense fallback={null}>
                <Routes />
              </Suspense>
            </ErrorBoundary>
          </BrowserRouter>
      </RecoilRoot>
    </Suspense>
  );
};

export default App;
