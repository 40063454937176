import React, { useState } from "react";
import {
  Route as BaseRoute,
  Routes,
  Navigate
} from "react-router-dom";
import { useNavigate } from "react-router";
import {
  Login,
  Signup,
  Home,
  GenComposites,
  StartNewJob,
  GenerateProofs, // Replaces StartNewJob+GenProofCards
  CreateNewPanels,
  GenProofCards,
  // GenerateProofCards,
  ProcessStaffImageJobs,
  ProcessOrders,
  ViewWorkflow,
  CreateNewPrintPacks,
  PrintPanels,
  AssignPrintPacksToProofCards,
  CreateNewProofCard,
  AutoImportSystemPresets,
  PhotographerDownloadJobData,
  PhotographerStartJob,
  PhotographerUploadJobPhotos,
  CreateNewStaffPhotoTemplate,
  ViewPhotographerJobs,
} from './Pages';


import { Page, Tutorial } from "components/common";

import { useUserContext, useClient } from "hooks";
import { useTranslation } from "react-i18next";
import AuthenticatedBase from "./AuthenticatedBase";


const Forbidden = () => {
  return (
    <AuthenticatedBase>
      <Page title="Unauthorised">
        You do not have permission to view this page
      </Page>
    </AuthenticatedBase>
  );
};

export const NotFound = () => {
  return (
    <Page title="Not found">
      The page you have requested could not be found
    </Page>
  );
};

const Route = ({
  path,
  exact,
  test,
  checkUser,
  element,
  children,
}) => {
  const { user } = useUserContext();

  if (checkUser && !user) {
    return <Navigate to='/login' />;
  } else if (test !== undefined && !test) {
    return <Forbidden />;
  } else if ((window.location.pathname === "/" || window.location.pathname === "") && user) {
    // This redirect is actually needed
    return <Navigate to='/home' />;
  }
  return (
    <BaseRoute
      path={path}
      exact={exact}
      element={element}
      children={children}
    />
  );
};

const AuthenticatedRoute = ({ element, ...props }) => {
  return (
    <Route
      checkUser
      element={<AuthenticatedBase>{element}</AuthenticatedBase>}
      {...props}
    />
  );
};

export const AppRoutes = () => {
  const { user } = useUserContext();

  const isPhotographer = user ? user.is_photographer : false;
  const isAdminOrStaff = user ? user.is_superuser || user.is_staff : false;
  return (
    <Routes>

      <Route path='login' element={<Login />} />
      <Route path='signup' element={<Signup />} />
      <Route path='/' element={<Navigate to="home"/>} />
      {isAdminOrStaff && <AuthenticatedRoute path="home" element={<ViewPhotographerJobs />} test={isAdminOrStaff} />}
      {isPhotographer && <AuthenticatedRoute path="home" element={<PhotographerStartJob />} test={isPhotographer} />}
      <Route path="home" element={<Login />} />

      {/* Pages */}
      <AuthenticatedRoute test={isAdminOrStaff} path='startnewjob' element={<StartNewJob />} />
      <AuthenticatedRoute test={isAdminOrStaff} path='startnewjob/:job_id' element={<StartNewJob />} />
      <AuthenticatedRoute test={isAdminOrStaff} path='generateproofs' element={<GenerateProofs />} />
      <AuthenticatedRoute test={isAdminOrStaff} path='generateproofs/:job_id' element={<GenerateProofs />} />
      <AuthenticatedRoute test={isAdminOrStaff} path='createnewpanels' element={<CreateNewPanels />} />
      <AuthenticatedRoute test={isAdminOrStaff} path='generatecomposites' element={<GenComposites />} />
      <AuthenticatedRoute test={isAdminOrStaff} path='generateproofcards' element={<GenProofCards />} />
      {/* <AuthenticatedRoute test={isAdminOrStaff} path='newgenerateproofcards' element={<GenerateProofCards />} /> */}
      <AuthenticatedRoute test={isAdminOrStaff} path='generateproofcards/:job_id' element={<GenProofCards />} />
      {/* <AuthenticatedRoute test={isAdminOrStaff} path='newgenerateproofcards/:job_id' element={<GenerateProofCards />} /> */}
      <AuthenticatedRoute test={isAdminOrStaff} path='processstaffimagejobs' element={<ProcessStaffImageJobs />} />
      <AuthenticatedRoute test={isAdminOrStaff} path='processorders' element={<ProcessOrders />} />
      <AuthenticatedRoute test={isAdminOrStaff} path='viewworkflow' element={<ViewWorkflow />} />
      <AuthenticatedRoute test={isAdminOrStaff} path='viewworkflow/:id' element={<ViewWorkflow />} />

      {/* Settings and presets */}
      <AuthenticatedRoute test={isAdminOrStaff} path='createnewprintpacks' element={<CreateNewPrintPacks />} />
      <AuthenticatedRoute test={isAdminOrStaff} path='printpanels' element={<PrintPanels />} />
      <AuthenticatedRoute test={isAdminOrStaff} path='assignprintpackstoproofcard' element={<AssignPrintPacksToProofCards />} />
      <AuthenticatedRoute test={isAdminOrStaff} path='createnewproofcard' element={<CreateNewProofCard />} />
      <AuthenticatedRoute test={isAdminOrStaff} path='autoimportsystempresets' element={<AutoImportSystemPresets />} />
      <AuthenticatedRoute test={isAdminOrStaff} path='createstaffphototemplate' element={<CreateNewStaffPhotoTemplate />} />
      <AuthenticatedRoute test={isAdminOrStaff} path='createstaffphototemplate/:id' element={<CreateNewStaffPhotoTemplate />} />

      {/* Photographer */}
      <AuthenticatedRoute test={isAdminOrStaff} path='photographerdownloadjobdata' element={<PhotographerDownloadJobData />} />
      <AuthenticatedRoute test={isAdminOrStaff} path='photographeruploadjobdata' element={<PhotographerUploadJobPhotos />} />
      {/* <AuthenticatedRoute test={isAdminOrStaff || isPhotographer} path='photographerstartjob' element={<PhotographerStartJob />} /> */}
      <AuthenticatedRoute test={isAdminOrStaff || isPhotographer} path='viewphotographerjob' element={<ViewPhotographerJobs />} />
      <AuthenticatedRoute test={isAdminOrStaff || isPhotographer} path='viewphotographerjob/:id' element={<ViewPhotographerJobs />} />

      <Route path="*" element={<NotFound />} />

    </Routes>
  );
};

export default AppRoutes;
