import React, { useCallback, useState, useEffect } from 'react';
import { RFormGroup as FormGroup, RichSelect as Select, Input, SubmitButton } from 'components/react-hook-form';
import { useForm, FormProvider } from 'react-hook-form';
import { Row, Col, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Page } from 'components/common';
import { toBase64 } from 'components/editors/utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FetchingSpinner } from 'components/common';
import { ModalHeader } from 'components/ModalHeader';

import { ImageGrid } from '.'


const NO_DATA_PHOTO_TYPE = "IndividualNoData";
const INDIVIDUAL = "Individual";


const StudentDetailModal = ({
  isOpen,
  handleToggle,
  loadingFullImage,
  selectedStudent,
  setSelectedStudent,
  data,
  setData,
  groupOptions,
  filesArray,
  setFilesArray,
  form,
  emptyObject
}) => {


  const { reset, handleSubmit } = form;

  const [chosenImage, setChosenImage] = useState(null);

  const handleImageSelect = useCallback((file, image, fileObject) => setChosenImage({ file: file, image: image }),
    [setChosenImage]);

  const compareFn = useCallback(
    (x) => x.assigned == null, []
  )


  const deallocate = () => {
    const image = selectedStudent?.photo;
    const studentIndex = data.findIndex(x => x.id === selectedStudent?.id);
    setData(data => {
      let x = data[studentIndex];
      x['photo'] = null;
      x['photo_name'] = null;
      x['encoded_photo'] = null;
      data[studentIndex] = x;
      return data;
    });
    setFilesArray(f => {
      f[image.file.webkitRelativePath].assigned = null;
      f[image.file.webkitRelativePath].individual = false;
      f[image.file.webkitRelativePath].staff = false;
      f[image.file.webkitRelativePath].siblings = false;
      f[image.file.webkitRelativePath].group = false;
      return { ...f };
    });
    setChosenImage(null);
    let student = selectedStudent;
    student.photo = null;
    setSelectedStudent({ ...student });
  }

  const closeModal = () => {
    handleToggle();
    setChosenImage(null);
  };


  const onSubmit = async (x) => {
    x.class_name = x.class_name.name;
    x.id = x.student_id;
    const img = await (chosenImage ? toBase64(chosenImage.file) : null);
    if (!selectedStudent) {
      // add new student
      setData(students => {
        let newStudent = {
          ...x,
          photo_type: NO_DATA_PHOTO_TYPE,
          assigned: null,
          absent: false,
          other: [],
        };
        if (chosenImage) {
          newStudent.photo = chosenImage;
          newStudent.photo_name = chosenImage.file.name;
          newStudent.encoded_photo = img;
        }
        return [...students, newStudent];
      })
    }
    else {
      const idx = data.findIndex(e => e.id === selectedStudent.id);
      setData(students => {
        let student = students[idx];
        student.absent = x.absent;
        if (chosenImage) {
          student.photo = chosenImage;
          student.photo_name = chosenImage.file.name;
          student.encoded_photo = img;
          student.photo_type = INDIVIDUAL;
        }
        students[idx] = student;
        return [...students];
      })


    }
    if (chosenImage) {
      setFilesArray(obj => {
        let photo = obj[chosenImage.file.webkitRelativePath];
        photo.assigned = x.student_id;
        photo.individual = true;
        obj[chosenImage.file.webkitRelativePath] = photo;
        return { ...obj };
      });
    }
    setSelectedStudent(null);
    reset(emptyObject);
    closeModal();
  }
  return (

    <Modal isOpen={isOpen} toggle={closeModal} fade={false} className='wide-modal'>
      <ModalHeader
        toggle={closeModal}
        close={
          <FontAwesomeIcon
            icon={faTimes}
            className=''
            onClick={closeModal}
            role='button'
          />
        }
      >
        {!!selectedStudent && selectedStudent?.first_name + " " + selectedStudent?.surname}
      </ModalHeader>

      <ModalBody>

        <FetchingSpinner className="mx-2" isFetching={loadingFullImage} />
        <Row>
          <Col className="border-right" style={{ maxWidth: '400px', }}>
            <FormGroup name="student_id" label='ID'>
              <Input
                type='text'
                className='form-control'
                name="student_id"
                placeholder="Student's ID"
                rules={{ required: true, validate: v => !!v }}
                disabled={selectedStudent}
              />
            </FormGroup>
            <FormGroup key={1} name="first_name" label='Forename'>
              <Input
                type='text'
                className='form-control'
                name="first_name"
                rules={{ required: true, validate: v => !!v }}
                // validate: (v) => selectedStudent ? true : !v}}
                disabled={selectedStudent}
              />
            </FormGroup>
            <FormGroup key={2} name="surname" label='Surname'>
              <Input
                type='text'
                className='form-control'
                name="surname"
                rules={{ required: true, validate: v => !!v }}
                disabled={selectedStudent}
              />
            </FormGroup>
            <FormGroup key={3} name="class_name" label='Group'>
              <Select
                name="class_name"
                options={groupOptions}
                labelKey="name"
                valueKey="id"
                rules={{ required: true, validate: v => !!v }}
                isDisabled={selectedStudent}
              />
            </FormGroup>

            {/* <FormGroup key={4}>
              <Input name='absent' type='checkbox' rules={{ required: false }} /> Absent
            </FormGroup> */}

          </Col>

          <Col>
            {/* Image Selection here */}
            <Row className=' d-flex justify-content-center '>
              {/* Image Preview here */}
              <div className="relative">
                <img
                  className='rounded img-thumbnail m-2'
                  loading="lazy"
                  style={{ width: '225px', height: '225px', objectFit: 'cover' }}
                  src={selectedStudent?.photo?.image ? selectedStudent?.photo?.image : chosenImage?.image || null}
                  role="button"

                />
                {selectedStudent !== null && selectedStudent?.photo?.image && (
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    className='mr-2 fx-lg add-button-center-circle absolute top-4 right-5 cursor-pointer'
                    onClick={deallocate}
                  />)}
              </div>
            </Row>
            <ImageGrid
              handleImageSelect={handleImageSelect}
              files={filesArray}
              height="350px"
              compareFn={compareFn}
            />
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter>
        <Row className='justify-content-end'>
          <Col>
            <SubmitButton color="primary" onClick={handleSubmit((data) => onSubmit(data, null))}>
              Save
            </SubmitButton>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
}


export default StudentDetailModal