import React, {useEffect, useRef, useState } from 'react';
import { determineColor } from 'components/editors/utils';

export const GraphicTypes = {
	STAFF_TEMPLATE_GRAPHIC: 'STAFF_TEMPLATE_GRAPHIC',
	STAFF_TEMPLATE_PHOTO: 'STAFF_TEMPLATE_PHOTO',
	PROOF_CARD_GRAPHIC: 'PROOF_CARD_GRAPHIC',
	PROOF_CARD_POSE: 'PROOF_CARD_POSE',
	PRINT_PANEL: 'PRINT_PANEL',
	COMPOSITE_GRAPHIC: 'COMPOSITE_GRAPHIC',
};

export const DraggableGraphic = ({ id, type, setSelectedItem, values, methods, scaleValue, fonts, startDark = false, draggableOnly = false, graphicPrefix = 'graphic_', aspectLock = false, initialZoom = 0.3 }) => {
	//  ------------------------------------------------------------------------------------------------
	//  Setup props
	//  ------------------------------------------------------------------------------------------------
	const node = useRef();
	const graphicId = 'design.boxes.' + id;
	const [zIndexVar, setZIndex] = useState(0);
	const [firstMount, setFirstMount] = useState(true);

	const { watch, getValues} = methods;
	const [borderCol, setBorderCol] = useState(determineColor(!startDark ? [255, 255, 255] : [0, 0, 0]));

	const [fullDragLocked, setFullDragLocked] = useState(false);
	function toggleLock(e){
		setFullDragLocked(e.flag)
	}

	const nameWatcher = watch('name')

	useEffect(() => {
	  setFirstMount(true)
	}, [nameWatcher])
	


	//  ------------------------------------------------------------------------------------------------
	//  on Click handlers
	//  ------------------------------------------------------------------------------------------------
	useEffect(() => {
		// add when mounted
		document.addEventListener('mousedown', handleClick); // return function to be called when unmounted
		document.addEventListener('toggleLock', toggleLock); // return function to be called when unmounted

		return () => {
			document.removeEventListener('mousedown', handleClick);
			document.removeEventListener('toggleLock', toggleLock);

		};
	}, []);

	const handleClick = (e) => {
		const result = parseInt(e.target.id.replace(graphicPrefix, ''));
		if (node && node.current && node.current.contains(e.target)) {
			setBorderCol(determineColor(!!getValues('background.colour') ? getValues('background.colour') : [0, 0, 0], true));
			setZIndex(100);
			setSelectedItem({ id: result, type: 'draggable_graphic', element: e.target, value: values });
		} else {
			setBorderCol(determineColor(!!getValues('background.colour') ? getValues('background.colour') : [0, 0, 0], false));
			setZIndex(0);
		}
	};

	//  ------------------------------------------------------------------------------------------------
	//  Styling objects
	//  ------------------------------------------------------------------------------------------------

	const imageStyles = {
		pointerEvents: 'none',
	}

	var styles = {
		...imageStyles,
		backgroundSize: '100% 100%',
		backgroundRepeat: 'no-repeat',
		outline: '10px solid ' + borderCol,
		backgroundColor: 'rgba(0,0, 0,0.3)',
		position: 'relative',
	};

	const containerStyles = {
		backgroundRepeat: 'no-repeat',
		backgroundSize: '100% 100%',
		padding: '10px',
		position: 'absolute',
	};

	const triangleContainerStyles = {
		position: 'absolute',
		top: '0',
		left: '0',
		width:"100%",
		height:"100%",
		overflow: 'visible'
	}

	const triangleStyles = {
		fill: values?.colour,

	}

	const textStyles = {
		color: getValues(graphicId + '.font_colour') ?? 'white',
	};



	//  ------------------------------------------------------------------------------------------------
	//  location variables
	//  ------------------------------------------------------------------------------------------------

	var x;
	var y;
	var width;
	var height;

	var styleX;
	var styleY;

	function addOneToId(){
		const arr = id.split('_')
		return  'Graphic ' + (parseInt(arr[1]) + 1)
	}

	var boxString = values.Text ?? values.text ?? addOneToId();

	if (boxString == id.replace("_"," ")) {
		textStyles['textTransform'] =  "capitalize";
	}

	useEffect(() => {
		if (firstMount) {
			setDataValues()
		}
	},);

	//  ------------------------------------------------------------------------------------------------
	//  Helper functions
	//  ------------------------------------------------------------------------------------------------

	const setDataValues = () => {
		setTimeout(function(){
			const element = document.getElementById(id);
			if (!element) {
				return
			}
			const transform = getTranslateXY(element)
			element.setAttribute('data-x', transform.x);
			element.setAttribute('data-y', transform.y);
			setFirstMount(false)
	   },500); //delay is in milliseconds
	}

	function getTranslateXY(element) {
		const style = window.getComputedStyle(element)
		const matrix = new DOMMatrixReadOnly(style.transform)
		return {
			x: matrix.m41,
			y: matrix.m42
		}
	}

	function measureTextWidth(str, fontSize = 10) {
		const widths = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0.2796875,0.2765625,0.3546875,0.5546875,0.5546875,0.8890625,0.665625,0.190625,0.3328125,0.3328125,0.3890625,0.5828125,0.2765625,0.3328125,0.2765625,0.3015625,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.2765625,0.2765625,0.584375,0.5828125,0.584375,0.5546875,1.0140625,0.665625,0.665625,0.721875,0.721875,0.665625,0.609375,0.7765625,0.721875,0.2765625,0.5,0.665625,0.5546875,0.8328125,0.721875,0.7765625,0.665625,0.7765625,0.721875,0.665625,0.609375,0.721875,0.665625,0.94375,0.665625,0.665625,0.609375,0.2765625,0.3546875,0.2765625,0.4765625,0.5546875,0.3328125,0.5546875,0.5546875,0.5,0.5546875,0.5546875,0.2765625,0.5546875,0.5546875,0.221875,0.240625,0.5,0.221875,0.8328125,0.5546875,0.5546875,0.5546875,0.5546875,0.3328125,0.5,0.2765625,0.5546875,0.5,0.721875,0.5,0.5,0.5,0.3546875,0.259375,0.353125,0.5890625]
		const avg = 0.5279276315789471
		return str
		  .split('')
		  .map(c => c.charCodeAt(0) < widths.length ? widths[c.charCodeAt(0)] : avg)
		  .reduce((cur, acc) => acc + cur) * fontSize
	}

	function measureTextHeight(fontSizeFace, text) {
		var width = 1500;
		var height = 500;
	  
		var canvas = document.createElement("canvas");
		canvas.width = width;
		canvas.height = height;
		var ctx=canvas.getContext("2d");
		ctx.save();
		ctx.font=fontSizeFace;
		ctx.clearRect(0,0,width,height);
		ctx.fillText(text, parseInt(width * 0.1, 10), parseInt(height / 2, 10));
		ctx.restore();
		document.body.appendChild(canvas);
		var data = ctx.getImageData(0,0,width,height).data;
	  
	  
		var topMost = false;
		var bottomMost = false;
		var leftMost = false;
		var rightMost = false;
		for(var x=0; x<width; x++) {
		  for(var y=0; (y<height) && (!leftMost); y++) {
			//console.log("x: %s y: %s index: %s", x,y, getAlphaIndexForCoordinates(x,y,width,height).toString() );
			if(data[getAlphaIndexForCoordinates(x,y,width,height)] != 0) {
			  leftMost = x;
			}
		  }
		}
		for(var y=0; y<height; y++) {
		  for(var x=0; (x<width) && (!topMost); x++) {
			//console.log("x: %s y: %s index: %s", x,y, getAlphaIndexForCoordinates(x,y,width,height).toString() );
			if(data[getAlphaIndexForCoordinates(x,y,width,height)] != 0) {
			  topMost = y;
			}
		  }
		}
		for(var x=width-1; x>=0; x--) {
		  for(var y=height-1; (y>=0) && (!rightMost); y--) {
			//console.log("x: %s y: %s index: %s", x,y, getAlphaIndexForCoordinates(x,y,width,height).toString() );
			if(data[getAlphaIndexForCoordinates(x,y,width,height)] != 0) {
			  rightMost = x;
			}
		  }
		}
		for(var y=height-1; y>=0; y--) {
		  for(var x=width-1; (x>=0) && (!bottomMost); x--) {
			//console.log("x: %s y: %s index: %s", x,y, getAlphaIndexForCoordinates(x,y,width,height).toString() );
			if(data[getAlphaIndexForCoordinates(x,y,width,height)] != 0) {
			  bottomMost = y;
			}
		  }
		}
		document.body.removeChild(canvas);
		return ({
		   width: (rightMost - leftMost) + 1
		  ,height: (bottomMost - topMost) + 1
		});
	  }
	  function getAlphaIndexForCoordinates(x,y,width,height) {
		return (((width*4*y)+4*x)+3);
	  }

	function scaleUp() {
		width = width * scaleValue;
		height = height * scaleValue;
		// if (firstMount) {
		styleX = styleX * scaleValue;
		styleY = styleY * scaleValue;
		// }
	}

	function forceText(){
		values.Text = values.text;
		values.is_text = true;
	}

	function setBaseLocationValues(xKey, yKey, pageXKey, pageYKey){
		height = values.height;
		width = values.width;
		styleX = values[xKey] * parseFloat(getValues(pageXKey));
		styleY = values[yKey] * parseFloat(getValues(pageYKey));
	}

	function getContrastYIQ(hexcolor){
		if (!hexcolor) return 'black'
		hexcolor = hexcolor.replace("#", "");
		var r = parseInt(hexcolor.substr(0,2),16);
		var g = parseInt(hexcolor.substr(2,2),16);
		var b = parseInt(hexcolor.substr(4,2),16);
		var yiq = ((r*299)+(g*587)+(b*114))/1000;
		return (yiq >= 128) ? 'black' : 'white';
	}

	//  ------------------------------------------------------------------------------------------------
	//  Page specific functions
	//  ------------------------------------------------------------------------------------------------

	function handleStaffTemplatePhotoAndGraphic() {
		const isVisible = values.active ?? values.visible ?? false
		containerStyles['visibility'] = isVisible ? 'visible' : 'hidden'
		if (isVisible) {
			setBaseLocationValues('left', 'top', 'layout.size_x', 'layout.size_y');
			scaleUp();
			// Change the thickness of the border to the starting zoom level
			const thickness = (10 / (initialZoom / 0.3)).toString()
			styles['outline'] = thickness + 'px solid ' + borderCol
			containerStyles['padding'] = thickness + 'px'
			if (type == GraphicTypes.STAFF_TEMPLATE_GRAPHIC) {
				// base the size of the box based off the text size
				// const textHeight = measureTextWidth('M', watch('layout.font_size'))
				// const textWidth = measureTextWidth(values.text ?? values.Text,  watch('layout.font_size'))
				// height = (textHeight * 20) / parseFloat(watch('layout.size_y'));
				// width = (textWidth * 10) / parseFloat(watch('layout.size_x'));
				// textStyles['fontSize'] = ((parseFloat(watch('layout.font_size')) ?? 75) * 1) + "px"

				// THIS IS THE PYTHON CODE WE HAVE TO BE COMPATABLE WITH

				// align = box.get('align', CENTRE)
				// context.set_font_size(self.font_size)
				// extents = context.text_extents(box['text'])
				// width = extents[2] / self.paper_bounds['width']
				// extents = context.text_extents('Ag')
				// height = extents[3] / self.paper_bounds['height']
				// box['width'] = width
				// box['height_ratio'] = height / width
				// rect = (
				//     box['left'] * self.paper_bounds['width']  + self.paper_bounds['left'],
				//     box['top'] * self.paper_bounds['height'] + self.paper_bounds['top'],
				//     width * self.paper_bounds['width'],
				//     height * self.paper_bounds['height'],
				// )

				var fontSize = watch('layout.font_size')
				// const textHeight = measureTextWidth('Ag', fontSize)
				// const textWidth = measureTextWidth(values.text ?? values.Text,  fontSize)
				const resWidth = measureTextHeight(`${fontSize}pt Arial`, values.text ?? values.Text)
				const resHeight = measureTextHeight(`${fontSize}pt Arial`, 'Ag')
				height = (resHeight.height / 2 ) // * parseFloat(watch('layout.size_y'));
				width = (resWidth.width / 2 ) // * parseFloat(watch('layout.size_x'));
				textStyles['fontSize'] = `${values.fontSize}px`

				// setTimeout(function() {
				// 	let canvas = document.getElementById(id+"_canvas");
				// 	if (!canvas) {
				// 		return;
				// 	}

				// 	let ctx = canvas.getContext('2d');
				// 	ctx.fillText(
				// 		values.text ?? values.Text,
				// 		30,
				// 		50
				// 	  );


				// }, 500);
				textStyles['width'] = "100%";

				if (values.align == "Centre") {
					textStyles['textAlign'] = "center";
				} else {
					textStyles['textAlign'] = values.align.toLowerCase();
				}
				textStyles['padding'] = "5px";

			} else {
				// base it off the width of the box because its just an image
				textStyles['fontSize'] = calculateDisplayedTextSize()
			}
		}
	}

	function handleProofCardPoseAndGraphic() {
		containerStyles['visibility'] = values.visible ? 'visible' : 'hidden'

		const pageWidth = 2480
		const pageHeight = 1753

		// Handle Ratios
		if (values.fixed_ratio) {
			height = values.width * values.fixed_ratio * pageWidth
		} else {
			height = values.height * pageHeight
		}

		// Handle if text
		if (values.is_text) {
			if (type == GraphicTypes.PROOF_CARD_POSE){
				const textHeight = measureTextWidth('M', 30)
				height = (textHeight * 4000) / pageHeight
				width = values.width * pageWidth
			} else {
				const textWidth = measureTextWidth(values.text, getValues('design.text_height'))
				const textHeight = measureTextWidth('M', getValues('design.text_height'))
				width = 2 * (textWidth * 6500) / pageWidth
				height = 2 * (textHeight * 4000) / pageHeight
			}
		} else {
			width = values.width * pageWidth
		}

		x = values.centre.x * pageWidth;
		y = values.centre.y * pageHeight ;
		styleX = x - (width / 2);
		styleY = y - (height / 2);


		if (type == GraphicTypes.PROOF_CARD_GRAPHIC) {
			textStyles['fontSize'] = ((values.text_size ?? 75) * 3) + "px"
		} else  {
			textStyles['fontSize'] = 30 +"px"
		}

	}

	function handlePrintPanel(){
		// set rectangle properties for the image
		width = getValues('layout.boxes.' + id + '.width')
		height = getValues('layout.boxes.' + id + '.height')
		setBaseLocationValues('left', 'top', 'layout.size_x', 'layout.size_y');
		forceText()
		scaleUp();
		textStyles['fontSize'] = calculateDisplayedTextSize()
	}

	function handleCompositeGraphic(){
		// apply styles

		styles['backgroundColor'] = 'rgba(0,0,0,0.15)';
		containerStyles['visibility'] = values.visible ? 'visible' : 'hidden'

		// apply location and size values
		const fullWidth = parseInt(getValues('full_width'))
		const fullHeight = parseInt(getValues('full_height'))

		x = values.centre.x * fullWidth
		y = values.centre.y * fullHeight;
		width = values.width * fullWidth;
		height = values.height * fullHeight;

		styleX = x - width / 2;
		styleY = y - height / 2;

		// handle shape stylings

		switch (values?.type) {
			case "Image":
				delete styles['backgroundColor']
				delete styles['outline']
				textStyles['opacity'] = 0.7
				containerStyles['backgroundImage'] = `url("data:image/jpeg;base64, ${values.Image}")`;
				containerStyles['backgroundColor'] = 'rgba(0,0,0,0.15)';
			case "Shape":
				// add colour stylings to shape
				styles['backgroundOpacity'] = "1";
				styles['backgroundColor'] = values.colour;
				styles['outline'] = `${values.outline_stroke}px solid ${values.color_outline}`;

				textStyles['color'] = getContrastYIQ(values.colour)
				switch (values?.Shape?.toLowerCase()) {
					case 'triangle':
						boxString = ""
						// triangleSrc.style.fill = values.colour;
						// styles['backgroundImage'] = `url(${triangleSrc})`;
						delete styles['backgroundColor']
						delete styles['outline']

						break;
					case 'circle':
					case 'ellipse':
						styles['borderRadius'] = '100%';
						break

				}
			case "Text":
				values["Text"] = values.Text
		}

		if (values?.Shape == 'circle' || values?.Shape == 'square' && !values?.is_text) {
			dragClass = 'draggable-container-circle'
		}
		 textStyles['fontFamily'] = values.font
		 textStyles['fontSize'] = values.font_size

	}





	var dragClass = 'draggable-container';
	if (draggableOnly){
		dragClass = 'only-draggable-container'
	} else if (aspectLock){
		dragClass = 'draggable-container-ratio-lock'
	}
	if(fullDragLocked){
		dragClass = 'locked'
	}



	//  ------------------------------------------------------------------------------------------------
	//  handle differences between graphics
	//  ------------------------------------------------------------------------------------------------

	if ( type == GraphicTypes.STAFF_TEMPLATE_PHOTO || type ==GraphicTypes.STAFF_TEMPLATE_GRAPHIC) {
		handleStaffTemplatePhotoAndGraphic();
	} else if ( type == GraphicTypes.PROOF_CARD_POSE || type == GraphicTypes.PROOF_CARD_GRAPHIC ) {
		handleProofCardPoseAndGraphic();
	} else if ( type == GraphicTypes.PRINT_PANEL) {
		handlePrintPanel();
	} else if ( type == GraphicTypes.COMPOSITE_GRAPHIC) {
		handleCompositeGraphic();
	}
	if (!textStyles['fontSize']) {
		textStyles['fontSize'] = calculateDisplayedTextSize();
	}


	function calculateDisplayedTextSize() {

		if (height < width) {
			return parseInt(height / 5) + "px"
		}

		return parseInt(width / 5) +"px"
	}

	// update values in the styles with newly set ones
	containerStyles['height'] = height
	containerStyles['width'] = width
	containerStyles['transform'] = `translate(${styleX}px, ${styleY}px) `
	containerStyles['WebkitTransform'] = `translate(${styleX}px, ${styleY}px) `
	containerStyles['zIndex'] = zIndexVar

	return (
		<div ref={node} id={id} style={containerStyles} className={dragClass}>
			{(<div style={values?.type != "Image" ? styles : imageStyles} className='h-100 w-100 user-select-none pe-none'>
				<div className='w-100 h-100 d-flex justify-content-center align-items-center'> <div style={textStyles}>{boxString}</div></div>


				{type == GraphicTypes.COMPOSITE_GRAPHIC && values?.Shape?.toLowerCase() == 'triangle' && (
					<div style={triangleContainerStyles}>
						<svg
							id="triangle"
							stroke={values?.color_outline}
							stroke-width={values?.outline_stroke}
							style={triangleStyles}
							width="100%"
							height="100%"
							viewBox="0 0 100 100"
							preserveAspectRatio="none"
						>
							<polygon points="50 0, 100 100, 0 100"/>
						</svg>
					</div>
				)}

				{/* { GraphicTypes.STAFF_TEMPLATE_GRAPHIC && (
					<>
						<canvas style={{
							position: "absolute",
							top: "0px",
							width: "100%",
							height: "100%",
						}} id={id+"_canvas"}/>
					</>
				)} */}

			</div>
			)}
		</div>
	);
};

export default DraggableGraphic;
