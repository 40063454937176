import React, { useState, useEffect } from 'react'
import { ReOrderableItem, ReOrderableList } from 'react-reorderable-list'
import 'react-reorderable-list/dist/index.css'

export const OrderedSectionList = ({list, setList}) => {

    // const { watch, getValues, setValue, formState, reset, handleSubmit, unregister, register } = methods;
    // const [list, setList] = useState([])


    // useEffect(() => {
    //     createFolderListFromNames(folderNamesList)
    // }, [folderNamesList])

    // function createFolderListFromNames(folderNamesList){
    //     const tempList = []
    //     folderNamesList.forEach((folder, index) => {
    //         tempList.push({ id: index + 1, name: folder})
    //     })
    //     setList(tempList)
    // }


    return (
        <div className = "w-100" style={{ display: 'flex', gap: '30px' }}>
        <ReOrderableList
            //The unique identifier for this list. Should be unique from other lists and list groups.
            name='list2'
            //your list data
            list={list}
            //the list update callback
            onListUpdate={(newList) => setList(newList)}
            style={{
            width: '100%'
            }}
            componentProps={{
                    style: { width: '100%', minHeight: '200px', minWidth: '100px' }
                }}
            className = "w-100"
        >
            {list.map((data, index) => (
            <ReOrderableItem className = "w-100" key={`item-${index}`}>
                <div
                style={{
                    border: '1px solid rgba(1,1,1,0.2)',
                    margin: '10px',
                    padding: '5px',
                    borderRadius: '5px',
                }}
                >
                {data.name}
                </div>
            </ReOrderableItem>
            ))}
        </ReOrderableList>
        </div>
    )
}