import React, { useRef, useEffect, useState } from 'react';
import 'components/InteractJS/DraggableImage.js'


export const DraggableEditorImage = ({ id, setSelectedItem, src, value }) => {
	const node = useRef();

	const [borderCol, setBorderCol] = useState('Red');
	const [zIndexVar, setZIndex] = useState(0);


	useEffect(() => {
		// add when mounted
		document.addEventListener('mousedown', handleClick); // return function to be called when unmounted
		return () => {
			document.removeEventListener('mousedown', handleClick);
		};
	}, []);

	const handleClick = (e) => {
		// console.log(node.current)
		// console.log(e.target)
		// console.log(e.target.id);

		const result = parseInt(e.target.id.replace('photo_', ''));

		// console.log(node.current.contains(e.target));
		if (node.current.contains(e.target)) {
			// const result = parseInt(e.target.id.replace('photo_', ''));
			// console.log(result);
			setBorderCol('Blue');
			setZIndex(100);
			setSelectedItem({ id: result, type: 'draggable-panel-photo', element: e.target, value: value });;
			return;
		} // outside click

		if (e.target.id.includes('photo_') && e.target.id != result) {
			setBorderCol('Red');
			setZIndex(0);
		}


		// if (e.target.className != 'draggable-panel-photo') {
		// 	setSelectedItem({ id: -1, type: 'None', element: null });
		// }
	};

	const photoStyle = {
		width: value.width + 'px',
		height: value.height + 'px',
		position: 'absolute',
		// top: value.top + 'px',
		// left: value.left + 'px',
		backgroundColor: borderCol,
		// border: '5px solid ' + borderCol,
		// borderStyle: 'inset',
		transform: `translate(${value.left}px, ${value.top}px)`,
		zIndex: zIndexVar,
	};

	return (
		<div data-x={value.left} data-y={value.top} className='d-flex justify-content-center align-items-center draggable-panel-photo ' ref={node} id={id} style={photoStyle}>
			{value.text}
		</div>
	);
};

export default DraggableEditorImage;
