import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormGroup, Select, CustomButton, Input, DatePicker, SubmitButton } from 'components/react-hook-form';
import { useForm, FormProvider, useFormContext, useFormState } from 'react-hook-form';
import { Row, Col, Button, Modal, ModalBody, ModalFooter, Label } from 'reactstrap';
import { ModalHeader } from 'components/ModalHeader';
import { useUserContext, useClient } from 'hooks';
import { createClient } from 'hooks/useClient';
import { useQuery, useQueryClient } from 'react-query';

import { Page } from 'components/common';

import download from 'downloadjs';

export const Home = () => {
	const navigate = useNavigate();
	const client = useClient();
	const { user } = useUserContext();

//   const {
// 		data: printPanelsList,
// 		error: printPanelsError,
// 		refetch: getPrintPanelsList,
//   } = useQuery(
// 		'get_paint_panels_list',
// 		async () =>
// 			client.get(`get_pack_barcodes`).then((resp) => {
// 				console.log(resp)
// 				const contentType = resp.headers['content-type'];
// 				if (contentType === 'application/pdf') {
// 					download(resp.data, `test.pdf`, 'application/pdf');
// 				}
// 			}),
// 			{
// 				refetchOnWindowFocus: false,
// 			}
//   );


	return (
		<Page>
			<h1> Welcome </h1>
			<p> This is the homepage</p>
		</Page>
	);
};

export default Home;
