import React, { useMemo } from "react";
import { map } from "lodash";
import { useController } from "react-hook-form";
import ReactSelect from "react-select";
import classNames from "classnames";

const flattenOptions = (v, valueKey) => {
  return v.reduce((acc, i) => {
    if (i.options) {
      acc = {
        ...acc,
        ...flattenOptions(i.options, valueKey),
      };
    } else {
      acc[i[valueKey]] = i;
    }
    return acc;
  }, {});
};

export const RichSelect = ({
  name,
  rules,
  defaultValue,
  control,
  onFocus,
  options,
  labelKey = "label",
  valueKey = "value",
  simpleValue,
  className,
  isMulti,
  isClearable,
  ...props
}) => {
  const { field } = useController({
    name,
    rules,
    defaultValue,
    control,
    onFocus,
  });
  const { onBlur, value, onChange } = field;

  const keyedOptions = useMemo(
    () => (options && simpleValue ? flattenOptions(options, valueKey) : {}),
    [options, valueKey, simpleValue]
  );

  const selected = useMemo(() => {
    if (!value) return null;
    if (simpleValue) {
      if (isMulti) {
        return map(value, (i) => keyedOptions[i]);
      } else {
        return keyedOptions[value];
      }
    }
    return value;
  }, [simpleValue, keyedOptions, value, isMulti]);

  return (
    <ReactSelect
      name={name}
      options={options}
      onChange={(v) => {
        if (simpleValue) {
          if (isMulti) {
            onChange(map(v, (i) => i[valueKey]));
          } else {
            onChange(v ? v[valueKey] : null);
          }
        } else {
          onChange(v);
        }
      }}
      onBlur={onBlur}
      value={selected}
      getOptionLabel={(v) => v[labelKey]}
      getOptionValue={(v) => v[valueKey]}
      className={classNames("react-select", className)}
      classNamePrefix="react-select"
      isMulti={isMulti}
      isClearable={isClearable}
      {...props}
    />
  );
};
