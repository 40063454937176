import React, { useRef, useEffect, useState } from 'react';
import { FormGroup, Select, Input } from 'components/react-hook-form';
import { FormProvider } from 'react-hook-form';
import { Row, Col } from 'reactstrap';
import ColorPicker from 'components/editors/ColourPicker.jsx';
import { toBase64 } from 'components/editors/utils';

const graphicTypes = [
	{ id: '1', name: 'Image' },
	{ id: '2', name: 'Text' },
	{ id: '3', name: 'Shape' },
	{ id: '4', name: 'Class Name' },
];

const shapeTypes = [
	// { id: '1', name: 'Text' },
	{ id: '1', name: 'Square' },
	{ id: '2', name: 'Rectangle' },
	{ id: '3', name: 'Triangle' },
	{ id: '4', name: 'Circle' },
	{ id: '5', name: 'Ellipse' },
];

const fontStyles = [
	// { id: '1', name: 'Text' },
	{ id: '1', name: 'Cantarell Italic Light' },
];


export const GraphicContextMenu = ({ selectedItem, methods, fontOptions, downloadedFonts, getIndividualFont }) => {
	const graphicId = 'design.boxes.graphic_' + selectedItem.id;
	const { watch, getValues, setValue, formState, reset, handleSubmit, unregister, register } = methods;
	console.log("this one", fontOptions)
	return (
		<FormProvider {...methods}>
			<h3>Graphic {selectedItem.id}</h3>

			<FormGroup label='Type'>
				<Select
					className='composites-select'
					// isMulti
					getOptionLabel={(data) => {
						return data['name'];
					}}
					getOptionValue={(data) => {
						return data['id'];
					}}
					options={graphicTypes}
					name={graphicId + '.whatever'}

					onChange={(newValue) => {
						setValue(graphicId + '.is_text', newValue.name == 'Text');
						setValue(graphicId + '.type', newValue.name);
						setValue(graphicId + '.whatever', newValue);
					}}
					shouldUnregister={false}
					// value = {watchBoxes["graphicId + '.whatever'"] ?? ''}
					placeholder={graphicTypes[2].name}
					defaultValue={graphicTypes.find(x=>x.name == getValues(graphicId + '.type')) ?? graphicTypes[2]}
				/>
			</FormGroup>

			<Row className='m-0'>
				<Col className='pl-0'>
					<FormGroup label='Width'>
						<input
							type='number'
							className='form-control'
							placeholder='0'
							{...register(graphicId + '.width', {
								required: false,
								value: selectedItem.element.style.width,
								valueAsNumber: true,
							})}
							min={0}
							max={1}
							step={0.1}
							onChange={(e) => {
								// set element value to ratio of page
								const value = e.target.value;
								const yValue = parseFloat(selectedItem.element.getAttribute('data-y') ?? 0);

								const result = value * parseFloat(getValues('full_width'));
								// console.log(result);
								selectedItem.element.style.width = result + 'px';

								const rect = selectedItem.element.getBoundingClientRect();
								const x = getValues(graphicId + '.centre.x');
								const resultLoc = x * parseFloat(getValues('full_width')) - rect.width / 2;

								selectedItem.element.setAttribute('data-x', parseFloat(resultLoc));
								selectedItem.element.style.transform = `translate(${resultLoc}px, ${yValue}px) `;
								selectedItem.element.style.WebkitTransform = `translate(${resultLoc}px, ${yValue}px) `;

								// Store value in the form
								setValue(graphicId + '.width', parseFloat(value));
							}}
						/>
					</FormGroup>
				</Col>
				<Col className='pr-0'>
					<FormGroup label='Height'>
						<input
							type='number'
							className='form-control'
							placeholder='0'
							{...register(graphicId + '.height', {
								required: false,
								value: selectedItem.element.style.height,
								valueAsNumber: true,
							})}
							min={0}
							max={1}
							step={0.1}
							onChange={(e) => {
								// set element value to ratio of page
								const value = e.target.value;
								const xValue = parseFloat(selectedItem.element.getAttribute('data-x') ?? 0);

								const result = value * parseFloat(getValues('full_height'));
								selectedItem.element.style.height = result + 'px';

								const rect = selectedItem.element.getBoundingClientRect();
								const y = getValues(graphicId + '.centre.y');
								const resultLoc = y * parseFloat(getValues('full_height')) - rect.height / 2;
								selectedItem.element.setAttribute('data-y', parseFloat(resultLoc));
								selectedItem.element.style.transform = `translate(${xValue}px, ${resultLoc}px) `;
								selectedItem.element.style.WebkitTransform = `translate(${xValue}px, ${resultLoc}px) `;

								// Store value in the form
								setValue(graphicId + '.height', parseFloat(value));
							}}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row className='m-0'>
				<Col className='pl-0'>
					<FormGroup label='Left'>
						<input
							type='number'
							className='form-control'
							{...register(graphicId + '.centre.x', {
								required: false,
								value: 0,
								valueAsNumber: true,
							})}
							min={0}
							max={1}
							step={0.1}
							placeholder='0'
							onChange={(e) => {
								// set element value to ratio of page
								const value = e.target.value;

								const rect = selectedItem.element.getBoundingClientRect();

								const result = value * parseFloat(getValues('full_width')) - rect.width / 2;
								const yValue = parseFloat(selectedItem.element.getAttribute('data-y') ?? 0);

								selectedItem.element.setAttribute('data-x', parseFloat(result));
								selectedItem.element.style.transform = `translate(${result}px, ${yValue}px) `;
								selectedItem.element.style.WebkitTransform = `translate(${result}px, ${yValue}px) `;

								// Store value in the form
								setValue(graphicId + '.centre.x', parseFloat(value));
							}}
						/>
					</FormGroup>
				</Col>
				<Col className='pr-0'>
					<FormGroup label='Top'>
						<input
							type='number'
							className='form-control'
							placeholder='0'
							min={0}
							max={1}
							step={0.1}
							{...register(graphicId + '.centre.y', {
								required: false,
								value: 0,
								valueAsNumber: true,
							})}
							onChange={(e) => {
								// set element value to ratio of page
								const value = e.target.value;

								const rect = selectedItem.element.getBoundingClientRect();

								const result = value * parseFloat(getValues('full_height')) - rect.height / 2;

								const xValue = parseFloat(selectedItem.element.getAttribute('data-x') ?? 0);

								// selectedItem.element.style.removeProperty('transform');
								console.log(selectedItem.element.getAttribute('data-x'));
								console.log(xValue, result);

								selectedItem.element.setAttribute('data-y', parseFloat(result));
								selectedItem.element.style.transform = `translate(${xValue}px, ${result}px) `;
								selectedItem.element.style.WebkitTransform = `translate(${xValue}px, ${result}px) `;

								// Store value in the form
								setValue(graphicId + '.centre.y', parseFloat(value));
							}}
						/>
					</FormGroup>
				</Col>
			</Row>
			<hr />
			{/* Only show if the selected item is text */}
			{getValues(graphicId + '.type') == 'Text' && (
				<>
					<FormGroup label='Text Content'>
						<Input
							type='text'
							className='form-control'
							name={graphicId + '.Text'}
							placeholder='Enter text'
							rules={{
								required: false,
							}}
						/>
					</FormGroup>

					<Row className='m-0'>
						<Col md={6} className='pl-0'>
							<FormGroup label='Font'>
								<Select
									getOptionLabel={(data) => {
										return data['name'];
									}}
									getOptionValue={(data) => {
										return data['id'];
									}}
									options={fontOptions}
									name={graphicId + '.font_form_item'}
									onChange={(newValue) => {
										setValue(graphicId + '.font', newValue.name);
										// request full font
										getIndividualFont(downloadedFonts, newValue.name)
										setValue(graphicId + '.font_form_item', newValue);
									}}
									shouldUnregister={false}
									placeholder={'Select font'}
								/>
							</FormGroup>
						</Col>
						<Col md={6} className='pr-0'>
							<FormGroup label='Size'>
								<input
									type='number'
									className='form-control'
									placeholder='0'
									min={0}
									max={72}
									step={1}
									{...register(graphicId + '.font_size', {
										required: false,
										// value: 0,
										valueAsNumber: true,
									})}
									onChange={(e) => {
										const newValue = e.target.value;
										setValue(graphicId + '.font_size', newValue);
										const element = document.getElementById('graphic_' + selectedItem.id);
										element.style.fontSize = newValue + 'px';
									}}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row className='m-0'>
						<Col className='pr-0 pl-0' md={6}>
							<FormGroup label='Colour'>
								<div style={{ height: 'calc(1.5em + .75rem + 2px)' }}>
									<ColorPicker setValue={setValue} hookFormTitle={graphicId + '.font_colour'} isHex={true} defaultColour={'#000000'} />
								</div>
							</FormGroup>
						</Col>
					</Row>
				</>
			)}
			{/* Only show for shapes */}
			{getValues(graphicId + '.type') == 'Shape' && (
				<>
					<FormGroup label='Shape'>
						<Select
							className='composites-select'
							getOptionLabel={(data) => {
								return data['name'];
							}}
							getOptionValue={(data) => {
								return data['id'];
							}}
							options={shapeTypes}
							{...register('whatever_shapes', {
								rules: {
									required: false,
								},
								shouldUnregister: false,
							})}
							onChange={(newValue) => {
								setValue(graphicId + '.Shape', newValue.name.toLowerCase());
							}}
							shouldUnregister={true}
							placeholder={shapeTypes[1].name}
						/>
					</FormGroup>
					<Row>
						<Col className=''>
							<FormGroup label='Fill Colour'>
								<div style={{ height: 'calc(1.5em + .75rem + 2px)' }}>
									<ColorPicker isHex={true} setValue={setValue} hookFormTitle={graphicId + '.colour'} />
								</div>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col className=''>
							<FormGroup label='Outline Colour'>
								<div style={{ height: 'calc(1.5em + .75rem + 2px)' }}>
									<ColorPicker isHex={true} setValue={setValue} hookFormTitle={graphicId + '.color_outline'} defaultColour={'#000000'} />
								</div>
							</FormGroup>
						</Col>
						<Col md={6} className='pr-0'>
							<FormGroup label='Outline Thickness'>
								<input
									type='number'
									className='form-control'
									placeholder='0'
									min={0}
									max={72}
									step={1}
									{...register(graphicId + '.outline_stroke', {
										required: false,
										// value: 0,
										valueAsNumber: true,
									})}
									onChange={(e) => {
										const newValue = e.target.value;
										setValue(graphicId + '.outline_stroke', newValue);
										const element = document.getElementById('graphic_' + selectedItem.id);
										element.style.fontSize = newValue + 'px';
									}}
								/>
							</FormGroup>
						</Col>
					</Row>
				</>
			)}
			{getValues(graphicId + '.type') == 'Image' && (
				<>
					<FormGroup label='Image File'>
						<input
							type='file'
							className='form-control'
							// id='background'
							{...register('background_form_item', {
								required: false,
								onChange: async (e) => {
									const file = e.target.files[0];
									console.log(file);
									await toBase64(file).then((string) => {
										setValue(graphicId + '.Image', string);
										// console.log('String: ', string);
										// setPageBackground(string);
										// refreshPanel();
										// setImagePreview(string);
									});
								},
							})}
						/>
					</FormGroup>
					{/* <FormGroup label='Size'>
						<input
							type='number'
							className='form-control'
							placeholder='0'
							min={0}
							max={10}
							step={1}
							{...register(graphicId + '.fixed_ratio', {
								required: false,
								// value: 0,
								valueAsNumber: true,
							})}
							// onChange={(e) => {
							// 	const newValue = e.target.value;
							// 	setValue(graphicId + '.font_size', newValue);
							// 	const element = document.getElementById('graphic_' + selectedItem.id);
							// 	element.style.fontSize = newValue + 'px';
							// }}
						/>
					</FormGroup> */}
				</>
			)}
		</FormProvider>
	);
};

export default GraphicContextMenu;
