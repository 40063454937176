import React, { useRef, useEffect, useState } from 'react';
import { determineColor } from 'components/editors/utils';


export const CompositeArea = ({ id, setSelectedItem, selectedItem, eventFunction, backgroundImage, methods}) => {
	const node = useRef();

	const { watch, getValues, setValue, formState, reset, handleSubmit, unregister, register } = methods;

	const [firstMount, setFirstMount] = useState(true);


	const [borderCol, setBorderCol] = useState(determineColor([255,255,255]));
	const [fullDragLocked, setFullDragLocked] = useState(true);
	function toggleLock(e){
		setFullDragLocked(e.flag)
	}

	useEffect(() => {
		// add when mounted
		document.addEventListener('mousedown', handleClick); // return function to be called when unmounted
		document.addEventListener('toggleLock', toggleLock); // return function to be called when unmounted

		return () => {
			document.removeEventListener('mousedown', handleClick);
			document.removeEventListener('toggleLock', toggleLock);

		};
	}, []);

	useEffect(() => {
		if (firstMount) {
			setDataValues()
		}
	},);

	function getTranslateXY(element) {
		const style = window.getComputedStyle(element)
		const matrix = new DOMMatrixReadOnly(style.transform)
		return {
			x: matrix.m41,
			y: matrix.m42
		}
	}

	const setDataValues = () => {
		setTimeout(function(){
			const element = document.getElementById(id);
			if (!element) {
				return
			}
			const transform = getTranslateXY(element)
			// console.log(transform)
			element.setAttribute('data-x', transform.x);
			element.setAttribute('data-y', transform.y);
			setFirstMount(false)
	   },500); //delay is in milliseconds
	}


	const fullWidth = getValues('full_width');
	const fullHeight = getValues('full_height');
	const composite = watch('design.boxes.composite_area_1')

	const x = fullWidth * composite.centre.x;
	const y = fullHeight * composite.centre.y;
	const width = fullWidth * composite.width;
	const height = fullHeight * composite.height;

	const styleX = x - width / 2;
	const styleY = y - height / 2;

	const compositeStyle = {
		position: 'relative',
		backgroundRepeat: 'no-repeat',
		backgroundSize: '100% 100%',
		height: height + "px",
		width: width + "px",
		'transform' : `translate(${styleX}px, ${styleY}px) `,
		'WebkitTransform' : `translate(${styleX}px, ${styleY}px) `,


		// width: composite.width,

		// outline: '10px dotted ' + borderCol,
		// outlineStyle: 'inset',
		padding: "10px",
		// backgroundImage: `url("data:image/jpeg;base64, ${backgroundImage}")`,
	};

	function formatColour(colour){
		if (colour) {
			return `rgb(${colour[0]}, ${colour[1]}, ${colour[2]})`;
		}

		return "rgba(0,0, 0,0.15)"
	}

	const innerStyle = {
		position: 'relative',
		width: 'calc(100%)',
		height: 'calc(100%)',

		outline: '10px solid ' + borderCol,
		backgroundColor:formatColour(composite?.background?.colour),

		// backgroundImage: `url("data:image/jpeg;base64, ${backgroundImage}")`,
	};
	const textStyles = {
		'font-size': parseInt(composite.width * 200) +"px",
		color:borderCol
	}
	// textStyles['font-size'] = parseInt(width / 5) +"px"



	const handleClick = (e) => {
		const result = parseInt(e.target.id.replace('composite_area_', ''));

		if (node.current.contains(e.target)) {
			setBorderCol(determineColor(getValues("background"), true));
			setSelectedItem({ id: result, type: 'composite_area', element: e.target });
		} else {
			setBorderCol(determineColor(getValues("background"), false));
		}
	};

	return (
		<div id={id} ref={node} style={compositeStyle} className={!fullDragLocked ? 'composite_area' : 'locked-composite'}>
				<div className='d-flex justify-content-center align-items-center pointer-events-none' style={innerStyle}> <div style={textStyles}>{id}</div></div>
		</div>
	);
};

export default CompositeArea;
