import React, { useState, useMemo } from 'react';
import { FormGroup, RichSelect as Select } from 'components/react-hook-form';
import { Row, Col, Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { ModalHeader } from 'components/ModalHeader';
import { Pagination, Table, Search, useClientPaginatedRowSelectTable, TableContextProvider } from '../../components/common/Tables';
import { toBase64 } from 'components/editors/utils';

const columns = [
  {
    Header: 'Forename',
    accessor: 'first_name',
  },
  {
    Header: 'Surname',
    accessor: 'surname',
  },
  {
    Header: 'Group',
    accessor: 'class_name',
  },
  {
    Header: 'ID',
    accessor: 'id',
  },
];

const IndividualNoDataModal = ({ methods, toggleModal, isOpen, selectedImage, setSelectedImage, data, setData, groups, setFilesArray, photoType }) => {
  const { watch } = methods;
  const [selectedStudent, setSelectedStudent] = useState(null);

  const closeModal = () => {
    toggleModal();
    setSelectedStudent(null);
  };

  const filteredData = useMemo(() => data.filter(e => !e.photo), [data]);
  const photoTypeWatcher = watch('no_data_photo_type_select');
  const no_data_group_select = watch("no_data_group_select");
  const { table } = useClientPaginatedRowSelectTable({
    data: filteredData,
    setData,
    initialState: {
      pageSize: 10
    },
    columns: useMemo(() => columns, []),
    selectionColumn: false,
  });

  const getRowProps = (row) => {
    return {
      onClick: () => setSelectedStudent(row.values),
      style: { cursor: 'pointer' },
    };
  };

  const handleSave = async () => {


    switch (photoTypeWatcher.id) {
      case 0: {
        setFilesArray(obj => {
          obj[selectedImage.file.webkitRelativePath].individual = true;
          obj[selectedImage.file.webkitRelativePath].assigned = selectedStudent.id;
          return {...obj};
        });
        setSelectedImage(img => {
          img.file.assigned = selectedStudent.id;
          return { ...img };
        });
        console.log('student: ', selectedStudent)
        const idx = data.findIndex(e => e.id === selectedStudent.id)
        await setData(x => {
          console.log('x: ', x, idx)
          x[idx].photo = selectedImage;
          x[idx].photo_type = "Individual";
          x[idx].encoded_photo = toBase64(selectedImage.file);
          return [...x];
        })
        break;
      }
      case 1: {
        setFilesArray(obj => {
          obj[selectedImage.file.webkitRelativePath].group = true;
          obj[selectedImage.file.webkitRelativePath].assigned = no_data_group_select?.id || true;
          return {...obj};
        });
        setSelectedImage(img => {
          img.file.assigned = no_data_group_select?.id || true;
          return { ...img };
        });
        break;
      }
      case 2: {
        setFilesArray(obj => {
          obj[selectedImage.file.webkitRelativePath].siblings = true;
          obj[selectedImage.file.webkitRelativePath].assigned = photoTypeWatcher.name;
          return {...obj};
        });
        setSelectedImage(img => {
          img.file.assigned = photoTypeWatcher.name || true;
          return { ...img };
        });
        break;
      }
      case 3: {
        setFilesArray(obj => {
          obj[selectedImage.file.webkitRelativePath].staff = true;
          obj[selectedImage.file.webkitRelativePath].assigned = photoTypeWatcher.name;
          return {...obj};
        });
        setSelectedImage(img => {
          img.file.assigned = photoTypeWatcher.name || true;
          return { ...img };
        });
        break;
      }
    }

    closeModal();
  }

  return (
    <Modal isOpen={isOpen} toggle={closeModal} fade={false} className='md-modal'>
      <ModalHeader toggle={closeModal} charCode='close'
        close={<FontAwesomeIcon icon={faTimes} className='' onClick={toggleModal} role='button' />}>
      </ModalHeader>

      <ModalBody>
        <Row>
          <Col md={6} style={{ maxWidth: '400px' }}>
            <img loading="lazy" className='rounded img-thumbnail m-2' style={{ width: '300px', height: '300px', objectFit: 'cover' }} src={selectedImage?.image} role='button' />
            {selectedStudent !== null &&
                        <>
                        <div class="py-2">
                          <h3 class="text-lg leading-6 font-medium text-gray-900">Student Information</h3>
                          {/* <p class="mt-1 max-w-2xl text-sm text-gray-500"></p> */}
                        </div>
                        <div class="border-t border-gray-200 pb-3">
                          <dl>
                            <div class="bg-gray-50 px-1 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt class="text-sm font-medium text-gray-500">ID</dt>
                              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{selectedStudent?.id}</dd>
                            </div>
                            <div class="bg-white px-1 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt class="text-sm font-medium text-gray-500">Full Name</dt>
                              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{selectedStudent?.first_name} {selectedStudent?.surname}</dd>
                            </div>
                            <div class="bg-gray-50 px-1 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt class="text-sm font-medium text-gray-500">Group</dt>
                              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{selectedStudent?.class_name}</dd>
                            </div>
                          </dl>
                        </div>
                        </>
            }
          </Col>
          <Col>
            <FormGroup label={'Photo Type'}>
              <Select
                name="no_data_photo_type_select"
                options={photoType.slice(0, -1)}
                labelKey="name"
                valueKey="id"
                rules={{ required: true }}
                shouldUnregister={true}
                defaultValue={''}
              />
            </FormGroup>
            {/* No Data */}
            {photoTypeWatcher && photoTypeWatcher.id == 0 &&
              <TableContextProvider table={table}>
                <Row form>
                  <Col>
                    <Search placeholder='Search' />
                  </Col>
                </Row>
                <Table size="sm" clientSidePagination striped getRowProps={getRowProps} />
                <Pagination />
              </TableContextProvider>
            }


            {/* Group */}
            {!!photoTypeWatcher && photoTypeWatcher.id == 1 &&
              <FormGroup label={'Group'}>
                <Select
                  name="no_data_group_select"
                  options={groups}
                  labelKey="name"
                  valueKey="id"
                  rules={{ required: false }}
                  shouldUnregister={true}
                  defaultValue={''}
                />
              </FormGroup>
            }
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter>
        <Row className='justify-content-end'>
          <Col>
            <Button onClick={toggleModal}>Close</Button>
          </Col>
          <Col>
            <Button
              color='primary'
              disabled={!photoTypeWatcher || (photoTypeWatcher?.id === 0 ? selectedStudent === null : false)}
              onClick={async () => {
                await handleSave()
              }}
            >
              Save
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

export default IndividualNoDataModal;
