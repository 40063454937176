import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { NotificationManager } from 'react-notifications';
import { useClient, useUserContext } from 'hooks';
import { useForm, FormProvider, useFormContext, useFormState } from 'react-hook-form';
import { FormGroup, Select, SubmitButton, Input, DatePicker } from 'components/react-hook-form';
import { Alert, Button, Container, Card, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import { Slide, Fade } from 'react-slideshow-image';
// import { Link, useHistory } from 'react-router-dom';

// CSS
import 'react-slideshow-image/dist/styles.css';
import 'assets/css/style.css';

// import { Formik, Form, Field, FieldArray } from "formik";

// import * as Yup from "yup";

// import { FileToB64Field, ErrorLabel, passwordRegExp, phoneRegExp, openInNewTab, SubmitButton } from "components/common";

export const Login = () => {
	const methods = useForm({
		defaultValues: {
			// connected_organisation: selectedOrganisation,
		},
		mode: 'all',
	});
	const navigate = useNavigate();
	//   const history = useHistory();

	const { watch, setValue, formState, reset, handleSubmit } = methods;
	const client = useClient();
	const { login } = useUserContext();

	async function onSubmit({ email, password }) {
    try {
      await login(email, password);
    } catch (ex) {
      const status = ex?.response?.status;

      if (status === 400) {
        NotificationManager.error("Invalid username or password");
        return;
      } else {
        throw ex;
      }
    }

    navigate("/home");
  }

	const slideImages = [
		{
			url: 'https://images.unsplash.com/photo-1633542804128-9ae60b3d5249?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80',
			caption: 'First Slide',
		},
		{
			url: 'https://images.unsplash.com/photo-1633542804128-9ae60b3d5249?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80',
			caption: 'Second Slide',
		},
		{
			url: 'https://images.unsplash.com/photo-1633542804128-9ae60b3d5249?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80',
			caption: 'Third Slide',
		},
	];

	const Slideshow = () => {
		return (
			<div className='slideshow-container'>
				<Slide easing='ease'>
					<div className='each-slide'>
						<div style={{ backgroundImage: `url(${slideImages[0].url})` }}>{/* <span>Slide 1</span> */}</div>
					</div>
					<div className='each-slide'>
						<div style={{ backgroundImage: `url(${slideImages[1].url})` }}>{/* <span>Slide 2</span> */}</div>
					</div>
					<div className='each-slide'>
						<div style={{ backgroundImage: `url(${slideImages[2].url})` }}>{/* <span>Slide 3</span> */}</div>
					</div>
				</Slide>
			</div>
		);
	};

	return (
		// <div style={{ height: '100vh' }}>

		<>
			{/* Slideshow container */}
			{/* <div style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}>{Slideshow()}</div> */}

			{/* Form Container */}
			<div className='login-container'>
				<img className="mx-auto pt-20" src="logo.png"/>
				<Container fluid='sm' style={{ height: 'calc(100% - 223px)', width: '25%', maxWidth: '1200px', minWidth: '500px' }}>
					<Row className='align-items-center' style={{ height: '100%' }}>
						<Col className='login-card'>
							<FormProvider {...methods}>
								<form onSubmit={handleSubmit(onSubmit)}>
									<h3>Sign In</h3>

									<FormGroup>
										<label>Email address</label>
										<Input
											type='email'
											className='form-control'
											name={'email'}
											placeholder='Enter email'
											rules={{
												required: false,
											}}
										/>
									</FormGroup>

									<FormGroup>
										<label>Password</label>
										<Input
											type='password'
											className='form-control'
											name={'password'}
											placeholder='Enter password'
											rules={{
												required: false,
											}}
										/>
									</FormGroup>

									<div className='form-group'>
										<div className='custom-control custom-checkbox'>
											<input type='checkbox' className='custom-control-input' id='customCheck1' />
											<label className='custom-control-label' htmlFor='customCheck1'>
												Remember me
											</label>
										</div>
									</div>

									<SubmitButton>Login</SubmitButton>
									<br />
									<br />

									<Row>
										<Col>
											<p className='forgot-password text-left'>
												Don't have an account yet?
												<br />
												<a href='signup'>Sign Up</a>
											</p>
										</Col>
										<Col>
											<p className='forgot-password text-right'>
												Forgot your password?
												<br />
												<a href='#'>Reset Password</a>
											</p>
										</Col>
									</Row>
								</form>
							</FormProvider>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

export default Login;
