import React, { useMemo, useState, useEffect } from "react";
import { debounce } from "lodash";

const DebouncedInput = ({ onChange, value: valueProp, innerRef, ...props }) => {
  const [value, setValue] = useState(() => valueProp);

  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  const onDebounced = useMemo(() => debounce(onChange, 1000), [onChange]);

  return (
    <input
      value={value}
      onChange={(e) => {
        e.persist();
        setValue(e.target.value);
        onDebounced(e);
      }}
      ref={innerRef}
      {...props}
    />
  );
};

export default DebouncedInput;
