import React, { useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { Page } from 'components/common';
import { CreateNewPanelsBrowse, CreateNewPanelsCode, CreateNewPanelsMulti } from '../CreateNewPanels';

export const CreateNewPanels = () => {
  // Constants
  const CODE = 'Code';
  const BROWSE = 'Browse';
  const MULTI = 'Multi';

  // State stuff
  const [selectedForm, setSelectedForm] = useState(null);

  // Styling stuff
  const buttonClasses = 'w-100 mb-2';

  const showContent = () => {
    const buttons = (
      <Row className='align-content-center justify-content-center'>
        <Col md={4}>
          <Button className={buttonClasses} onClick={() => { setSelectedForm(CODE) }} color='primary'>
            Create From Proof Card Code
          </Button>
          <Button className={buttonClasses} onClick={() => { setSelectedForm(BROWSE) }} color='primary'>
            Browse Images
          </Button>
          <Button className={buttonClasses} onClick={() => { setSelectedForm(MULTI) }} color='primary'>
            Create Multi Purpose Panels
          </Button>
        </Col>
      </Row>
    );

    switch (selectedForm) {
      case CODE:
        return <CreateNewPanelsCode setSelectedForm={setSelectedForm} />;
      case BROWSE:
        return <CreateNewPanelsBrowse setSelectedForm={setSelectedForm} />;
      case MULTI:
        return <CreateNewPanelsMulti setSelectedForm={setSelectedForm} />;
      default:
        return buttons;
    }
  };

  return (
    <Page>
      <div className='font-bold text-2xl tracking-wide mb-3'>
        Create New Panels
      </div>
      <hr />

      {selectedForm != null && (
        <Row className='mb-2'>
          <Col>
            <Button
              onClick={() => {
                setSelectedForm(null);
              }}
              color='secondary'
            >
              {' '}
              Back
            </Button>
          </Col>
        </Row>
      )}
      {showContent()}
    </Page>
  );
};

export default CreateNewPanels;
