import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Row, Col, Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { ModalHeader } from 'components/ModalHeader';
import { useForm, FormProvider } from 'react-hook-form';
import { Input, RFormGroup as FormGroup, RSubmitButton as SubmitButton } from 'components/react-hook-form';
import { NotificationManager } from 'react-notifications';
import { useClient } from 'hooks';
import { FetchingSpinner, Page } from 'components/common';
import { Pagination, Table, Search, useClientPaginatedRowSelectTable, TableContextProvider } from '../components/common/Tables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const StaffTable = ({ data, setData, setSelectedJob, toggleProcessModal, requestJobData }) => {
  const columns = useMemo(() => ([
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'School',
      accessor: 'school_name',
    },
    {
      Header: '',
      accessor: "_process",
      disableSortBy: true,
      Cell: ({ row }) => {
        return <Button
          color="darker"
          onClick={() => {
            setSelectedJob(row.original);
            toggleProcessModal();
            requestJobData(row.original?.id)
          }}
        >
          Process
        </Button>;
      }
    },
  ]), [setSelectedJob, toggleProcessModal, requestJobData]);

  const { table } = useClientPaginatedRowSelectTable({
    data,
    setData,
    initialState: {
      pageSize: 10
    },
    columns: columns,
    selectionColumn: false
  });
  return (
    <TableContextProvider table={table}>
      <Row form>
        <Col>
          <Search placeholder='Search' onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} />
        </Col>
      </Row>
      <Table size="sm" clientSidePagination striped />
      <Pagination />
    </TableContextProvider>
  );
}



export const ProcessStaffImageJobs = () => {
  const client = useClient();
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: {},
    mode: 'all',
  });

  const { watch, handleSubmit, register } = methods;
  const [data, setData] = useState([]);
  const [jobElementList, setJobListElements] = useState(null);

  const [selectedJob, setSelectedJob] = useState(null);


  const [showProcessModal, setShowProcessModal] = useState(false);
  const toggleProcessModal = () => setShowProcessModal(!showProcessModal);
  const [previewImage, setPreviewImage] = useState(null);

  // -------------------------------------------------------------
  // API Stuff
  // -------------------------------------------------------------





  const { isFetching, refetch: getStaffImageJobs } = useQuery(
    'get_paint_panels',
    async () => {
      client.get(`pending_staff_images`).then((response) => {
        console.log('response data:', response.data);
        // setPrintPanels(data.data);
        setJobListElements(createJobList(response.data));
        setData(response.data);
      });
      return null;
    },
    {
      refetchOnWindowFocus: false,
    }
  );


  function requestJobData(job_id) {
    // pending_staff_images
    client.get(`pending_staff_images`, { params: { id: job_id, images: false } }).then((response) => {
      // setPrintPanels(data.data);
      setSelectedJob(response.data);
      setPreviewImage(response.data.template.background);
      // setJobListElements(createJobList(response.data));
    });
  }

  const onSubmit = async (formData, e) => {

    const toSave = { selected_job: {school_id: selectedJob.school, ...selectedJob},
                  job_name: `${selectedJob?.school_name}: ${selectedJob?.name}`, ...formData }

    client
      .post('/generate_staff_image_job', { staff_image_job_id: selectedJob?.id, job_type: 'STAFF_IMAGE', job_data: toSave })
      .then((data) => {
        NotificationManager.success('Staff image job has been submitted successfully');
        // reset()
        // setSubmitting(false)
        navigate(`/viewworkflow/${data.data.id}`);
        return null;
      })
      .catch((e) => {
        console.error('ERROR: ', e.response.data);
        NotificationManager.error('There was an issue with this request');
        // setSubmitting(false)
        return null;
      });

    // generate_staff_image_job
  };

  // -------------------------------------------------------------
  // Frontend Stuff
  // -------------------------------------------------------------

  function createJobList(jobs) {
    const toReturn = [
      <Row className={'mt-3'}>
        <Col>
          <h4>School</h4>
        </Col>
        <Col>
          <h4>Job Name</h4>
        </Col>
        <Col></Col>
      </Row>,
    ];
    jobs.forEach((job) => {
      toReturn.push(
        <Row className={'mt-3'}>
          <Col>{job.school_name}</Col>
          <Col>{job.name}</Col>
          <Col>
            <Button
              onClick={() => {
                setSelectedJob(job);
                toggleProcessModal();
                requestJobData(job.id)
              }}
            >
              Process
            </Button>
          </Col>
        </Row>
      );
    });
    return toReturn;
  }

  function createImageGrid() {
    const toReturn = []

    const requiredRows = watch('rows')
    const requiredCols = watch('cols')

    for (let i = 0; i < requiredRows; i++) {
      const newRowContents = []
      for (let j = 0; j < requiredCols; j++) {
        newRowContents.push(
          <Col>
            <img className="rounded" src={`data:image/jpeg;base64,${previewImage}`} />
          </Col>
        )

      }

      toReturn.push(
        <Row className='mb-6'>
          {newRowContents}
        </Row>
      )

    }


    return toReturn
  }

  return (
    <>
      <Page>
        <div className='font-bold text-2xl tracking-wide mb-3'>
          Process Staff Image Jobs <FetchingSpinner className='mx-2' isFetching={isFetching} />{' '}
        </div>
        <StaffTable
          data={data} setData={setData} setSelectedJob={setSelectedJob}
          toggleProcessModal={toggleProcessModal} requestJobData={requestJobData}
        />
      </Page>
      <div>
        <Modal size='md' isOpen={showProcessModal}>
      <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>{selectedJob?.name}</ModalHeader>
          <ModalBody>
            <FormProvider {...methods}>
              <div className="text-darker text-xl pb-2">Layout</div>
                <Row className="justify-center">
                  <Col xs='3'>
                  <FormGroup name="rows" label="Height">
                    <Input
                      type='number'
                      className='form-control'
                      name="rows"
                      rules={{ required: true }}
                      defaultValue={1}
                      min={1}
                      step={1}
                    />
                    </FormGroup>
                  </Col>
                  <div className="px-0 flex items-center"><FontAwesomeIcon icon={faTimes} size="sm" /></div>
                  <Col xs='3'>
                  <FormGroup name="cols" label="Width">
                    <Input
                      type='number'
                      name="cols"
                      className='form-control'
                      rules={{ required: true }}
                      defaultValue={1}
                      min={1}
                      step={1}
                    />
                    </FormGroup>
                  </Col>


                </Row>
                <div className="text-darker text-xl pb-2">Page size</div>
                <Row className="justify-center">
                  <Col xs='3'>
                  <FormGroup name="paper_y" label="Height">
                    <Input
                      type='number'
                      className='form-control'
                      name="paper_y"
                      rules={{ required: true }}
                      defaultValue={1}
                      min={1}
                      step={1}
                    />
                    </FormGroup>
                  </Col>
                  <div className="px-0 flex items-center"><FontAwesomeIcon icon={faTimes} size="sm" /></div>
                  <Col xs='3'>
                  <FormGroup name="paper_x" label="Width">
                    <Input
                      type='number'
                      name="paper_x"
                      className='form-control'
                      rules={{ required: true }}
                      defaultValue={1}
                      min={1}
                      step={1}
                    />
                    </FormGroup>
                  </Col>


                </Row>

              {!!previewImage ? createImageGrid() : <p>No image associated with this job</p>}

            </FormProvider>
          </ModalBody>

          <ModalFooter>

                <Row>
                  <Col>
                    <Button onClick={toggleProcessModal}>Cancel</Button>
                  </Col>
                  <Col>
                    <SubmitButton>
                      Submit
                    </SubmitButton>
                  </Col>
                </Row>
          </ModalFooter>
              </form>
            </FormProvider>
        </Modal>
      </div>
    </>
  );
};

export default ProcessStaffImageJobs;
