import React from "react";
import { InputGroup, InputGroupText } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { useTableContext } from "../hooks";
import { DebouncedInput } from "../..";

export const Search = ({
  className,
  table,
  icon = faSearch,
  addonType = "prepend",
  placeholder,
  onChangeCallback,
  ...props
}) => {
  const _table = useTableContext();
  table = table ?? _table;
  const { setGlobalFilter, state } = table;
  const { globalFilter } = state;

  return (
    <div className={classNames("form-group", className)} {...props}>
      <InputGroup>
        {icon ? (
            <InputGroupText>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroupText>
        ) : null}
        <DebouncedInput
          onChange={(e) => {
            if (onChangeCallback && e.target.value != globalFilter){
              onChangeCallback(e);
            }
            setGlobalFilter(e.target.value)
          }}
          value={globalFilter}
          className="form-control"
          placeholder={placeholder}
        />
      </InputGroup>
    </div>
  );
};
