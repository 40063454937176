// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./DMSans-Bold.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./DMSans-Bold.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./DMSans-BoldItalic.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./DMSans-BoldItalic.woff");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./DMSans-Italic.woff2");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./DMSans-Italic.woff");
var ___CSS_LOADER_URL_IMPORT_6___ = require("./DMSans-Medium.woff2");
var ___CSS_LOADER_URL_IMPORT_7___ = require("./DMSans-Medium.woff");
var ___CSS_LOADER_URL_IMPORT_8___ = require("./DMSans-Regular.woff2");
var ___CSS_LOADER_URL_IMPORT_9___ = require("./DMSans-Regular.woff");
var ___CSS_LOADER_URL_IMPORT_10___ = require("./DMSans-MediumItalic.woff2");
var ___CSS_LOADER_URL_IMPORT_11___ = require("./DMSans-MediumItalic.woff");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
// Module
exports.push([module.id, "@font-face {\n  font-family: 'DM Sans';\n\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2'),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff');\n\n  font-weight: bold;\n\n  font-style: normal;\n\n  font-display: swap;\n}\n\n@font-face {\n  font-family: 'DM Sans';\n\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff2'),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('woff');\n\n  font-weight: bold;\n\n  font-style: italic;\n\n  font-display: swap;\n}\n\n@font-face {\n  font-family: 'DM Sans';\n\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format('woff2'),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format('woff');\n\n  font-weight: normal;\n\n  font-style: italic;\n\n  font-display: swap;\n}\n\n@font-face {\n  font-family: 'DM Sans';\n\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format('woff2'),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format('woff');\n\n  font-weight: 500;\n\n  font-style: normal;\n\n  font-display: swap;\n}\n\n@font-face {\n  font-family: 'DM Sans';\n\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ") format('woff2'),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ") format('woff');\n\n  font-weight: normal;\n\n  font-style: normal;\n\n  font-display: swap;\n}\n\n@font-face {\n  font-family: 'DM Sans';\n\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_10___ + ") format('woff2'),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_11___ + ") format('woff');\n\n  font-weight: 500;\n\n  font-style: italic;\n\n  font-display: swap;\n}\n\n", ""]);
// Exports
module.exports = exports;
