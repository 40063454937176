import React, { useEffect, useMemo, useState } from 'react';
import { RFormGroup as FormGroup, RichSelect as Select, Input } from 'components/react-hook-form';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, Label, Col, Row, Alert } from 'reactstrap';
import { useClient } from 'hooks';
import { useQuery } from 'react-query';
import { Page } from 'components/common';
import { NotificationManager } from 'react-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { StudentDetailModal, AddGroupModal } from './ClassDetail'
import { ImageGrid, IndividualNoDataModal, PhotoModal } from './ClassDetail'
import { Pagination, Table, Search, useClientPaginatedRowSelectTable, TableContextProvider } from '../components/common/Tables';
import { RSubmitButton as SubmitButton } from 'components/react-hook-form';
import { useNavigate } from 'react-router';
import { toBase64 } from 'components/editors/utils';
import useModal from 'hooks/useModal';
const TYPES = new Set(["image/jpg", "image/jpeg", "image/tiff"]);


const EMPTY_OBJECT = {
  class_name: null,
  student_id: "",
  first_name: "",
  surname: "",
  absent: false,
};

const ConfirmModal = ({ filesArray, selectedJob, handleSubmit, onSubmit }) => {
  const {
    isOpen,
    toggle,
    Modal,
    ModalBody,
    ModalFooter,
    CloseButton
  } = useModal();

  const count = {
    no_data: 0,
    individual:0,
    staff: 0,
    siblings: 0,
    group: 0
  };
  Object.values(filesArray).forEach(e => {
    if (e.individual) count.individual += 1;
    else if (e.staff) count.staff += 1;
    else if (e.siblings) count.siblings += 1;
    else if (e.group) count.group += 1;
    else count.no_data += 1;
  })

  const onClick = () => {
    handleSubmit(onSubmit)().then(r => toggle()).catch(ex => NotificationManager.error(ex?.response?.detail));
  };

  return (
    <>
      {isOpen ? (
        <Modal>
              <ModalBody>
              <div className="py-2">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Are you sure you want to start this job?</h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">Currrent assignments</p>
            </div>
            <div className="border-t border-gray-200 pb-3">
              <dl>
                <div className="bg-gray-50 px-1 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">No Data</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{count.no_data}</dd>
                </div>
                <div className="bg-white px-1 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Individual</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{count.individual}</dd>
                </div>
                <div className="bg-gray-50 px-1 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Staff</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{count.staff}</dd>
                </div>
                <div className="bg-white px-1 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Group</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{count.group}</dd>
                </div>
                <div className="bg-gray-50 px-1 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Siblings</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{count.siblings}</dd>
                </div>
              </dl>
              {count.no_data !== 0 &&
                <Alert color="info my-2">
                  Some photographs are not assigned to anything, are you sure you want to submit?
                </Alert>
              }
            </div>
              </ModalBody>
              <ModalFooter>
                <CloseButton />
                <SubmitButton color="primary" onClick={onClick}>
                  Start
                </SubmitButton>
              </ModalFooter>
        </Modal>) :  null
      }
      <div className="flex justify-end">
      <Button 
        disabled={(filesArray === null || Object.values(filesArray).length === 0) || !selectedJob || isOpen}
        color="primary"
        onClick={toggle}>
        Submit
      </Button>
    </div>
    </>
  );
}

export const PhotographerStartJob = () => {
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: {
      job_has_data: true,
      photo_type: { name: 'Individual', id: 0 }
    },
    mode: 'all',
  });

  const form = useForm({
    defaultValues: {
      surname: "",
      first_name: "",
      class_name: null,
      student_id: "hehe",
      absent: false
    },
    mode: 'all',
  });
  const { reset } = form;
  const client = useClient();

  const photoType = useMemo(() => [
    { name: 'Individual', id: 0 },
    { name: 'Group', id: 1 },
    { name: 'Siblings', id: 2 },
    { name: 'Staff', id: 3 },
    { name: 'Indiv. (No data)', id: 4 },
  ], []);

  const [jobOptionsList, setJobOptionsList] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [data, setData] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);

  const handleGroupImageSelect = (file, image, fileObject) => {
    setPhotoModalOpen(true);
    setSelectedImage({ file: file, image: image, fileObject: fileObject })
  }

  const handleNoDataImageSelect = (file, image, fileObject) => {
    setNoDataIsOpen(true)
    setSelectedImage({ file: file, image: image, fileObject: fileObject })
  }

  const [showStudentDetail, setShowStudentDetail] = useState(false);
  const toggleStudentDetail = () => {
    setShowStudentDetail(!showStudentDetail);
  };

  const [showAddGroupModel, setShowAddGroupModel] = useState(false);
  const toggleShowAddGroupModel = () => {
    setShowAddGroupModel(!showAddGroupModel);
  };

  const [filesArray, setFilesArray] = useState({});

  const { watch, setValue, register, handleSubmit } = methods;

  const selectedJob = watch("selected_job");
  const has_data = watch("job_has_data");
  const schoolId = watch("school_id");

  const { refetch: getJobs } = useQuery(
    'get_photographer_job_list',
    async () => {
      client.get(`photographer_job_list/min_list`).then((data) => {
        setJobOptionsList(data.data);
      });
      return null;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const processPhoto = async (obj) => {
    const encoded = await toBase64(obj.file)
    return {
      assigned: obj?.assigned,
      siblings: obj?.siblings,
      staff: obj?.staff,
      group: obj?.group,
      individual: obj?.individual,
      encoded_photo: encoded,
      photo_name: obj.file.name,
    };
  };

  const [pending, setPending] = useState(false);
  const onSubmit = async (x) => {
    try {
      setPending(true);

      const _data = await Promise.all(Object.values(filesArray).map(processPhoto));
      const payload = {
        job_id: x.selected_job.id,
        data: _data,
        students: data
      };
      client.post('/start_photographer_job', payload).catch(ex => console.error('ex ', ex));
      //const data = await Promise.all(Object.values(filesArray).map(processPhoto));
      NotificationManager.success('Files added successfully');
      navigate(`/viewphotographerjob/${x.selected_job.id}`);
    }
    catch (ex) {
      console.error('ERROR: ', ex);
      console.log('ex.message: ', ex.message)
      NotificationManager.error('There was an issue with this request');
    }
    finally {
      setPending(false);
    }
  }

  const requestJobData = (jobID) => {
    client.get(`check_job_data`, { params: { job: jobID } }).then((resp) => {
      const data = resp.data.data
      setData(data.map(e => !e['photo_type'] ? { ...e, photo_type: "IndividualNoData", assigned: null }
        : { ...e, assigned: null }));
      createGroupList(data)
    }).catch(e => {
      const detail = e?.response?.data?.detail;
      NotificationManager.error(detail ? detail : 'Something went wrong');
    });
  };

  const createGroupList = (groupsArray) => {
    const groups = new Set(groupsArray.map(e => e.class_name));
    setGroupOptions(Array.from(groups).map((e, idx) => ({
      id: idx,
      name: e
    })));
  }


  function addPerson() {
    setSelectedStudent(null);
    reset(EMPTY_OBJECT);
    setShowStudentDetail(true);
  }

  function addGroup(newGroup) {
    setGroupOptions([...groupOptions, newGroup]);
  }

  const [noDataIsOpen, setNoDataIsOpen] = useState(false);

  const toggleNoDataModal = () => { setNoDataIsOpen(!noDataIsOpen) };

  const columns = [
    {
      Header: 'Forename',
      accessor: 'first_name',
    },
    {
      Header: 'Surname',
      accessor: 'surname',
    },
    {
      Header: 'Group',
      accessor: 'class_name',
    },
    {
      Header: 'ID',
      accessor: 'id',
    },
    // {
    //   Header: 'Absent',
    //   accessor: 'absent',
    //   Cell: ({ value }) => {
    //     return (value ? <FontAwesomeIcon className="text-green-500" icon={faCheckCircle} />
    //       : <></>);
    //   },
    //   disableSortBy: true,
    // },
    {
      Header: "Photo",
      accessor: "photo",
      disableSortBy: true,
      Cell: (cellProps) => {
        let photo = cellProps.row.values.photo ?? null;
        // console.log(photo);
        if (!photo) {
          return null;
        }
        return (
          <img
            className='rounded'
            style={{ width: '50px', height: '50px', objectFit: 'cover' }}
            src={photo.image ?? (photo.file ? window.URL.createObjectURL(photo.file) : null) ?? null}

          />
        )
      }
    },
  ];

  const getRowProps = (row) => {
    return {
      onClick: async () => {
        let x = { ...row.values };
        x.class_name = groupOptions.find(e => e.name === row.values.class_name);
        x.student_id = x.id;
        reset(x);
        setSelectedStudent(row.values)
        setShowStudentDetail(true)
      },
      style: { cursor: 'pointer' },
    };
  };
  const tableProps = { getRowProps: getRowProps };

  const [filteredData, setFilteredData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const groupFilter = watch('select_group');
  const photo_type = watch('photo_type')
  const [photoModalOpen, setPhotoModalOpen] = useState(false);
  const toggleModal = () => setPhotoModalOpen(x => !x);
  useEffect(() => {
    let filtered = data;
    if (groupFilter) filtered = filtered.filter(e => e.class_name === groupFilter.name);
    setFilteredData(filtered);
  }, [data, groupFilter]);

  const compareFn = useMemo(() => {
    if (!photo_type) return () => true;
    if (photo_type.id === 1) {
      return (x) => x.group;
    } else if (photo_type.id === 2) {
      return (x) => x.siblings;
    }
    else if (photo_type.id === 3) {
      return (x) => x.staff;
    }
    else if (photo_type.id === 4) {
      return (x) => x.assigned === null;
    }
    return () => true;
  }, [photo_type]);

  const { table } = useClientPaginatedRowSelectTable({
    data: filteredData,
    setData,
    initialState: {
      pageSize: 10
    },
    columns: useMemo(() => columns, []),
    selectionColumn: false
  });

  const handlePhotoClick = useMemo(() => {
    if (!photo_type) return () => { };
    if (photo_type.id === 4) {
      return handleNoDataImageSelect;
    }
    return handleGroupImageSelect;
  }, [photo_type]);


  return (
    <>
      <Page>
        <div className="font-bold text-2xl tracking-wide mb-3">
          Start Job
        </div>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup name="job_has_data">
              <Input name="job_has_data" defaultChecked type="checkbox" id="job_has_data" />
              <Label check className='ml-1' htmlFor="job_has_data">
                Job has data
              </Label>
            </FormGroup>
            {has_data ? (
              <FormGroup name="selected_job" label="Job">
                <Select
                  name="selected_job"
                  options={jobOptionsList}
                  labelKey="school_name_with_date"
                  valueKey="id"
                  onChange={(selectedJob) => {
                    requestJobData(selectedJob.id)
                    console.log(selectedJob)
                    setValue('selected_job', selectedJob);
                  }}
                  placeholder="Select a job from the list..."
                  isLoading={jobOptionsList.length == 0}
                  shouldUnregister={true}
                  defaultValue=""
                />

              </FormGroup>
            ) : (
              <FormGroup label="School ID">
                <input
                  type='number'
                  className='form-control'
                  {...register('school_id', {
                    required: false,
                    valueAsNumber: true,
                  })}
                  min={0}
                  defaultValue={1}
                />
              </FormGroup>
            )}

            <FormGroup key={7} label='Camera directory'>
              <input webkitdirectory="true" multiple accept="image/*"
                type='file' className="form-control" id='camera_directory'
                {...register('camera_directory', {
                  required: false,
                  onChange: async (e) => {
                    const files = [...e.target.files].filter(v => TYPES.has(v.type));
                    setFilesArray(files.reduce((obj, item) => ({
                      ...obj, [item["webkitRelativePath"]]: {
                        file: item,
                        assigned: null
                      }
                    }), {}));
                  },
                })}
              />

            </FormGroup>

            {selectedJob &&
            <>
            <div className="py-2">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Job Information</h3>
              {/* <p className="mt-1 max-w-2xl text-sm text-gray-500"></p> */}
            </div>
            <div className="border-t border-gray-200 pb-3">
              <dl>
                <div className="bg-gray-50 px-1 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Name</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{selectedJob?.name}</dd>
                </div>
                <div className="bg-white px-1 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">School</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{selectedJob?.school__name}, {selectedJob?.school__city}</dd>
                </div>
                <div className="bg-gray-50 px-1 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Date</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{selectedJob?.date}</dd>
                </div>
              </dl>
            </div>
            </>
            }


            {(selectedJob || schoolId) &&
              <div className="py-2">
                <Row className="justify-between">
                  <Col md={4}>
                    <FormGroup label="Group">
                      <Select
                        options={groupOptions}
                        labelKey="name"
                        valueKey="id"
                        isClearable
                        name="select_group"
                        placeholder="Filter by group name..."
                        rules={{ required: false }}
                        shouldUnregister={true}
                        defaultValue={''}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <Row className="w-full">
                      <FormGroup label="Photo Type" className="w-full">
                        <Select
                          options={photoType}
                          labelKey="name"
                          valueKey="id"
                          name="photo_type"
                          rules={{ required: false }}
                          shouldUnregister={true}
                          defaultValue={""}
                        />
                      </FormGroup>
                    </Row>
                    <Row className="w-full justify-end">
                      <Button size="sm" className="m-2" onClick={addPerson}>Add Person</Button>
                      <Button size="sm" className="m-2" onClick={toggleShowAddGroupModel}>Add Group</Button>
                    </Row>

                  </Col>
                </Row>

                {/* Individual */}
                <IndividualNoDataModal
                  methods={methods}
                  toggleModal={toggleNoDataModal}
                  isOpen={noDataIsOpen}
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                  data={data}
                  setData={setData}
                  groups={groupOptions}
                  files={filesArray}
                  setFilesArray={setFilesArray}
                  photoType={photoType}
                />
                <PhotoModal
                  methods={methods}
                  toggleModal={toggleModal}
                  isOpen={photoModalOpen}
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                  data={data}
                  setData={setData}
                  groups={groupOptions}
                  files={filesArray}
                  setFilesArray={setFilesArray}
                />
                {photo_type?.id == 0 && selectedJob ?
                  <TableContextProvider table={table}>
                    <Row form>
                      <Col>
                        <Search placeholder='Search' onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} />
                      </Col>
                    </Row>
                    <Table size="sm" clientSidePagination striped {...tableProps} />
                    <Pagination />
                  </TableContextProvider>
                  : (
                    <ImageGrid
                      methods={methods}
                      handleImageSelect={handlePhotoClick}
                      files={filesArray}
                      height='calc(100vh - 400px)'
                      compareFn={compareFn}
                    />
                  )
                }
              </div>
            }
            <AddGroupModal
              isOpen={showAddGroupModel}
              addGroup={addGroup}
              handleToggle={toggleShowAddGroupModel}
              methods={methods}
              groupOptions={groupOptions}
            />
            <ConfirmModal filesArray={filesArray} selectedJob={selectedJob} handleSubmit={handleSubmit} onSubmit={onSubmit} />
          </form>
        </FormProvider>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <StudentDetailModal
              isOpen={showStudentDetail}
              handleToggle={toggleStudentDetail}
              selectedStudent={selectedStudent}
              setSelectedStudent={setSelectedStudent}
              data={data}
              setData={setData}
              groupOptions={groupOptions}
              filesArray={filesArray}
              setFilesArray={setFilesArray}
              form={form}
              emptyObject={EMPTY_OBJECT}
            />
          </form>
        </FormProvider>
      </Page>
    </>
  );
};

export default PhotographerStartJob;
