import React, { useState, useMemo, useEffect, useImperativeHandle, forwardRef } from 'react';
import { compose, withState, withHandlers } from 'recompose';
import moment from 'moment';
import { Card, CardBody, Button, Input, Alert, Row, Col } from 'reactstrap';
import { Pagination, Table, Search, useListQuery, useListTable, useClientPaginatedRowSelectTable, TableContextProvider } from '../../components/common/Tables';
import { NotificationManager } from 'react-notifications';
import { Page } from 'components/common';
import { find } from 'lodash';
import download from 'downloadjs';
import { useClient } from 'hooks';

const formatDate = v => (v ? moment(v).format('DD/MM/YYYY') : null);
const ID_COL = 0;
const FIRST_NAME_COL = 1;
const SURNAME_COL = 2;
const CLASS_COL = 3;



export const CheckData = forwardRef(({job, setJob, refreshCallback, editCallback}, ref) => {
    const client = useClient();
    // const [editCols, setEditCols] = useState(false);
    // const [extraCols, setExtraCols] = useState(0);
    // const [selectedCols, setSelectedCols] = useState({});
    // const [offset, setOffset] = useState(0);
    // const [limit, setLimit] = useState(50);

    useImperativeHandle(ref, () => ({
        save: (event) => {
           onSaveOrdering();
        }
     }));

    const [error, setError] = useState('');
    
    // const ColumnSelect = ({ index, onChange, selectedCols }) => (
    //   <Input
    //     type="select"
    //     onChange={e => onChange(e, index)}
    //     value={
    //       find(Object.keys(selectedCols), key => selectedCols[key] === index) || -1
    //     }
    //   >
    //     <option value={-1} />
    //     <option value={ID_COL}>ID</option>
    //     <option value={FIRST_NAME_COL}>First Name</option>
    //     <option value={SURNAME_COL}>Surname</option>
    //     <option value={CLASS_COL}>Class</option>
    //   </Input>
    // );
    // console.log({job});
	// useEffect(() => {
    //     if (job.data && job.data[0].other){
    //         setExtraCols(job.data[0].other.length);
    //     }
	// }, [job]);
    // const onChangeColumns = () => {setEditCols(true)}
    // const onColumnSelectChange = (event, index) => {
    //     selectedCols[event.target.value] = index;
    //     console.log({selectedCols});
    //     setSelectedCols(selectedCols);
    // }
    // const onSave = () => {
    //     if (Object.keys(selectedCols).length < 4) {
    //     let missing = [];
    //     if (!(ID_COL in selectedCols)) missing.push('ID');
    //     if (!(FIRST_NAME_COL in selectedCols)) missing.push('First Name');
    //     if (!(SURNAME_COL in selectedCols)) missing.push('Surname');
    //     if (!(CLASS_COL in selectedCols)) missing.push('Class');
    //     setError(`These columns have not been assigned: ${missing.join(', ')}`);
    //     return;
    //     }
    //     setError('');
    //     const data = {
    //     job_id: job.id,
    //     old_id_col: 0,
    //     old_firstname_col: 1,
    //     old_surname_col: 2,
    //     old_class_col: 3,
    //     id_col: selectedCols[ID_COL],
    //     firstname_col: selectedCols[FIRST_NAME_COL],
    //     surname_col: selectedCols[SURNAME_COL],
    //     class_col: selectedCols[CLASS_COL]
    //     };
    //     client
    //     .post('check_job_data', data)
    //     .then(r => setJob(job));
    // }



    const columns = [
        {
            Header: 'ID',
            accessor: 'id',
        },
        {
            Header: 'Forename',
            accessor: 'first_name',
        },
        {
            Header: 'Surname',
            accessor: 'surname',
        },
        {
            Header: 'Group',
            accessor: 'class_name',
        },
        {
            Header: 'Other',
            accessor: 'other',
            Cell: (cellProps) => {
                let other = cellProps.row.values.other ?? [];
                return (
                  <>
                    {other.map(other_col => (
                        <td className="border-none">
                            {other_col}
                        </td>
                    ))}
                  </>
                );
            }
        },
    ];
	const { table, selections, setSelections } = useClientPaginatedRowSelectTable({
		data: job.data,
		// setData,
		columns: useMemo(() => columns, []),
        initialState:{
            pageSize: 10,
        },
        selectionColumn:false,


	});


    function onSaveOrdering() {
        const columns = table.columns
        var selectedCols = []
        columns.forEach(column => {
            console.log(column.Header, column.isSorted, column.isSortedDesc)
            if (column.isSorted) {
                selectedCols.push(column)
            }
        })
        var payload = {};
        if (selectedCols.length != 0) { // At least one selected column
            const colsForPayload = {}
            selectedCols.forEach((selectedCol)=>{
                colsForPayload[selectedCol.sortedIndex] = {
                    'columnID': selectedCol.id,
                    'header': selectedCol.Header,
                    'isSortedDesc': selectedCol.isSortedDesc,
                    'sortedIndex': selectedCol.sortedIndex
                }
            })
            payload['columns'] = colsForPayload
        }

        console.log(payload)
        console.log("=======================================================================")
        console.log("=                                                                     =")
        console.log("=                                                                     =")
        console.log("=                           Would send off reorder here               =")
        console.log("=                                                                     =")
        console.log("=                                                                     =")
        console.log("=======================================================================")


        if(Object.keys(payload).length === 0){
            NotificationManager.info('No Changes to the spreadsheet order');
            refreshCallback(true)
            return
        }

        client
        .post('/order_spreadsheet', {'job': job.id, ...payload})
        .then((resp) => {
          NotificationManager.success('Spreadsheet has been reordered');
          return null;
        })
        .catch((e) => {
          console.error('ERROR: ', e.response.data);
          NotificationManager.error('There was an issue with this request');
          return null;
        }).finally(r => {
            refreshCallback(false);
        });


    };

	return (
            <Card>
                <CardBody>
                <h5>{`${job.name} - ${formatDate(job.date)}`}</h5>

                {job.notes && <p>{job.notes}</p>}
                    {error && <Alert color="danger">{error}</Alert>}
                    <p className='italic'>To select multiple columns for sorting hold the SHIFT key when clicking</p>
                    <TableContextProvider table={table}>
                        <Table size="sm" clientSidePagination striped />
                        <Pagination />
                    </TableContextProvider>
                    <Row className='mt-3'>
                        <Button className='ml-3' color='primary' onClick={editCallback}>
                        Edit
                        </Button>
                        <Button className='ml-3' color='primary' onClick={()=>{refreshCallback(false)}}>
                        Refresh Job Data
                        </Button>
                        {/* <Button className='ml-3' color='primary' onClick={onSaveOrdering}>
                        Save Order
                        </Button> */}
                    </Row>
                </CardBody>
            </Card>

    );
})


export default CheckData;
