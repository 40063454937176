export const toBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);

		reader.onload = () => resolve(reader.result.split(',').pop());
		reader.onerror = (error) => reject(error);
});

export const letterOptionsABC = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q',
								'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'].map((e, idx) => ({ id: idx, name: e }));
export const isImage = filename => {
	let i = filename.lastIndexOf('.');
	let ext = filename.slice(i + 1).toLowerCase();
	return (
	  ext === 'png' ||
	  ext === 'jpg' ||
	  ext === 'jpeg' ||
	  ext === 'gif' ||
	  ext === 'bmp'
	);
};

export const determineColor = (rgbArr = [0,0,0], selected = false)  =>{
	if (!rgbArr) return "rgba(150,0, 0,1)"


	const red = rgbArr[0]
	const green = rgbArr[1]
	const blue = rgbArr[2]

	if ((red * 0.299 + green * 0.587 + blue * 0.114) > 186) {
		if (selected) {
			return "rgba(0,0, 150,1)";
		} else {
			return "rgba(150,0, 0,1)";
		}
	}
	else {

		if (selected) {
			return "rgba(150,150, 255,1)";
		} else {
			return "rgba(255,150, 150,1)";
		}
	}

}


export const getSubScale = (w, h) => Math.pow(10, Math.min(Math.ceil(Math.log10(w)), Math.ceil(Math.log10(h))));

export const getScale = (w, h, m=1000) => {
	const d = getSubScale(w, h);
	return m/d;
	return [(w/d)*m, (h/d)*m];

}