import React, { useEffect, useState } from "react";
import { useListQuery, useListTable } from "..";
import { useRowSelect } from "react-table";
import { useGetLatest } from "../../../../hooks";

const SelectionCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

export const useRowSelectTable = ({
  url,
  columns,
  isDisabled = () => false,
  refresh_token = null,
  query_params = {},
}) => {
  const [data, setData] = useState([]);
  const [selections, setSelections] = useState(() => new Set());
  const getIsDisabled = useGetLatest(isDisabled);
  const hook = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      {
        id: "selection",
        Header: ({
          rows,
          isAllRowsSelected,
          toggleAllRowsSelected,
          getToggleAllPageRowsSelectedProps,
        }) => (
          <div>
            <SelectionCheckbox
              {...getToggleAllPageRowsSelectedProps()}
              onClick={() => {
                let mode = isAllRowsSelected;
                rows.forEach((row) => {
                  if (!getIsDisabled()(row)) {
                    row.toggleRowSelected();
                  }
                });
                if (!mode) {
                  // add all rows
                  setSelections((prev) => {
                    let _ret = new Set(prev);
                    for (let e of rows) {
                      if (!getIsDisabled()(e)) _ret.add(e.original.id);
                    }
                    return _ret;
                  });
                } else {
                  // delete all rows
                  setSelections((prev) => {
                    let _ret = new Set(prev);
                    for (let e of rows) {
                      _ret.delete(e.original.id);
                    }
                    return _ret;
                  });
                }
              }}
            />
          </div>
        ),
        Cell: ({ row }) =>
          getIsDisabled()(row) ? (
            <div>
              <SelectionCheckbox
                {...row.getToggleRowSelectedProps()}
                checked={true}
              />
            </div>
          ) : (
            <div>
              <SelectionCheckbox
                {...row.getToggleRowSelectedProps()}
                onClick={() => {
                  let mode = row.isSelected;
                  row.toggleRowSelected();
                  if (!mode) {
                    setSelections((prev) => {
                      let _ret = new Set(prev);
                      _ret.add(row.original.id);
                      return _ret;
                    });
                  } else {
                    setSelections((prev) => {
                      let _ret = new Set(prev);
                      _ret.delete(row.original.id);
                      return _ret;
                    });
                  }
                }}
              />
            </div>
          ),
      },
      ...columns,
    ]);
  };

  const table = useListTable(
    {
      columns: columns,
      data: data,
      initialState: {
        pageSize: 50,
      },
    },
    useRowSelect,
    hook
  );

  useListQuery({
    setData,
    table,
    url: url,
    refresh_token,
    query_params,
  });

  const { flatRows, rows } = table;
  useEffect(() => {
    rows.forEach((row) => {
      if (selections.has(row.original.id)) {
        row.toggleRowSelected(true);
      }
    });
  }, [flatRows, rows, selections]);

  return { data, setData, selections, setSelections, table };
};

export default useRowSelectTable;
